import { Button, Icon } from "@abb/common-ux";
import classNames from "classnames";
import { FC, PropsWithChildren } from "react";
import {
  ConnectDragPreview,
  ConnectDragSource,
  ConnectDropTarget,
} from "react-dnd";
import styles from "./DragAndDrop.module.scss";
export interface DraggableBaseProps {
  drag: ConnectDragSource;
  drop: ConnectDropTarget;
  isDragging: boolean;
  canDrop: boolean;
  draggedItem: any;
  isOver: boolean;
  title: string;
  dragPreview: ConnectDragPreview;
  icon?: string;
}
export const DraggableBase: FC<PropsWithChildren<DraggableBaseProps>> = ({
  drag,
  drop,
  isDragging,
  draggedItem,
  canDrop,
  isOver,
  title,
  dragPreview,
  icon,
  children,
}) => {
  return (
    <div>
      <div
        ref={drop}
        className={classNames(styles.dropContainer, {
          [styles.isDragging]: isDragging,
        })}
      >
        <div
          ref={dragPreview}
          className={classNames(styles.dragCollapsible, {
            [styles.isDragging]: isDragging,
            [styles.dragging]: !!draggedItem,
            [styles.canDrop]: canDrop,
            [styles.isOver]: isOver,
          })}
          title={title}
        >
          {icon && <Icon size={16} name="folder" />}
          <p>{title}</p>
          <div ref={drag}>
            <Button size="small" type="discreet-black" icon="menu" />
          </div>
        </div>
      </div>
      <div className={styles.dragChildren}>{children}</div>
    </div>
  );
};
