import { gql } from "@apollo/client";

export const changeProperties = gql`
  mutation changeStructureProperties($id: String, $properties: jsonb) {
    update_master_structure(
      where: { structureid: { _eq: $id } }
      _set: { properties: $properties }
    ) {
      affected_rows
    }
  }
`;

export const changeParentId = gql`
  mutation changeParentId($id: String, $parentId: String) {
    update_master_structure(
      where: { structureid: { _eq: $id } }
      _set: { parentid: $parentId }
    ) {
      affected_rows
    }
  }
`;

export const addStructureChild = gql`
  mutation addStructureElement(
    $id: String
    $parentId: String
    $properties: jsonb
  ) {
    insert_master_structure_one(
      object: { structureid: $id, properties: $properties, parentid: $parentId }
    ) {
      structureid
    }
  }
`;

export const addStructure = gql`
  mutation addStructureElement(
    $objects: [master_structure_insert_input!]!
    $on_conflict: master_structure_on_conflict!
  ) {
    insert_master_structure(objects: $objects, on_conflict: $on_conflict) {
      returning {
        structureid
      }
    }
  }
`;

export const deleteStructure = gql`
  mutation deleteStructure($id: String!) {
    delete_master_structure(where: { structureid: { _eq: $id } }) {
      returning {
        structureid
      }
    }
  }
`;

export const deleteAssetStructure = gql`
  mutation deleteStructure($id: String!) {
    delete_master_assetstructure(where: { structureid: { _eq: $id } }) {
      returning {
        assetid
      }
    }
    delete_master_structure(where: { structureid: { _eq: $id } }) {
      affected_rows
    }
  }
`;

export const deleteStructureChildren = gql`
  mutation deleteStructureChildren($id: String!) {
    delete_master_structure(where: { parentid: { _eq: $id } }) {
      returning {
        parentid
      }
    }
  }
`;
