import { useEffect, useState } from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";

import { useTranslation } from "react-i18next";

import { ApolloService } from "services/ApolloService";
import { ApolloProvider } from "@apollo/client";

import { MainLayout } from "layouts/main/MainLayout";
import { Products } from "views/Products/Products";
import { Structure } from "views/Structure/Structure";
import { Users } from "views/Users/Users";
import Launcher from "views/Launcher/Launcher";
import Unauthorized from "views/Unauthorized/Unauthorized";

import { AuthError, AuthState, useAuth } from "@abb/identity-auth-react";
import { useLoggedUserPermission } from "libs/hooks/useLoggedUserPermission";

import { Button } from "@abb/common-ux";
import { GlobalNotificationComponent } from "components/GlobalNotification/GlobalNotification";
import { useConfig } from "components/Config/ConfigProvider";
import { ErrorBox } from "components/ErrorBox/ErrorBox";
import "react-reflex/styles.css";
import styles from "./App.module.scss";

import { MainStoreProvider } from "components/MainStore/MainStoreProvider";
import { LOGOUT_BUTTON_THROTTLE_MS } from "config/constants";

const App = () => {
  const [hasuraApolloServiceInitialized, setHasuraInitialized] = useState(
    false
  );
  const { config, updateToken } = useConfig();

  const { authState, error, user, logout } = useAuth();
  const {
    hasViewProductPermission,
    hasViewStructurePermission,
    hasViewUserPermission,
  } = useLoggedUserPermission();

  const { t } = useTranslation();

  useEffect(() => {
    if (user?.access_token) {
      updateToken(user?.access_token);
    }
  }, [user?.access_token, updateToken]);

  useEffect(() => {
    if (
      !ApolloService.getClient() &&
      !error &&
      user &&
      user?.access_token &&
      config.identity.uri &&
      config.apollo.storageApi.uri &&
      config.apollo.storageApi.configurationReady
    ) {
      if (process.env.NODE_ENV === "development") {
        console.log(user);
      }
      ApolloService.init(
        config.apollo.storageApi.uri,
        config.apollo.storageApi.wsUri,
        config.identity.uri,
        config.assetsApi.uri,
        config.assetsApi.wsUri,
        user.access_token
      );

      setHasuraInitialized(true);
    }
    if (user?.access_token) {
      ApolloService.updateToken(user?.access_token);
    }
  }, [
    authState,
    error,
    user,
    user?.access_token,
    config.identity.uri,
    config.apollo.storageApi.uri,
    config.apollo.storageApi.wsUri,
    config.apollo.storageApi.headerAuth,
    config.apollo.storageApi.configurationReady,
    config.assetsApi.uri,
    config.assetsApi.wsUri,
  ]);

  if (error) {
    if (error instanceof AuthError) {
      return (
        <div>
          <ErrorBox messages={[error.name!]} title={error.message} />
          <p>
            <b>Hint</b>: {error.hint}
          </p>
          <div className={styles.logout}>
            <Button
              text={t("logout")}
              onPress={logout}
              throttleMilliseconds={LOGOUT_BUTTON_THROTTLE_MS}
            />
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <ErrorBox
            messages={[error.message]}
            title={t("authenticationError")}
          />
          <div className={styles.logout}>
            <Button
              text={t("logout")}
              onPress={logout}
              throttleMilliseconds={LOGOUT_BUTTON_THROTTLE_MS}
            />
          </div>
        </div>
      );
    }
  }

  if (authState !== AuthState.UNAUTHENTICATED) {
    return hasuraApolloServiceInitialized &&
      authState === AuthState.AUTHENTICATED &&
      user &&
      user?.access_token ? (
      <div className="App h-100">
        <ApolloProvider client={ApolloService.getClient()}>
          <MainStoreProvider>
            <BrowserRouter basename={process.env.PUBLIC_URL}>
              <MainLayout>
                <Switch>
                  <Route path="/auth_callback">
                    <Launcher />
                  </Route>
                  <Route path="/launcher">
                    <Launcher />
                  </Route>
                  <Route path="/structure">
                    {hasViewStructurePermission ? (
                      <Structure />
                    ) : (
                      <Unauthorized />
                    )}
                  </Route>
                  <Route path={["/users/:type/:id", "/users/:type", "/users"]}>
                    {hasViewUserPermission ? <Users /> : <Unauthorized />}
                  </Route>
                  <Route path={["/roles/:service", "/roles"]}>
                    {hasViewProductPermission ? <Products /> : <Unauthorized />}
                  </Route>
                  <Redirect to="/launcher" />
                </Switch>
              </MainLayout>
            </BrowserRouter>
          </MainStoreProvider>
        </ApolloProvider>
        <GlobalNotificationComponent></GlobalNotificationComponent>
      </div>
    ) : (
      <></>
    );
  } else {
    return <></>;
  }
};
export default App;
