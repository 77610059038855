import React from "react";
import styles from "./LabeledProperty.module.scss";
import classNames from "classnames";

interface Props {
  label: string;
  value: string | JSX.Element;
  classNames?: {
    container?: string;
    label?: string;
    value?: string;
  };
}

export const LabeledProperty = ({
  label,
  value,
  classNames: classNamesProp,
}: Props) => {
  return (
    <div className={classNames(styles.container, classNamesProp?.container)}>
      <p className={classNames(styles.label, classNamesProp?.label)}>{label}</p>
      <div className={classNames(styles.value, classNamesProp?.value)}>
        {value}
      </div>
    </div>
  );
};
