import { ErrorBox } from "components/ErrorBox/ErrorBox";
import React from "react";
import { ErrorBoundary } from "react-error-boundary";

const ErrorFallback = ({ error, resetErrorBoundary }: any) => {
  return (
    <div role="alert">
      <ErrorBox messages={[error.message]} />
      {resetErrorBoundary ? (
        <button onClick={resetErrorBoundary}>Try again</button>
      ) : (
        []
      )}
    </div>
  );
};
const DefaultErrorBoundary: React.FunctionComponent<{
  onReset?: () => void;
  children: any;
}> = ({ children, onReset }) => {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback} onReset={onReset}>
      {children}
    </ErrorBoundary>
  );
};

export default DefaultErrorBoundary;
