/**
 * Flattens a hierarchical structure collection where the levels are given by a specific property.
 * @param items Items to flatten
 * @param childrenGetter a getter function that given a parent node returns its children.
 */
export const flatBy = <T,>(
  items: T[],
  childrenGetter: (parent: T) => T[]
): T[] => {
  return items
    ? [
        ...items,
        ...items?.flatMap((item) =>
          flatBy(childrenGetter(item), childrenGetter)
        ),
      ]
    : [];
};
