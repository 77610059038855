import { FunctionComponent, useState } from "react";
import { QueryResultRendererProps } from "./QueryResultRendererProps";
import { LoadingBox } from "../LoadingBox/LoadingBox";
import { ErrorBox } from "../ErrorBox/ErrorBox";
import Skeleton from "components/Skeletons/Skeleton";

export const QueryResultRenderer: FunctionComponent<QueryResultRendererProps> = ({
  queryResults,
  children,
  loadingBoxSizeClass,
  skeleton: skeletonTemplate,
}) => {
  const [noDataEver,setNoData]=useState(true);
  /**
   * Check if has errors
   */

  const queryResultsWithErrors = queryResults.filter(
    (queryResultDetails) =>
      queryResultDetails.queryResult.error &&
      queryResultDetails.queryResult.error.message
  );

  if (queryResultsWithErrors.length) {
    return (
      <ErrorBox
        messages={queryResultsWithErrors.map(
          (queryResult) => queryResult.queryResult.error!.message
        )}
      />
    );
  }
  /**
   * Check if any is loading
   */

  if(noDataEver && !queryResults.some(
    (queryResultDetails) => !queryResultDetails.queryResult.data
  )){
    setNoData(false)
  }
  if (noDataEver && skeletonTemplate) {
    return <Skeleton>{skeletonTemplate}</Skeleton>;
  } else if (noDataEver) {
    return <LoadingBox sizeClass={loadingBoxSizeClass} />;
  }

  /**
   * Render if no loading and no errors
   */

  return (
    <>
      {children(
        queryResults.map(
          (queryResult) => queryResult.queryResult.data?
          queryResult.queryResult.data[queryResult.dataKey]:
          null
        )
      )}
    </>
  );
};
