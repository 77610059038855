import React from "react";
import ContentLoader from "react-content-loader";
import skeletonDefaults from "../Skeleton";

interface IDataGridSkeleton {
  id: string;
  width?: number;
  height?: number;
}

const DataGridSkeleton = ({ id, width = 0, height = 0 }: IDataGridSkeleton) => {
  const dataGridDefaults = {
    ...skeletonDefaults,
    x: 0,
    y: 8,
    rx: 4,
    ry: 4,
    width: 32,
    height: 32,
    heightTotal: 48,
    padding: 2,
    gap: 8,
  };

  const checkbox = {
    x: `${dataGridDefaults["x"]}`,
    y: `${dataGridDefaults["y"] * 2}`,
    rx: `${dataGridDefaults["rx"]}`,
    ry: `${dataGridDefaults["ry"]}`,
    width: `${dataGridDefaults["width"] - dataGridDefaults["y"]}`,
    height: `${dataGridDefaults["height"] - dataGridDefaults["y"]}`,
  };

  const rowQuantity = Math.ceil(
    (height || dataGridDefaults["heightTotal"]) /
      dataGridDefaults["heightTotal"] /
      1.2
  );
  const widthAvailable = (width || 400) - dataGridDefaults["width"];

  const row = (num: number) => {
    const rowYPosition =
      (num >= 1 ? dataGridDefaults["heightTotal"] * num : 0) +
      dataGridDefaults["y"];
    const height = dataGridDefaults["height"] - dataGridDefaults["y"];
    return (
      <React.Fragment key={`${id}_row_${num}`}>
        <rect
          {...checkbox}
          y={
            (num >= 1 ? dataGridDefaults["heightTotal"] * num : 0) +
            dataGridDefaults["y"]
          }
          key={`test1 ${num}`}
        />
        <rect
          width={(widthAvailable / 100) * 45 - dataGridDefaults["gap"]}
          height={height}
          y={rowYPosition}
          x={width - widthAvailable + dataGridDefaults["gap"]}
          rx={dataGridDefaults["rx"]}
          ry={dataGridDefaults["ry"]}
          key={`test2 ${num}`}
        />
        <rect
          width={(widthAvailable / 100) * 45 - dataGridDefaults["gap"]}
          height={height}
          y={rowYPosition}
          x={`${
            (widthAvailable / 100) * 45 +
            (width - widthAvailable + dataGridDefaults["gap"])
          }`}
          rx={dataGridDefaults["rx"]}
          ry={dataGridDefaults["ry"]}
          key={`test3 ${num}`}
        />
        <rect
          width={(widthAvailable / 100) * 10 - dataGridDefaults["gap"]}
          height={height}
          y={rowYPosition}
          x={`${
            (widthAvailable / 100) * 90 +
            (width - widthAvailable + dataGridDefaults["gap"])
          }`}
          rx={dataGridDefaults["rx"]}
          ry={dataGridDefaults["ry"]}
          key={`test4 ${num}`}
        />
      </React.Fragment>
    );
  };

  let rows = [];
  for (let i = 0; i < rowQuantity; i++) {
    rows.push(row(i));
  }

  return (
    <ContentLoader
      height={380}
      backgroundColor={`#f5f5f5`}
      foregroundColor={`#ebebeb`}
    >
      <rect
        width={"100%"}
        height={2}
        y={42}
        rx={dataGridDefaults["rx"]}
        ry={dataGridDefaults["ry"]}
      />
      {rows}
      <rect
        width={"20%"}
        height={20}
        y={350}
        rx={dataGridDefaults["rx"]}
        ry={dataGridDefaults["ry"]}
      />
      <rect
        width={"10%"}
        height={20}
        x={"90%"}
        y={350}
        rx={dataGridDefaults["rx"]}
        ry={dataGridDefaults["ry"]}
      />
    </ContentLoader>
  );
};

export default DataGridSkeleton;
