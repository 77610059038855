// UTILS
import capitalize from "utils/capitalize";
import { filterInternalGroups } from "utils/filterInternal";

// I18NEXT
import { useTranslation } from "react-i18next";

// REACT
import { useContext, useEffect, useMemo, useRef, useState } from "react";

// NAVIGATION
import { useParams } from "react-router-dom";

// FORMIK
import { useFormik } from "formik";

// APOLLO
import { PureQueryOptions, useMutation, useQuery } from "@apollo/client";
import { getAllGroupData, getGroup } from "gql/identity/queries/groupQueries";
import { getAllUsers } from "gql/identity/queries/userQueries";
import {
  addIdentitiesToGroup,
  removeIdentitiesFromGroup,
} from "gql/identity/mutations/groupMutations";

// COMPONENTS
import { QueryResultRenderer } from "components/QueryResultRenderer/QueryResultRenderer";
import { User } from "types/identity/User";
import { Group } from "types/identity/Group";
import SelectOptions from "components/Select/SelectOptions";
import { Button, ThreeStateValue } from "@abb/abb-common-ux-react";
import { GlobalNotificationService } from "services/GlobalNotification/GlobalNotificationService";
import { GlobalNotificationType } from "services/GlobalNotification/GlobalNotificationType";
import { UsersGridContext } from "layouts/main/MainLayout";
import { Identity } from "types/identity/Identity";
import useKeyPress from "libs/@abbrda/abb-common-ux-react/internalUtils/useKeyPress";

// STYLING
import styles from "./AddToGroup.module.scss";
import { isArrayEqual } from "utils/isArrayEquals";
import { ApolloContexts } from "services/ApolloService";

const returnCheckBoxType = (group: Group, users: User[]) => {
  if (
    users.every(
      (u) =>
        group.identities && group.identities.some((i) => i.id === u.identity.id)
    )
  ) {
    return ThreeStateValue.Checked;
  }
  if (
    users.some(
      (u) =>
        group.identities && group.identities.some((i) => i.id === u.identity.id)
    )
  ) {
    return ThreeStateValue.Indeterminate;
  } else {
    return ThreeStateValue.Unchecked;
  }
};

interface AddToUserForm {
  groups: any[];
}

interface Props {
  users: string[];
  close?: () => void;
}

const AddToGroup = ({ users, close = () => {} }: Props) => {
  const [initialValues, setInitialValues] = useState({ groups: [] });
  const [isInit, setIsInit] = useState(false);
  const [options, setOptions] = useState<any[]>([]);
  const [submitting, setSubmitting] = useState(false);

  const { context } = ApolloContexts.Identity;

  const { t } = useTranslation();
  const { setSelectedUsers } = useContext(UsersGridContext);

  let params = useParams<{ type?: string; id: string }>();
  const refetchQueries: (string | PureQueryOptions)[] = [
    "getAllUsers",
    "getUserByIdentityId",
    "getUser",
    "getAllGroups",
    "getAllGroupData",
  ];
  if (params.type) {
    if (params.type === "groups") {
      refetchQueries.push({
        query: getGroup,
        variables: { id: params.id },
      });
    }
  }
  const [addIdentitiesToGroupMutation] = useMutation(addIdentitiesToGroup, {
    refetchQueries,
    context
  });
  const [removeIdentitiesFromGroupMutation] = useMutation(
    removeIdentitiesFromGroup,
    {
      refetchQueries,
      context
    }
  );

  const groupsQuery = useQuery(getAllGroupData, {
    fetchPolicy: "cache-and-network",
    context
  });
  const { data: groupsData } = groupsQuery;

  const usersQuery = useQuery(getAllUsers, {
    fetchPolicy: "cache-and-network",
    context
  });
  const { data: usersData } = usersQuery;

  const filteredUsers: User[] =
    (usersData &&
      usersData.usersByNameAndEmail.filter((u: User) =>
        users.includes(u.id)
      )) ||
    [];
  const { current: usersVal } = useRef(users);

  useEffect(() => {
    if (!isInit) {
      setOptions(
        groupsData
          ? filterInternalGroups(groupsData.groupsByName)!.map((g: Group) => {
              return {
                label: g.label,
                value: g.id,
              };
            })
          : []
      );
    }
  }, [groupsData, setOptions, isInit]);

  const onSubmit = async () => {
    try {
      if (isDirty) {
        setSubmitting(true);
        const filteredUsersIdentities = filteredUsers.map((u) => u.identity.id);

        const originalGroups = groupsData?.groupsByName.filter((group: Group) =>
          group.identities?.some((i: Identity) =>
            filteredUsersIdentities.includes(i.id)
          )
        );
        const removedGroups = originalGroups.filter(
          (og: Group) => !values.groups.some((g) => og.id === g.value)
        );
        for (let group of removedGroups) {
          const usersInGroup = filteredUsersIdentities.filter((id) =>
            group.identities?.some((i: any) => i.id === id)
          );
          if (usersInGroup.length > 0) {
            await removeIdentitiesFromGroupMutation({
              variables: {
                input: {
                  identityIds: usersInGroup,
                  groupId: group.id,
                },
              },
            });
          }
        }
        const groupsSelected = values.groups.filter(
          (g) => g.state === ThreeStateValue.Checked
        );
        const addedGroups: Group[] = groupsData?.groupsByName.filter(
          (group: Group) => groupsSelected.some((gs) => gs.value === group.id)
        );
        for (let group of addedGroups) {
          const usersNotInGroup = filteredUsersIdentities.filter(
            (id) => !group.identities?.some((i: any) => i.id === id)
          );
          if (usersNotInGroup.length > 0) {
            await addIdentitiesToGroupMutation({
              variables: {
                input: {
                  identityIds: usersNotInGroup,
                  groupId: group.id,
                },
              },
            });
          }
        }
        GlobalNotificationService.publishNotification({
          text: capitalize(
            t(`app:screen.user.panel.editUser.notifications.success`)
          ),
          type: GlobalNotificationType.Success,
        });
        setSelectedUsers([]);
        close();
      }
    } catch (e: any) {
      GlobalNotificationService.publishNotification({
        text:
          e.message ||
          capitalize(t(`app:screen.user.panel.editUser.notifications.error`)),
        type: GlobalNotificationType.Alarm,
      });
      setSubmitting(false);
    }
  };

  // FORM : FORMIK
  const {
    values,
    setFieldTouched,
    handleSubmit,
    setFieldValue,
  } = useFormik<AddToUserForm>({
    initialValues: { groups: [] },
    onSubmit,
  });

  const isDirty = useMemo(
    () => !isArrayEqual(values.groups, initialValues.groups),
    [initialValues.groups, values.groups]
  );

  useEffect(() => {
    if (
      usersData &&
      usersData.usersByNameAndEmail.length &&
      groupsData?.groupsByName.length &&
      !isInit
    ) {
      const filteredUsers: User[] =
        usersData.usersByNameAndEmail.filter((u: User) =>
          users.includes(u.id)
        ) || [];
      const isNew = filteredUsers.length === 0;
      const filteredGroups = isNew
        ? []
        : groupsData?.groupsByName.filter((group: Group) =>
            group.identities?.some((identity) =>
              filteredUsers.some((u) => u.identity.id === identity.id)
            )
          );

      const currentGroups = filteredGroups
        ? filteredGroups.map((group: Group) => ({
            value: group.id,
            label: group.label,
            state: returnCheckBoxType(group, filteredUsers),
          }))
        : [];
      // resetForm({ values: { groups: [...currentGroups] } });
      setInitialValues({ groups: JSON.parse(JSON.stringify(currentGroups)) });
      setFieldValue("groups", currentGroups);
      setIsInit(true);
    }
  }, [
    users,
    usersData,
    usersVal,
    groupsData,
    setInitialValues,
    setFieldValue,
    t,
    isInit,
  ]);

  useKeyPress("Escape", () => {
    close();
  });

  return (
    <div className={styles.container}>
      <div className={styles.floatingForm}>
        <div className={styles.innerContainer}>
          <QueryResultRenderer
            queryResults={[
              { queryResult: groupsQuery, dataKey: `groupsByName` },
            ]}
          >
            {() => {
              return (
                <SelectOptions
                  value={values.groups}
                  values={options}
                  onValueChange={(v) => {
                    setFieldValue("groups", v);
                    setTimeout(() => setFieldTouched("groups", true), 500);
                  }}
                  containerStyles={styles.selectContainer}
                  submitForm={onSubmit}
                />
              );
            }}
          </QueryResultRenderer>
          <div className={styles.buttons}>
            <div className={styles.secondary}>
              <Button
                type="ghost"
                text={t("cancel")}
                className={styles.button}
                onClick={() => close()}
              />
            </div>
            <div className={styles.primary}>
              <Button
                disabled={!isDirty}
                type="primary-blue"
                text={capitalize(
                  t("app:screen.user.addToGroup.form.buttons.save")
                )}
                onClick={() => handleSubmit()}
                className={styles.button}
                isLoading={submitting}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddToGroup;
