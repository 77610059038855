import { gql } from "@apollo/client";

export const insertDomain = gql`
    mutation insertDomain(
        $value: jsonb!
        $codeListId: Int!
    ) {
        insert_master_code_list_item_one(object: {code_list_id: $codeListId, value: $value}) {
            id
            value
        }
    }
`

export const insertDomains = gql`
mutation insertDomains($objects: [master_code_list_item_insert_input!]! = []) {
  insert_master_code_list_item(objects: $objects){
    returning{
      id
      value
    }
  }
}`