import { Dropdown, DropdownOption } from "@abb/abb-common-ux-react";
import { FormikProps } from "formik";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

interface Props {
  name: string;
  formik: FormikProps<any>;
  values: { value: string; label: string }[];
  multiselect?: boolean;
  // initial state
  selectedValues?: string[];
}

const FormDropdown = ({
  name,
  values,
  formik,
  multiselect,
  selectedValues,
}: Props) => {
  const { t } = useTranslation();
  const [selected, setSelected] = useState([
    { value: "", label: "", isNew: false },
  ]);
  useEffect(() => {
    if (selectedValues) {
      setSelected(
        selectedValues.map((sel) => ({
          label: "",
          value: "",
          ...values.find((val) => val.value === sel),
          isNew: true,
        }))
      );
    }
  }, [setSelected, values, selectedValues]);

  const handleChange = (
    selection: Array<{
      value: any;
      label: string;
      isNew: boolean;
    }>
  ) => {
    setSelected(selection);
    formik.setFieldValue(
      name,
      multiselect ? selection.map((sel) => sel.value) : selection[0].value
    );
  };
  return (
    //<Input inputAttributes={{ name: name }} value={formik.values.name} onValueChange={value => formik.setFieldValue(name, value)} dataType="text" label={t(name)} />
    <Dropdown
      label={t(name)}
      multiselect={multiselect}
      value={selected}
      onChange={handleChange}
      sizeClass="large"
    >
      {values.map((value, idx) => (
        <DropdownOption
          key={`option-${name}-${idx}`}
          value={value.value}
          label={value.label}
        />
      ))}
    </Dropdown>
  );
};
export default FormDropdown;
