import { useTranslation } from "react-i18next";
import styles from "./BottomNavigation.module.scss";
import { Button } from "@abb/common-ux";

export interface WizardBottomNavigationProps {
  onClickOnBack?: () => void;
  onClickOnSubmit: () => void;
  showBackButton: boolean;
  showConfirm?: boolean;
  disableNextButton?: boolean;
}

export const WizardBottomNavigation = ({
  onClickOnBack,
  onClickOnSubmit,
  showBackButton,
  showConfirm,
  disableNextButton,
}: WizardBottomNavigationProps) => {
  const { t } = useTranslation();
  return (
    <div className={styles.main}>
        {showBackButton && (
          <Button
            type="discreet-black"
            icon="left"
            text={t(`back`)}
            size="large"
            onPress={onClickOnBack}
          />
        )}
        <Button
          onPress={onClickOnSubmit}
          icon={showConfirm ? "check-mark" : "right"}
          size="large"
          type="primary-blue"
          disabled={disableNextButton}
        >
          {showConfirm ? t(`confirm`) : t(`next`)}
        </Button>
    </div>
  );
};
