import React, { useState, useEffect } from "react";
import {
  NotificationContainer,
  NotificationData,
} from "@abb/abb-common-ux-react";
import { v4 } from "uuid";
import { GlobalNotificationService } from "services/GlobalNotification/GlobalNotificationService";
import { GlobalNotification } from "services/GlobalNotification/GlobalNotification";
export const GlobalNotificationComponent = () => {
  const [notifications, setNotifications] = useState<NotificationData[]>([]);
  const onNotification = (notification: GlobalNotification) => {
    setNotifications((notifications) => [
      ...notifications,
      {
        id: v4(),
        type: "banner",
        discreet: false,
        severity: notification.type,
        text: notification.text,
        timeout: 4000,
      },
    ]);
  };
  useEffect(() => {
    GlobalNotificationService.onNotificationPublished(onNotification);
    return () =>
      GlobalNotificationService.offNotificationPublished(onNotification);
  }, []);
  return (
    <NotificationContainer
      actionHandler={{ remove: () => {} }}
      notifications={notifications}
      style={{
        position: "absolute",
        top: "80px",
        left: "20px",
      }}
    />
  );
};
