// UTILS
import capitalize from "utils/capitalize";

// NAVIGATION
import { Route, Switch, useLocation } from "react-router-dom";

// I18NEXT
import { useTranslation } from "react-i18next";

// COMPONENTS
import { DashboardContainer } from "components/DashboardContainer/DashboardContainer";
import { ProductsSidebar } from "./sidebar/Sidebar";
import { ProductsDataGrid } from "./ProductsDataGrid";

// STYLING
import {
  Col,
  Row,
  Text,
  useTheme,
  useViewLayoutContentStyles,
  ViewLayout,
  ViewLayoutContent,
} from "@abb/common-ux";
import { Footer } from "components/Footer/Footer";
import InfoButton from "components/InfoButton";

export const Products = () => {
  const { t } = useTranslation();
  const location = useLocation<{ name: string; description: string }>();

  const { theme } = useTheme();
  const { title: titleStyle } = useViewLayoutContentStyles(theme.preset, {});
  return (
    <DashboardContainer sidebar={<ProductsSidebar />}>
      <ViewLayout
        navigationTitle={capitalize(t("roles"))}
        footerTemplate={<Footer />}
      >
        <Switch>
          <Route path="/roles/:name">
            <ViewLayoutContent
              title={
                <Row>
                  <Text style={titleStyle}>{location.state?.name}</Text>
                  <InfoButton>{location.state?.description}</InfoButton>
                </Row>
              }
            >
              <Col>
                <ProductsDataGrid />
              </Col>
            </ViewLayoutContent>
          </Route>
          <Route path="/roles/" />
        </Switch>
      </ViewLayout>
    </DashboardContainer>
  );
};
