// REACT
import { useContext, useEffect, useRef, useState } from "react";

// I18NEXT
import { useTranslation } from "react-i18next";

// NAVIGATION
import { Route, Switch, useParams } from "react-router-dom";

// CUSTOM HOOKS
import { useLoggedUserPermission } from "libs/hooks/useLoggedUserPermission";

// COMPONENTS
import { DashboardContainer } from "components/DashboardContainer/DashboardContainer";
import { UserFilter } from "./panels/Filters";
import { UsersSidebar } from "./sidebar/Sidebar";
import { UsersGridContext } from "layouts/main/MainLayout";
import GroupsSummary from "./routedContent/GroupsSummary";
import UsersSection from "./routedContent/UsersSection";
import RemoveUser from "./panels/RemoveUser";
import AddToGroup from "./AddToGroup";
import { User } from "./panels/User";
import { Group } from "./panels/Group";
import PopupButton from "components/PopupButton/PopupButton";

// STYLING
import styles from "./Users.module.scss";
import cx from "classnames";
import { Button, SplitButton, ViewLayout } from "@abb/common-ux";
import { Footer } from "components/Footer/Footer";
import { usePanel } from "components/Panel/PanelContext";
import { useQuery } from "@apollo/client";
import { getProviders } from "gql/identity/queries/providerQueries";
import { ApolloContexts } from "services/ApolloService";

export const getFilterStatus = (filters?: UserFilter) => {
  let count = 0;
  let key: keyof UserFilter;
  if (filters) {
    for (key in filters) {
      if (
        (typeof filters![key] === "string" && filters![key] !== "") ||
        (typeof filters![key] === "object" && filters![key]!.length)
      ) {
        count++;
      }
    }
    return count > 0;
  }
  return false;
};

export enum UserType {
  local,
  external,
}

interface OidcProvider {
  id: string;
  isActive: string;
}

export const Users = () => {
  // Contexts
  const { setPanel } = usePanel();
  const { selectedUsers, setSelectedUsers } = useContext(UsersGridContext);
  // Utils
  const { hasEditUserPermission } = useLoggedUserPermission();
  const { t } = useTranslation();
  const ref = useRef(null);
  const params = useParams<{ type?: string; id: string }>();

  // States
  const [search, setSearch] = useState("");
  const [preselectedAllUsers, setPreselectedAllUsers] = useState<string>("");
  const [preselectedGroup, setPreselectedGroup] = useState<string>("");
  const [filters, setFilters] = useState<UserFilter | undefined>({
    groups: [],
    email: "",
    phone: "",
  });

  const { context } = ApolloContexts.Identity;

  // API
  let areThereActiveProviders = false;
  const { data, loading } = useQuery(getProviders, {
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-only",
    context
  });
  if (!loading && data) {
    areThereActiveProviders = data.allOidcProviders.some(
      (x: OidcProvider) => x.isActive
    );
  }
  // Methods
  const resetFilters = () => {
    setSelectedUsers([]);
    setFilters({
      groups: [],
      email: "",
      phone: "",
    });
  };

  const renderAddUserPanel = (optionId: string): void => {
    setPanel(
      <User
        users={[]}
        defaultGroups={defaultGroups}
        isUserAD={!(optionId === UserType[UserType.local])}
      />
    );
  };
  const handleAllUserSelected = () => {
    // Pass a truthy un-existent id to provoke tree view selection clear
    setPreselectedAllUsers("#");
  };
  const handleOnGroupSelected = () => {
    resetFilters();
    // Pass a truthy un-existent id to provoke tree view selection clear
    setPreselectedGroup("#");
  };
  const navigationTitle =
    params?.type === "groups" ? t(`userGroups`) : t(`allUsers`);

  const defaultGroups = params.type === "groups" ? [params.id] : [];
  const defaultGroup = params?.type === "groups" ? params.id : undefined;

  // Effects
  useEffect(() => {
    if (defaultGroup) {
      setPreselectedGroup(defaultGroup);
      handleOnGroupSelected();
    } else {
      setPreselectedAllUsers("all");
      handleAllUserSelected();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultGroup]);

  return (
    <DashboardContainer
      sidebar={
        <UsersSidebar
          onGroupSelected={handleOnGroupSelected}
          preSelectedGroupId={preselectedGroup}
          preSelectedAllUsersId={preselectedAllUsers}
          onAllUsersSelected={handleAllUserSelected}
        />
      }
    >
      <ViewLayout
        navigationTitle={navigationTitle}
        footerTemplate={<Footer />}
        viewLayoutRight={
          <div className={styles.actionsContainer} ref={ref}>
            {selectedUsers.length ? (
              <div
                className={cx(
                  styles.groupActions,
                  hasEditUserPermission ? "" : "hidden"
                )}
              >
                <h4 className={styles.groupActionsCounter}>
                  {selectedUsers.length} {t("selected")}
                </h4>
                <PopupButton
                  text={t("addToGroup")}
                  component={<AddToGroup users={selectedUsers}></AddToGroup>}
                />
                <Button
                  icon="trash"
                  type="primary-red"
                  onPress={() => setPanel(<RemoveUser users={selectedUsers} />)}
                />
              </div>
            ) : (
              <>
                {hasEditUserPermission && (
                  <>
                    <Button
                      onPress={() => setPanel(<Group />)}
                      type="primary-blue"
                      text={t("addGroup")}
                      size="large"
                    />
                    {areThereActiveProviders ? (
                      <SplitButton
                        id="add-user-button"
                        size="large"
                        icon="abb_plus"
                        items={[
                          {
                            id: UserType[UserType.external],
                            text: t`addADUser`,
                            icon: "user",
                          },
                          {
                            id: UserType[UserType.local],
                            text: t`addLocalUser`,
                            icon: "user",
                          },
                        ]}
                        onPress={renderAddUserPanel}
                        text={t("addUser")}
                        type="primary-blue"
                        appendTo={document.body}
                      />
                    ) : (
                      <Button
                        onPress={() =>
                          setPanel(
                            <User users={[]} defaultGroups={defaultGroups} />
                          )
                        }
                        type="primary-blue"
                        text={t("addUser")}
                        size="large"
                      />
                    )}
                  </>
                )}
              </>
            )}
          </div>
        }
      >
        <Switch>
          <Route path="/users/:section/:idElement">
            <UsersSection
              filters={filters}
              search={search}
              setSearch={setSearch}
              setFilters={setFilters}
            />
          </Route>
          <Route path="/users/groups">
            <GroupsSummary
              filters={filters}
              setSearch={setSearch}
              search={search}
            />
          </Route>
          <Route path="/users">
            <UsersSection
              filters={filters}
              search={search}
              setSearch={setSearch}
              setFilters={setFilters}
            />
          </Route>
        </Switch>
      </ViewLayout>
    </DashboardContainer>
  );
};
