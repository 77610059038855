// UTILS
import capitalize from "utils/capitalize";

// I18NEXT
import { useTranslation } from "react-i18next";

// COMPONENTS
import { Dropdown, DropdownOption } from "@abb/abb-common-ux-react";
import { Input } from "libs/@abbrda/abb-common-ux-react/src/components/Input";
import { QueryResultRenderer } from "components/QueryResultRenderer/QueryResultRenderer";
// import { User as UserType } from "types/identity/User";
import AbbSelect from "components/Select/Select";
import TextInput from "components/FormikCommonUX/Text";
import ClickableLink from "components/ClickableLink/ClickableLink";
import FormSkeleton from "components/Skeletons/commonux/Form";

// STYLING
import styles from "./User.module.scss";
import { Button, SlidePanel } from "@abb/common-ux";
import { UserProps, useUserHook } from "./hooks/useUserHook";
import { UserType } from "../Users";

export const User = ({ users, defaultGroups, isUserAD }: UserProps) => {
  const {
    isOpen,
    closePanel,
    isNew,
    isHimself,
    onRemoveHandler,
    oldValues,
    values,
    isValid,
    submitting,
    handleSubmit,
    groupsQuery,
    usersQuery,
    setFieldValue,
    setFieldTouched,
    handleValidate,
    filteredUsers,
    isUserSelectedSuperUser,
    groupsOptions,
    formik,
    onChangePasswordHandler,
    groupsText,
  } = useUserHook({ users, defaultGroups, isUserAD });
  const { t } = useTranslation();
  return (
    <SlidePanel
      isOpen={isOpen}
      closePanel={closePanel}
      title={capitalize(
        isNew
          ? isUserAD
            ? t("app:screen.user.panel.addUser.ADUser.title")
            : t("app:screen.user.panel.addUser.localUser.title")
          : users.length === 1
          ? values.type === UserType[UserType.external]
            ? t("app:screen.user.panel.editUser.ADUser.title")
            : t("app:screen.user.panel.editUser.localUser.title")
          : t("app:screen.user.panel.editUser.title")
      )}
      headerActions={
        isNew || isHimself ? null : (
          <Button
            icon="trash"
            type="discreet-red"
            onPress={onRemoveHandler}
            text={capitalize(
              users.length === 1
                ? t("app:screen.user.panel.editUser.buttons.deleteUser")
                : t("app:screen.user.panel.editUser.buttons.deleteUsers")
            )}
          />
        )
      }
      bottomActions={
        <>
          <Button
            type="discreet-black"
            text={t("cancel")}
            onPress={closePanel}
          />
          <Button
            disabled={oldValues === values || !isValid || submitting}
            type="primary-blue"
            text={capitalize(
              isNew
                ? t("app:screen.user.panel.user.form.buttons.addUser")
                : t("app:screen.user.panel.editUser.buttons.save")
            )}
            onPress={() => handleSubmit()}
          />
        </>
      }
    >
      <QueryResultRenderer
        queryResults={[
          { queryResult: groupsQuery, dataKey: `groupsByName` },
          { queryResult: usersQuery, dataKey: `usersByNameAndEmail` },
        ]}
        skeleton={<FormSkeleton inputs={7} />}
      >
        {() => {
          return (
            <div className={styles.form}>
              {!isUserAD && (
                <>
                  <Input
                    label={capitalize(
                      t("app:screen.user.panel.user.form.fields.name")
                    )}
                    dataType={"text"}
                    onValueChange={(v) => setFieldValue("firstName", v)}
                    value={values.firstName}
                    onLostFocus={() => setFieldTouched("firstName")}
                    showValidationBarWhenInvalid={true}
                    showValidationIconWhenInvalid={true}
                    validationResult={handleValidate("firstName")}
                    disabled={
                      users.length > 1 ||
                      values.type === UserType[UserType.external]
                    }
                    maxLength={50}
                  />
                  <Input
                    label={capitalize(
                      t("app:screen.user.panel.user.form.fields.surname")
                    )}
                    dataType={"text"}
                    onValueChange={(v) => setFieldValue("lastName", v)}
                    value={values.lastName}
                    onLostFocus={() => setFieldTouched("lastName")}
                    showValidationBarWhenInvalid={true}
                    showValidationIconWhenInvalid={true}
                    validationResult={handleValidate("lastName")}
                    disabled={
                      users.length > 1 ||
                      values.type === UserType[UserType.external]
                    }
                    maxLength={50}
                  />
                </>
              )}
              <Input
                label={capitalize(
                  t("app:screen.user.panel.user.form.fields.email")
                )}
                dataType={"email"}
                onValueChange={(v) => setFieldValue("email", v)}
                value={values.email}
                showValidationBarWhenInvalid={true}
                showValidationIconWhenInvalid={true}
                onLostFocus={() => setFieldTouched("email")}
                validationResult={handleValidate("email")}
                disabled={!isNew}
                className={styles.input}
                maxLength={50}
                required
              />
              {isUserSelectedSuperUser ? null : users.length > 1 ? (
                <AbbSelect
                  values={groupsOptions}
                  title={capitalize(
                    t("app:screen.user.panel.user.form.fields.groups")
                  )}
                  onValueChange={(v) => {
                    setFieldValue("groups", v);
                    setTimeout(() => setFieldTouched("groups"), 500);
                  }}
                  value={values.groups}
                  placeholder={capitalize(
                    t("app:screen.user.panel.user.form.placeholders.groups")
                  )}
                  errorMsg={handleValidate("groups")}
                  onBlur={() => setFieldTouched("groups")}
                  stylesSelectContainer={styles.selectContainer}
                  customValue={groupsText}
                  className={styles.dropdown}
                />
              ) : (
                <Dropdown
                  label={capitalize(
                    t("app:screen.user.panel.user.form.fields.groups")
                  )}
                  value={values.groups}
                  multiselect={true}
                  onChange={(v) => {
                    setFieldValue("groups", v);
                    setTimeout(() => setFieldTouched("groups"), 500);
                  }}
                  showValidationBarWhenInvalid={true}
                  showValidationIconWhenInvalid={true}
                  validationState={{
                    valid: handleValidate("groups") === "",
                    message: handleValidate("groups"),
                  }}
                  clearable={true}
                  searchable={true}
                  clearOnEscape={true}
                  sizeClass="large"
                  id={"groups"}
                  className={styles.dropdown}
                >
                  {groupsOptions.map(({ id, label }) => (
                    <DropdownOption
                      key={`group_${id}`}
                      label={label}
                      value={id}
                      disabled={false}
                    />
                  ))}
                </Dropdown>
              )}
              {values.type === UserType[UserType.local] && (
                <>
                  <Input
                    label={capitalize(
                      t("app:screen.user.panel.user.form.fields.phone")
                    )}
                    dataType={"tel"}
                    onValueChange={(v) => setFieldValue("phone", v)}
                    value={values.phone}
                    onLostFocus={() => setFieldTouched("phone")}
                    showValidationBarWhenInvalid={true}
                    showValidationIconWhenInvalid={true}
                    validationResult={handleValidate("phone")}
                    disabled={users.length > 1}
                    maxLength={50}
                  />
                  {isNew ? (
                    <>
                      <TextInput
                        name="initialPassword"
                        label={capitalize(
                          t(
                            "app:screen.user.panel.user.form.fields.initialPassword"
                          )
                        )}
                        dataType="password"
                        formik={formik}
                        placeholder={""}
                        required
                      />
                      <TextInput
                        name="repeatPassword"
                        label={capitalize(
                          t(
                            "app:screen.user.panel.user.form.fields.repeatPassword"
                          )
                        )}
                        dataType="password"
                        formik={formik}
                        placeholder={""}
                        required
                      />
                      <span className={styles.passswordTip}>
                        {t("app:screen.user.panel.user.form.passwordTip")}
                      </span>
                    </>
                  ) : (
                    filteredUsers.length === 1 && (
                      <div>
                        <div className={styles.resetPassword}>
                          <ClickableLink
                            clickAction={onChangePasswordHandler}
                            icon={"abb/lock-closed"}
                            text={capitalize(
                              t(
                                "app:screen.user.panel.user.form.fields.resetPassword"
                              )
                            )}
                          ></ClickableLink>
                        </div>
                        <span className={styles.passswordTip}>
                          {t("app:screen.user.panel.user.form.passwordTip")}
                        </span>
                      </div>
                    )
                  )}
                </>
              )}
            </div>
          );
        }}
      </QueryResultRenderer>
    </SlidePanel>
  );
};
