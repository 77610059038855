import React from "react";
import useDimensions from "react-cool-dimensions";
import styles from "./Skeleton.module.scss";

export const skeletonDefaults = {
  x: 0,
  y: 0,
  rx: 4,
  ry: 4,
  width: 32,
  height: 32,
  padding: 2,
  margin: 16,
};

const Skeleton = ({ children }: any) => {
  const { ref: refDimension, width, height } = useDimensions();

  return (
    <div
      data-cy="skeleton"
      style={{ width: "100%", height: "100%" }}
      className={styles.responsiveContainer}
      ref={refDimension as React.RefObject<HTMLDivElement>}
    >
      {React.cloneElement(children, {
        width,
        height,
      })}
    </div>
  );
};

export default Skeleton;
