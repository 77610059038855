import { useContext, useEffect, useMemo, useState } from "react";
import { useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import {
  Datagrid,
  Pagination,
  ThreeStateValue,
} from "@abb/abb-common-ux-react";

import { getServiceByName } from "gql/identity/queries/servicesQueries";
import { DataGridSortDetails } from "types/DataGridSortDetails";
import { Product } from "types/identity/Product";
import { Role } from "types/identity/Role";
import { Group } from "types/identity/Group";
import { useLoggedUserPermission } from "libs/hooks/useLoggedUserPermission";

import { QueryResultRenderer } from "components/QueryResultRenderer/QueryResultRenderer";
import { Tags } from "components/Tags/Tags";
import Groups from "./panels/Groups";
import { getAllGroupData } from "gql/identity/queries/groupQueries";
import { useCallback } from "react";
import DataGridSkeleton from "components/Skeletons/commonux/DataGrid";
import {
  filterInternalGroups,
  filterInternalRoles,
} from "utils/filterInternal";
import styles from "./Products.module.scss";
import { usePanel } from "components/Panel/PanelContext";
import { ProductsGridContext } from "layouts/main/MainLayout";
import { Button } from "@abb/common-ux";
import usePaginationTranslation from "../../libs/hooks/usePagination";
import { ApolloContexts } from "services/ApolloService";
import { filterSelectableGroups } from "utils/filterSelectableGroups";
export interface ProductsDataGridRef {
  refetch: () => void;
}

interface RoleActionsProps {
  id: string;
  serviceId: string;
}

const RoleActions = ({ id, serviceId }: RoleActionsProps) => {
  const { setPanel } = usePanel();
  const { selectedProductRoles } = useContext(ProductsGridContext);
  const roles =
    selectedProductRoles.length > 0 && selectedProductRoles.includes(id)
      ? selectedProductRoles
      : [id];
  return (
    <Button
      icon="edit"
      type="discreet-black"
      onPress={() => setPanel(<Groups roles={roles} service={serviceId} />)}
    />
  );
};

export const ProductsDataGrid = () => {
  const { hasEditProductPermission } = useLoggedUserPermission();
  const { t, i18n } = useTranslation();
  const { name } = useParams<{ name?: string }>();
  const { selectedProductRoles, setSelectedProductRoles } = useContext(ProductsGridContext);

  const [productRoles, setProductRoles] = useState<any[]>([]);

  const { context } = ApolloContexts.Identity;

  const productsQuery = useQuery(getServiceByName, {
    fetchPolicy: "cache-and-network",
    variables: {
      nameFilter: name,
    },
    skip: !name,
    context,
  });

  const {
    data: productsData,
  }: { data: { servicesByName: Product[] } } = productsQuery;

  const { data: groupsData } = useQuery(getAllGroupData, {
    fetchPolicy: "network-only",
    context,
  });

  const selectAllState = useMemo(() => {
    if (productRoles.length === selectedProductRoles.length) {
      return ThreeStateValue.Checked;
    }

    if (selectedProductRoles.length > 0 ) {
      return ThreeStateValue.Indeterminate;
    }

    return ThreeStateValue.Unchecked

  }, [productRoles.length, selectedProductRoles.length]);


  const [sortDetails, setSortDetails] = useState<DataGridSortDetails | null>({
    field: "id",
    desc: true,
  });

  const pageSizes = [10, 30, 50];
  const [pageSize, setPageSize] = useState(pageSizes[0]);
  const [page, setPage] = useState(0);

  // UTILS
  const getProductRoles = (product: Product) =>
    filterInternalRoles(product?.identity?.ownedRoles)?.map((role: Role) => ({
      id: role.id,
      name: role.name,
      label: role.label,
    }));

  const getRoleGroups = useCallback(
    (role: Role, serviceId: string) => {
      const roleGroups = filterInternalGroups(role.groups) || [];

      const filteredGroupsIds: string[] = filterSelectableGroups(
        groupsData?.groupsByName || [],
        serviceId
      ).map((g: Group) => g.id);

      return roleGroups
        .filter((roleGroup) => filteredGroupsIds.includes(roleGroup.id))
        .map((group: Group) => ({
          id: group.id,
          name: group.label,
        }));
    },
    [groupsData?.groupsByName]
  );

  // ACTIONS
  const handleOnProductRoleSelected = (rowIndex: string) => {
    const newSelectedProducts = productRoles.map((user: any, index: any) => {
      return {
        ...user,
        isSelected: rowIndex === index ? !user.isSelected : user.isSelected,
      }
    });

    setProductRoles(newSelectedProducts);

    setSelectedProductRoles(newSelectedProducts.reduce((selectedProducts, currentProd) => {
      if (currentProd.isSelected) {
        selectedProducts.push(currentProd.fields.id);
      }
      
      return selectedProducts;
    }, []));
  };

  const handleSelectAll = useCallback(() => {
    const shouldSelectAll = selectAllState === ThreeStateValue.Unchecked || selectAllState === ThreeStateValue.Indeterminate;

    const productRolesSelected = productRoles.map((u: Product) => {
      return {
        ...u,
        isSelected: shouldSelectAll,
      };
    });
    setProductRoles(productRolesSelected);

    const selectedRolesIds = shouldSelectAll
    ? productRolesSelected.map(({ fields: { id } }: any) => id)
    : [];

    setSelectedProductRoles(selectedRolesIds);
  }, [productRoles, selectAllState, setSelectedProductRoles]);

  /**
   * Maps a role entity to a role datagrid row
   * @param role role entity
   * @param serviceId service identity id the role belongs to
   */
  const toRoleRow = useCallback(
    (role: Role, serviceId: string, serviceName: string) => {
      const roleNameArray = role.name.split(".");
      const roleKey = `roles:${serviceName}.roles.${
        roleNameArray[roleNameArray.length - 1]
      }`;
      return {
        fields: {
          id: role.id,
          description: i18n.exists(`${roleKey}.description`)
            ? t(`${roleKey}.description`)
            : role.description,
          role: i18n.exists(`${roleKey}.displayName`)
            ? t(`${roleKey}.displayName`)
            : role.label || role.name,
          groups: (
            <Tags
              maxVisibleTags={2}
              tags={getRoleGroups(role, serviceId)}
            ></Tags>
          ),
          actions: hasEditProductPermission ? (
            <RoleActions id={role.id} serviceId={serviceId} />
          ) : null,
        },
        isSelected: selectedProductRoles.includes(role.id),
      };
    },
    [getRoleGroups, hasEditProductPermission, i18n, selectedProductRoles, t]
  );

    // HOOK
    const paginationTranslation = usePaginationTranslation();

    /**
     * Clean selected items when name param changes.
     */
    useEffect(() => {
      setSelectedProductRoles([]);
    }, [name, setSelectedProductRoles]);  
  
    // USERS DATA
    useEffect(() => {
      if (productsData?.servicesByName.length) {
        const service = productsData?.servicesByName[0];
        const visibleRoles: Role[] = filterInternalRoles(
          service.identity.ownedRoles
        )!;
  
        setProductRoles(
          getProductRoles(service)!.map((role: Role) => {
            const fullRole = visibleRoles.filter((r) => r.id === role.id)[0];
            return toRoleRow(fullRole, service.identity.id, service.name);
          })
        );
      } else {
        setProductRoles([]);
      }
    }, [productsData?.servicesByName, toRoleRow]);


  const queryResults = [
    {
      queryResult: productsQuery,
      dataKey: "servicesByName",
    },
  ];

  return (
    <QueryResultRenderer
      queryResults={queryResults}
      skeleton={<DataGridSkeleton id={"product_datagrid_skeleton"} />}
    >
      {() => {
        return (
          <>
            <div className={styles.dataGridContainer}>
              <Datagrid
                className="pb-4"
                data={productRoles}
                columns={[
                  { fieldKey: "role", title: t("role"), width: 300 },
                  { fieldKey: "description", title: t("description") },
                  { fieldKey: "groups", title: t("groups") },
                  {
                    fieldKey: "actions",
                    sortable: false,
                    width: 44,
                    maxWidth: 44,
                  },
                ]}
                fitToContent={true}
                pageSize={pageSize}
                page={page}
                sortOrder={sortDetails ? sortDetails : undefined}
                onSort={(sort: any) =>
                  sort
                    ? setSortDetails({ field: sort.field, desc: sort.desc })
                    : setSortDetails(null)
                }
                onToggleRowSelect={(rowIndex) =>
                  handleOnProductRoleSelected(rowIndex)
                }
                enableColumnDragging={false}
                zebraColoring
                enableSorting
                enableRowSelection={hasEditProductPermission}
                borderStyle="discreet"
                onToggleSelectAll={handleSelectAll}
              />
            </div>
            <Pagination
              showPageSelectionInput={true}
              onGotoPage={(newPage) => setPage(newPage)}
              onSetPageSize={setPageSize}
              currentPage={page}
              currentPageSize={pageSize}
              rowCount={productRoles.length}
              showPrevNext={true}
              showNumberButtons={false}
              showInput={true}
              pageSizeOptions={pageSizes}
              texts={paginationTranslation}
            />
          </>
        );
      }}
    </QueryResultRenderer>
  );
};
