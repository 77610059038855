// React
import React from "react";

// i18n
import { useTranslation } from "react-i18next";

// Formik
import { FormikProps, useFormik } from "formik";

// Common UX
import { Button, ButtonProps, Icon } from "@abb/abb-common-ux-react";
import TextInput from "components/FormikCommonUX/Text";

// Styles
import styles from "./ListInput.module.scss";

export interface ListInputProps {
  name: string;
  formik: FormikProps<any>;
  label?: string;
  maxLength?: number;
  placeholder?: string;
  createButtonProps?: ButtonProps;
  children?: React.ReactNode;
  helperText?: string;
}

interface ListItemValues {
  add: string;
}

interface ListInputItemProps {
  value: string;
  children?: React.ReactNode;
  onRemove: Function;
}

const ListInputItem = ({ value, onRemove }: ListInputItemProps) => {
  return (
    <li>
      <span>{value}</span>
      <Icon
        color="color-grey-50"
        name="abb/close"
        sizeClass="small"
        onClick={() => onRemove()}
      ></Icon>
    </li>
  );
};

const ListInput = ({
  name,
  label,
  placeholder,
  createButtonProps,
  formik,
  maxLength,
  helperText,
}: ListInputProps) => {
  // I18n
  const { t } = useTranslation();

  // Defaults
  const listInputItems: string[] = formik.values[name] || [];

  const defaultCreateButtonProps = {
    icon: "abb/plus-in-circle",
    type: "primary-black",
    text: t("add"),
    sizeClass: "large",
  } as ButtonProps;

  const createButtonPropsParams = {
    ...defaultCreateButtonProps,
    ...createButtonProps,
  };

  // Handlers
  const handleAdd = (values: ListItemValues) => {
    if (values.add) {
      const items = formik.values[name] || [];
      const newItems = [...items, values.add];
      formik.setFieldValue(name, newItems);
      listInputFormik.resetForm();
    }
  };

  const handleCreate = () => {
    listInputFormik.submitForm();
  };

  const handleRemove = (index: number) => {
    const items: string[] = formik.values[name] || [];
    const newItems = items.filter((item, ix) => ix !== index);
    formik.setFieldValue(name, newItems);
  };

  // Formik
  const listInputFormik = useFormik<ListItemValues>({
    initialValues: {
      add: "",
    },
    onSubmit: handleAdd,
  });

  return (
    <div className={styles.container}>
      <div className={styles.addFormContainer}>
        <div className={styles.textInputContainer}>
          <TextInput
            maxLength={maxLength}
            name="add"
            label={label}
            placeholder={placeholder}
            formik={listInputFormik}
            helperText={helperText}
          />
        </div>
        <Button
          {...createButtonPropsParams}
          disabled={!listInputFormik.values.add.trim()}
          onClick={handleCreate}
        />
      </div>
      <div className={styles.listContainer}>
        <ul>
          {listInputItems.map((item, index) => (
            <ListInputItem
              value={item}
              key={index}
              onRemove={() => handleRemove(index)}
            />
          ))}
        </ul>
      </div>
    </div>
  );
};

export default ListInput;
