// UTILS
import capitalize from "utils/capitalize";
import useKeyPress from "libs/@abbrda/abb-common-ux-react/internalUtils/useKeyPress";

// REACT
import { useCallback, useState } from "react";

// I18NEXT
import { Trans, useTranslation } from "react-i18next";

// COMPONENTS
import { GlobalNotificationService } from "services/GlobalNotification/GlobalNotificationService";
import { GlobalNotificationType } from "services/GlobalNotification/GlobalNotificationType";

// STYLING
import styles from "./RemovePanel.module.scss";
import { usePanel } from "./PanelContext";
import { Button, SlidePanel } from "@abb/common-ux";

interface RemovePanelProps {
  elementIds: string[];
  elementName: string;
  resourcePath: string;
  removeHandler: () => Promise<number>;
  setSelectedElements?: (values: any[]) => void;
}

const RemovePanel = ({
  elementIds,
  elementName,
  resourcePath,
  removeHandler,
  setSelectedElements = () => {},
}: RemovePanelProps) => {
  // STATE
  const [submitting, setSubmitting] = useState(false);

  // CONTEXT
  const { isOpen, closePanel } = usePanel();

  // I18NEXT
  const { t } = useTranslation();

  const renderDescription = () => {
    if (elementIds.length === 1) {
      return capitalize(
        t(`${resourcePath}.single.description`, {
          name: `${elementName}`,
        })
      );
    } else {
      return (
        <Trans
          i18nKey={`${resourcePath}.multiple.description`}
          values={{ numberElements: elementIds.length }}
        >
          {[
            <strong
              className={styles.elementNumber}
              key={"assetNumber_key"}
            ></strong>,
          ]}
        </Trans>
      );
    }
  };

  const onDeleteHandler = useCallback(async () => {
    setSubmitting(true);
    try {
      const elementsRemoved = await removeHandler();
      const notificationText = t(
        `${resourcePath}.notifications.success.elementRemoved`,
        {
          count: elementsRemoved,
          context: `${elementsRemoved}`,
        }
      );
      GlobalNotificationService.publishNotification({
        text: capitalize(notificationText),
        type:
          elementsRemoved === 0
            ? GlobalNotificationType.Warn
            : GlobalNotificationType.Success,
      });
      setSelectedElements([]);
      closePanel();
    } catch (e: any) {
      const errorText = t(
        `${resourcePath}.notifications.success.elementRemoved`,
        {
          count: elementIds.length,
          context: `${elementIds.length}`,
        }
      );
      GlobalNotificationService.publishNotification({
        text: e.message || errorText,
        type: GlobalNotificationType.Alarm,
      });
      setSubmitting(false);
    }
  }, [
    closePanel,
    elementIds.length,
    removeHandler,
    setSelectedElements,
    t,
    resourcePath,
  ]);

  useKeyPress("Escape", () => {
    closePanel();
  });

  useKeyPress("Enter", () => {
    onDeleteHandler();
  });

  return (
    <SlidePanel
      isOpen={isOpen}
      closePanel={closePanel}
      title={capitalize(
        elementIds.length === 1
          ? t(`${resourcePath}.single.title`)
          : t(`${resourcePath}.multiple.title`)
      )}
      bottomActions={
        <>
          <Button
            type="discreet-black"
            text={t("delete")}
            onPress={onDeleteHandler}
            disabled={submitting}
          />
          <Button
            type="primary-blue"
            text={capitalize(t("cancel"))}
            onPress={closePanel}
          />
        </>
      }
    >
      <div className={styles.form}>
        <div>
          <span>{renderDescription()}</span>
        </div>
      </div>
    </SlidePanel>
  );
};

export default RemovePanel;
