import { gql } from "@apollo/client";

export const UserFields = `
	fragment User on User {
        id
        ref
        email
        phone
        firstName
        lastName
        identity{
          id
        }
	}
`;

// USER : FRAGMENTS
export const UserFragment = gql`
  ${UserFields}
`;

export const createUser = gql`
  mutation createUser($input: CreateUserInput!) {
    createUser(input: $input) {
      ...User
    }
  }
  ${UserFragment}
`;

export const updateUser = gql`
  mutation updateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      ...User
    }
  }
  ${UserFragment}
`;
export const resetUserStatus = gql`
  mutation resetStatus($userId: String!) {
    updateUserStatus(input: { userId: $userId, status: initialized }) {
      ...User
    }
  }
  ${UserFragment}
`;

export const deleteUser = gql`
  mutation deleteUser($userId: String!) {
    deleteUser(input: { userId: $userId })
  }
`;

export const deleteUsers = gql`
  mutation deleteUsers($input: DeleteUsersInput!) {
    deleteUsers(input: $input) {
      errors {
        message
      }
    }
  }
`;
