// STYLING
import cx from "classnames";
import styles from "./CardContainer.module.scss";

interface CardContainerProps {
  children?: JSX.Element | JSX.Element[];
  className?: string;
}

export const CardContainer = (props: CardContainerProps) => {
  return (
    <div className={cx(styles.cardContainer, props.className)}>
      {props.children}
    </div>
  );
};
