import { Icon } from "@abb/common-ux";
import { FC } from "react";
import styles from "./TreeViewHeader.module.scss";
import { STRUCTURE_TREE_ICON_WIDTH } from "config/constants";

export interface TreeViewHeaderProps {
  text: string;
  icon?: string;
  maxWidth?: number;
}

export const TreeViewHeader: FC<TreeViewHeaderProps> = ({
  text,
  icon,
  maxWidth,
}) => {
  return (
    <div
      className={styles.container}
      style={
        maxWidth
          ? {
              // Subtract the icon width from the maxWidth to ensure the text fits
              // Icon in slightly bigger than in children, so we multiply by 1.2
              maxWidth: maxWidth - (icon ? 1.2 * STRUCTURE_TREE_ICON_WIDTH : 0),
            }
          : {}
      }
    >
      {icon && <Icon name={icon} size={STRUCTURE_TREE_ICON_WIDTH} />}
      <span className={styles.text}>{text}</span>
    </div>
  );
};
