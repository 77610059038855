import { gql } from "@apollo/client";

export const getDomains = gql`
    query getDomains {
        master_code_list_item(where: {master_code_list_object: {description: {_eq: "AssetDomains"}}}) {
            id
            value
        }
    }
`

export const getDomainsListId = gql`
    query getDomainsListId {
        master_code_list(where: {description: {_eq: "AssetDomains"}}) {
            id
        }
    }
`