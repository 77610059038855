// CONSTANTS
import { PERMISSIONS } from "config/constants";

// LIBS
import { AuthProviderOptions } from "@abb/identity-auth-react";
import { broker } from "@abb/smartot-common-platform";

const packageJson = require("../../package.json");

export const getAuthConfig = async (): Promise<AuthProviderOptions> => {
  const factoryManagerUrl =
    process.env.REACT_APP_FRONTEND_FACTORY_MANAGER_URL ||
    ((await broker.cacher.get("FRONTEND_PORTAL_URL")) as string);
  const identityServerUrl =
    process.env.REACT_APP_FRONTEND_IDENTITY_URL ||
    ((await broker.cacher.get("FRONTEND_IDENTITY_URL")) as string);
  return {
    authority: `${identityServerUrl}/oauth2`,
    clientId: "portal",
    responseType: "code",
    scope: "openid offline",
    redirectUri: factoryManagerUrl,
    postLogoutUri: factoryManagerUrl,
    audiences: ["portal"],
    enableOidcClientLogging: false,
  };
};

export const getConfig = async (token?: string) => {
  const identityServerUrl =
    process.env.REACT_APP_FRONTEND_IDENTITY_URL ||
    ((await broker.cacher.get("FRONTEND_IDENTITY_URL")) as string);
  const hasuraEndpointUrl =
    process.env.REACT_APP_FRONTEND_HASURA_ENDPOINT ||
    ((await broker.cacher.get("FRONTEND_HASURA_ENDPOINT")) as string);
  const wsHasuraEndpointUrl =
    process.env.REACT_APP_WS_FRONTEND_HASURA_ENDPOINT ||
    ((await broker.cacher.get("FRONTEND_HASURA_ENDPOINT", true)) as string);
  const assetsApiEndpointUrl =
    process.env.REACT_APP_FRONTEND_ASSETS_API_ENDPOINT ||
    ((await broker.cacher.get("FRONTEND_ASSETS_API_ENDPOINT")) as string);
  const wsAssetsApiEndpointUrl =
    process.env.REACT_APP_WS_FRONTEND_ASSETS_API_ENDPOINT ||
    ((await broker.cacher.get("FRONTEND_ASSETS_API_ENDPOINT", true)) as string);
  
  const hasuraURL = new URL(hasuraEndpointUrl);

  const headerAuth = { authorization: `Bearer ${token}` };

  return {
    // Same as manifest serviceName
    serviceName: "portal",
    structureTreeMaxLevels: 5,
    appVersion: packageJson.version,
    authorityPath: "/sso/oauth2",
    apollo: {
      storageApi: {
        headerAuth,
        uri: hasuraEndpointUrl,
        relativePath: hasuraURL.pathname,
        wsUri: wsHasuraEndpointUrl,
        configurationReady: !!token,
      },
    },
    assetsApi: {
      uri: assetsApiEndpointUrl,
      wsUri: wsAssetsApiEndpointUrl,
    },
    identity: {
      uri: `${identityServerUrl}/graphql`,
      authority: `${identityServerUrl}/oauth2`,
    },
    modelIds: {
      robotController: `asset.robot.rc.dfa.raro`,
      robotVirtualController: `asset.robot.vc.dfa.raro`,
      opcua: `asset.opcua.dfa.raro`,
      mqtt: `asset.mqtt.dfa.raro`,
      mongodb: `asset.mongodb.dfa.raro`,
      timescale: `asset.timescale.dfa.raro`,
    },
    appLauncherModelId: "smartot.redbox.applications",
    admin: {
      groupName: "super",
    },
  };
};

type ThenArg<T> = T extends PromiseLike<infer U> ? U : T;

export type ConfigType = ThenArg<ReturnType<typeof getConfig>>;

export { PERMISSIONS };
