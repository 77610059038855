import { Button, Row } from "@abb/common-ux";
import { forwardRef } from "react";
import classNames from "classnames";

import styles from "./TreeViewActions.module.scss";

export const TreeViewActions = forwardRef<
  HTMLDivElement,
  {
    showEdit: boolean;
    onMenuClick: (e: MouseEvent) => void;
    onEditClick: (e: MouseEvent) => void;
    actionWrapperClassName?: string;
  }
>(({ showEdit, onMenuClick, onEditClick, actionWrapperClassName }, ref) => {
  return (
    <Row className={styles.actions}>
      <>
        <div
          className={classNames(styles.actionWrapper, actionWrapperClassName)}
          ref={ref}
        >
          <Button
            size="small"
            type="discreet-black"
            icon="menu-narrow"
            onPress={(e) => onMenuClick((e as unknown) as MouseEvent)}
          />
        </div>
        {showEdit && (
          <Button
            size="small"
            type="discreet-black"
            icon="edit"
            onPress={(e) => onEditClick((e as unknown) as MouseEvent)}
          />
        )}
      </>
    </Row>
  );
});
