// React
import React, { useEffect, useState } from "react";

// I18NEXT
import { useTranslation } from "react-i18next";

// COMPONENTS
import Select, { components, OptionProps } from "react-select";
import { Icon, ThreeStateValue, Checkbox } from "@abb/abb-common-ux-react";

// STYLING
import styles from "./SelectOptions.module.scss";
import cx from "classnames";

// Styling for the select component parts
const selectStyles: any = {
  control: (provided: any) => ({ ...provided, backgroundColor: "#f4f7fc" }),
  menu: () => ({
    marginTop: "8px",
  }),
  option: (styles: any) => {
    return {
      ...styles,
      padding: "0px",
      backgroundColor: undefined,
    };
  },
};

const SearchIcon = () => {
  return (
    <div className={styles.searchContainer}>
      <Icon name="abb/search"></Icon>
    </div>
  );
};

const IndeterminateCheckboxComponentOption = (
  props: OptionProps<any, true, any>
) => {
  const onChangeHandler = () => {
    const currentValues = props.getValue();
    const currentIndex = currentValues.findIndex(
      (v) => v.value === props.data.value
    );
    if (currentIndex > -1) {
      if (currentValues[currentIndex].state === ThreeStateValue.Checked) {
        props.selectOption(props.data);
      } else {
        currentValues[currentIndex].state = ThreeStateValue.Checked;
        props.setValue(currentValues, "select-option", {});
      }
    } else {
      const valueSelected = props.options.find(
        (v) => v.value === props.data.value
      );
      valueSelected.state = ThreeStateValue.Checked;
      props.setValue([...currentValues, valueSelected], "select-option", {});
    }
  };
  let value = ThreeStateValue.Unchecked;
  const currentValues = props.getValue();
  const currentIndex = currentValues.findIndex(
    (v) => v.value === props.data.value
  );
  if (currentIndex > -1) {
    value = currentValues[currentIndex].state || ThreeStateValue.Checked;
  }
  return (
    <components.Option
      {...props}
      innerProps={{ ...props.innerProps, onClick: () => {} }}
    >
      <div className={styles.optionContainer}>
        <Checkbox
          value={value}
          allowIndeterminate
          sizeClass="small"
          onChange={onChangeHandler}
        ></Checkbox>
        <span title={props.label} onClick={onChangeHandler}>
          {props.label}
        </span>
      </div>
    </components.Option>
  );
};

export interface SelectValueProps {
  label: string;
  value: string;
}

interface Props {
  values: any[];
  value: SelectValueProps[];
  containerStyles?: string;
  onValueChange: (v: any) => void;
  submitForm?: () => void;
}

const SelectOptions = ({
  values,
  value,
  containerStyles,
  onValueChange,
  submitForm = () => {},
}: Props) => {
  const [options, setOptions] = useState<any[]>(values);
  const handleChange = (selectedValue: any) => {
    onValueChange(selectedValue);
  };
  useEffect(() => {
    setOptions(values);
  }, [values, setOptions]);
  const { t } = useTranslation();

  const onEnterKeyHandler = (event: React.KeyboardEvent<HTMLElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
      submitForm();
    }
  };

  return (
    <div className={cx(styles.select, containerStyles)}>
      <Select
        autoFocus
        backspaceRemovesValue={false}
        components={{
          Option: IndeterminateCheckboxComponentOption,
          DropdownIndicator: SearchIcon,
          IndicatorSeparator: null,
        }}
        noOptionsMessage={() => t("noOptions")}
        controlShouldRenderValue={false}
        hideSelectedOptions={false}
        isClearable={false}
        menuIsOpen
        placeholder={t("searchPlaceholder")}
        tabSelectsValue={false}
        value={value}
        onChange={handleChange}
        options={options}
        isMulti={true}
        styles={selectStyles}
        onKeyDown={onEnterKeyHandler}
      />
    </div>
  );
};

export default SelectOptions;
