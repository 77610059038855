import { useCallback } from "react";

export const useDownload = () => {
  const downloadText = useCallback((csv: string, name?: string) => {
    const blob = new Blob(["\uFEFF" + csv], {
      type: "text/csv; charset=utf-18",
    });
    const a = document.createElement("a");
    a.href = URL.createObjectURL(blob);
    a.download = name || `${new Date().toISOString()}`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }, []);

  return { downloadText };
};
