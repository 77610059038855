// CONFIG
import { ConfigType, getConfig } from "config";

// REACT
import React, { useState, useCallback, useContext } from "react";

interface IConfigProvider {
  children: React.ReactNode;
}

interface IConfigProviderType {
  config: ConfigType;
  updateToken: (token: string) => void;
}

const configInitialValue = {
  serviceName: "",
  apollo: {
    storageApi: {
      headerAuth: { authorization: "" },
      relativePath: "",
      uri: "",
      wsUri: "",
      configurationReady: false,
    },
  },
  assetsApi: {
    uri: "",
    wsUri: "",
  },
  authorityPath: "",
  appLauncherModelId: "",
  appVersion: "",
  identity: { uri: "", authority: "" },
  modelIds: {
    robotController: "",
    robotVirtualController: "",
    opcua: "",
    mqtt: "",
    mongodb: "",
    timescale: "",
  },
  structureTreeMaxLevels: 0,
  admin: {
    groupName: "",
  },
};

export const ConfigContext = React.createContext<IConfigProviderType>({
  config: configInitialValue,
  updateToken: () => {},
});

export const ConfigProvider: React.FC<IConfigProvider> = ({ children }) => {
  const [config, setConfig] = useState<ConfigType>(configInitialValue);

  const updateToken = useCallback((token: string) => {
    getConfig(token).then((configuration) => setConfig(configuration));
  }, []);
  if (process.env.NODE_ENV === "development") {
    console.log(config);
  }
  return (
    <ConfigContext.Provider
      value={{
        config,
        updateToken,
      }}
    >
      {children}
    </ConfigContext.Provider>
  );
};

export const useConfig = () => useContext(ConfigContext);
