// UTILS
import capitalize from "utils/capitalize";

// REACT
import { useContext } from "react";

// APOLLO
import { deleteUsers } from "gql/identity/mutations/userMutations";
import { useMutation, useQuery } from "@apollo/client";
import { ApolloContexts } from "services/ApolloService";
import { getAllUsers } from "gql/identity/queries/userQueries";

// I18NEXT
import { useTranslation } from "react-i18next";

// COMPONENTS
import { UsersGridContext } from "layouts/main/MainLayout";
import { User } from "types/identity/User";

// STYLING
import RemovePanel from "components/Panel/RemovePanel";
import { useAuth } from "@abb/identity-auth-react";
import { useGlobalNotification } from "services/GlobalNotification/GlobalNotificationService";

interface Props {
  users: string[];
}

const RemoveUser = ({ users }: Props) => {
  const { user } = useAuth();
  const { publishWarning } = useGlobalNotification();
  const { setSelectedUsers } = useContext(UsersGridContext);
  const { context } = ApolloContexts.Identity;
  const [deleteUsersMutation] = useMutation(deleteUsers, {
    refetchQueries: ["getAllUsers", "getUserByIdentityId", "getUser"],
    context,
  });

  // I18NEXT
  const { t } = useTranslation();

  const { data: usersData } = useQuery(getAllUsers, {
    fetchPolicy: "cache-and-network",
    context
  });
  const filteredUsers: User[] =
    (usersData &&
      usersData.usersByNameAndEmail.filter((u: User) =>
        users.includes(u.id)
      )) ||
    [];
  const removeHandler = async () => {
    let userIds = [...users];
    let userAuth = filteredUsers.find((u) => u.email === user?.profile.email);
    if (userAuth) {
      userIds = userIds.filter((u) => u !== userAuth?.id);
      if (userIds.length > 0) {
        publishWarning(
          capitalize(
            t(
              "app:screen.user.panel.deleteUser.notifications.error.removeYourself"
            )
          )
        );
      }
    }
    if (userIds.length > 0) {
      await deleteUsersMutation({
        variables: { input: { userIds } },
      });
    }
    return userIds.length;
  };
  return (
    <RemovePanel
      elementIds={users}
      resourcePath="app:screen.user.panel.deleteUser"
      elementName={`${filteredUsers[0]?.firstName} ${filteredUsers[0]?.lastName}`}
      removeHandler={removeHandler}
      setSelectedElements={(values) => setSelectedUsers(values)}
    ></RemovePanel>
  );
};

export default RemoveUser;
