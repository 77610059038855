import React, { FC } from "react";
import styles from "./SummaryItem.module.scss";

export interface SummaryItemProps {
  label: string;
  value: string | string[] | JSX.Element;
}

export const SummaryItem: FC<SummaryItemProps> = ({ value, label }): any => {
  return (
    <div className={styles.summaryItem}>
      <div className={styles.label}>{label}</div>

      {/* Non Array Value */}
      {!Array.isArray(value) && (
        <span data-cy="non-array-value" className={styles.value}>
          {value}
        </span>
      )}

      {/* Array Value */}
      {Array.isArray(value) && (
        <ul className={styles.arrayValue} data-cy="array-value">
          {value?.map((value, ix) => (
            <li key={value + ix}>{value}</li>
          ))}
        </ul>
      )}
    </div>
  );
};
