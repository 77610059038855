import { useCallback } from "react";
import { GlobalNotification } from "./GlobalNotification";
import { GlobalNotificationType } from "./GlobalNotificationType";
let eventHandlers: ((notification: GlobalNotification) => void)[] = [];

export class GlobalNotificationService {
  static publishNotification(notification: GlobalNotification) {
    eventHandlers.forEach((eventHandler) => eventHandler(notification));
  }
  static onNotificationPublished(
    fn: (notification: GlobalNotification) => void
  ) {
    eventHandlers.push(fn);
  }
  static offNotificationPublished(
    fn: (notification: GlobalNotification) => void
  ) {
    eventHandlers = eventHandlers.filter((eventHandler) => eventHandler !== fn);
  }
}
export const NotifyError = (error: Error) => {
  GlobalNotificationService.publishNotification({
    type: GlobalNotificationType.Alarm,
    text: error.message,
  });
};
export const useGlobalNotification = () => {
  const publishSuccess = useCallback((message: string) => {
    GlobalNotificationService.publishNotification({
      type: GlobalNotificationType.Success,
      text: message,
    });
  }, []);
  const publishInfo = useCallback((message: string) => {
    GlobalNotificationService.publishNotification({
      type: GlobalNotificationType.Info,
      text: message,
    });
  }, []);
  const publishAlert = useCallback((message: string) => {
    GlobalNotificationService.publishNotification({
      type: GlobalNotificationType.Alarm,
      text: message,
    });
  }, []);
  const publishWarning = useCallback((message: string) => {
    GlobalNotificationService.publishNotification({
      type: GlobalNotificationType.Warn,
      text: message,
    });
  }, []);
  return { publishAlert, publishInfo, publishSuccess, publishWarning };
};
