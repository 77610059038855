// React
import React from "react";
// i18n
import { useTranslation } from "react-i18next";
// Custom
import styles from "../Wizard.module.scss";
import { StepProps } from "../DeviceWizard";
import ListInput from "components/ListInput/ListInput";
const MqttParameters = ({ formik }: StepProps) => {
  const { t } = useTranslation();

  return (
    <div className={styles.largeStepContainer}>
      <div className={styles.listInputWrapper}>
        <ListInput
          maxLength={256}
          label={t("app:screen.structure.wizard.form.fields.sourceTopics")}
          placeholder=""
          name="sourceTopics"
          formik={formik}
          helperText={t(
            "app:screen.structure.wizard.form.helperText.sourceTopics"
          )}
        />
      </div>
      <div className={styles.listInputWrapper}>
        <ListInput
          maxLength={256}
          label={t("app:screen.structure.wizard.form.fields.destinationTopics")}
          placeholder=""
          name="destinationTopics"
          formik={formik}
        />
      </div>
    </div>
  );
};

export default MqttParameters;
