// COMPONENTS
import { Icon } from "@abb/abb-common-ux-react";

// STYLING
import styles from "./ClickableLink.module.scss";
import cx from "classnames";

interface Props {
  clickAction: () => void;
  text: string;
  icon?: string;
  color?: "primary-red" | "default";
}

const ClickableLink = ({ clickAction, icon, text, color }: Props) => {
  return (
    <div
      onClick={clickAction}
      className={cx({
        [styles.container]: true,
        [styles.primaryRed]: color === "primary-red",
      })}
      data-cy="ClickableLink"
    >
      {icon && <Icon name={icon}></Icon>}
      <span>{text}</span>
    </div>
  );
};

export default ClickableLink;
