// I18NEXT
import { useTranslation } from "react-i18next";

// REACT
import { useEffect, useState } from "react";

// FORMIK
import { FormikProps } from "formik";

// COMPONENTS
import { Input } from "libs/@abbrda/abb-common-ux-react/src/components/Input/Input";

// STYLES
import styles from "./File.module.scss";

// LIBS
import cx from "classnames";

export interface Props {
  name: string;
  label?: string;
  maxLength?: number;
  placeholder?: string;
  dataType?:
    | "number"
    | "textarea"
    | "time"
    | "text"
    | "color"
    | "date"
    | "email"
    | "month"
    | "password"
    | "search"
    | "tel"
    | "url"
    | "week";
  required?: boolean;
  disabled?: boolean;
  autoFocus?: boolean;
  formik: FormikProps<any>;
  helperText?: string;
}

const TextInput = ({
  name,
  label,
  placeholder,
  dataType = "text",
  required,
  maxLength,
  autoFocus,
  disabled,
  formik,
  helperText,
}: Props) => {
  const [icon, setIcon] = useState<string>("");
  const [type, setDatatype] = useState(dataType);
  const [showHelperText, setShowHelperText] = useState<boolean>(false);
  const { t } = useTranslation();

  useEffect(() => {
    const iconName =
      dataType === "search"
        ? "abb/search"
        : dataType === "password"
        ? "abb/view"
        : "";
    setIcon(iconName);
  }, [dataType]);

  const onToggleSecureText = () => {
    if (dataType === "password") {
      const iconName = icon === "abb/view" ? "abb/hide" : "abb/view";
      const typeName = icon === "abb/view" ? "text" : "password";
      setIcon(iconName);
      setDatatype(typeName);
    }
  };

  const onFocus = () => {
    formik.setFieldTouched(name);
    setShowHelperText((sht) => !sht);
  };

  return (
    <>
      <Input
        disabled={disabled}
        id={name}
        inputAttributes={{ name, autoFocus, "data-cy": `input-${name}` }}
        value={formik.values[name]}
        validationResult={
          !!formik.errors[name] && !!formik.touched[name]
            ? formik.errors[name]?.toString()
            : undefined
        }
        showValidationIconWhenInvalid={!!formik.errors[name]}
        showValidationBarWhenInvalid={!!formik.errors[name]}
        onValueChange={(value) => {
          formik.handleChange({ target: { name, value } });
          setTimeout(() => formik.setFieldTouched(name), 500);
        }}
        onGotFocus={() => {
          onFocus();
        }}
        onLostFocus={() => setShowHelperText((sht) => !sht)}
        maxLength={maxLength}
        icon={icon}
        dataType={type}
        label={label || t(name)}
        placeholder={placeholder || placeholder === "" ? placeholder : t(name)}
        required={required}
        onIconClick={onToggleSecureText}
      />
      {helperText && (
        <div
          className={cx(styles.helperText, {
            [styles.displayNone]: !showHelperText,
          })}
        >
          {helperText}
        </div>
      )}
    </>
  );
};
export default TextInput;
