import { QueryStructure } from "types/storageApi/QueryStructure";
import { editByIdInHierarchy } from "utils/editByIdInHierachy";
import { findByIdInHierarchy } from "utils/findByIdInHierarchy";
import { IStructureTreeItem } from "./StructureTreeItem";

interface MappedStructureTreeItem extends IStructureTreeItem, QueryStructure {
  parents: IStructureTreeItem[];
}

const sortByName = (a: IStructureTreeItem, b: IStructureTreeItem) => {
  if (a.name < b.name) {
    return -1;
  }
  if (a.name > b.name) {
    return 1;
  }
  return 0;
};

const mapParents = (
  structure: MappedStructureTreeItem,
  structureToMap: MappedStructureTreeItem,
  structures: MappedStructureTreeItem[],
  level: number
): MappedStructureTreeItem => {
  const prevStructureParents = structure.parents || [];
  const prevStructureToMapParents = structureToMap.parents || [];

  const prevParents = [...prevStructureParents, ...prevStructureToMapParents];

  const newStructure: MappedStructureTreeItem = {
    ...structureToMap,
    parents: prevParents,
  };

  const parentStructure = structures.find(
    (parent: MappedStructureTreeItem) =>
      parent.structureid === structureToMap.parentid
  );

  const parentStructureNotInPreviousMap = prevParents.find(
    (prevStructure) => prevStructure.id === structureToMap.parentid
  );

  if (parentStructure && !parentStructureNotInPreviousMap) {
    newStructure.parents = [
      ...newStructure.parents,
      {
        id: parentStructure.structureid,
        name: parentStructure.properties.name,
        type: parentStructure.properties.type,
        level: parentStructure.level,
        parents: newStructure.parents,
        children: structures
          .filter(
            (structureToFilter: QueryStructure) =>
              structureToFilter.parentid === structure.structureid
          )
          .map((item: MappedStructureTreeItem) =>
            mapStructure(item, structures, level)
          )
          .sort(sortByName),
      },
    ];
  }

  return newStructure;
};

const mapStructure = (
  structure: MappedStructureTreeItem,
  structures: MappedStructureTreeItem[],
  level: number = 0
): IStructureTreeItem => ({
  name: structure.properties?.name || structure.name,
  id: structure.structureid || structure.id,
  level,
  type: structure.properties?.type || structure.type,
  parents: structure.parents || [],
  children: structures
    .filter(
      (structureToFilter) =>
        structureToFilter.parentid === structure.structureid
    )
    .map((structureToMap) => {
      return mapStructure(
        mapParents(
          structure,
          structureToMap,
          structures,
          level ? level + 1 : 1
        ),
        structures,
        level ? level + 1 : 1
      );
    })
    .sort(sortByName),
});

export const buildTree = (
  structures: MappedStructureTreeItem[]
): IStructureTreeItem[] => {
  const rootStructures = structures?.filter(
    (structure) => structure.parentid === null
  );
  return rootStructures
    ?.map((rootStructure) => mapStructure(rootStructure, structures))
    ?.sort(sortByName);
};

/**
 * Finds the given item from the tree structure by the given item id.
 */
export const findItem = (
  items: IStructureTreeItem[],
  itemId: string
): IStructureTreeItem => {
  return findByIdInHierarchy<IStructureTreeItem>({
    items,
    itemId,
    idGetter: (item) => item.id,
    childrenGetter: (item) => item.children,
  });
};

/**
 * Updates the given item from the tree structure by the given item id.
 */
export const updateItem = (
  items: IStructureTreeItem[],
  itemId: string,
  changes: Omit<Partial<IStructureTreeItem>, "id">
): IStructureTreeItem[] => {
  return editByIdInHierarchy<IStructureTreeItem>({
    items,
    itemId,
    changes,
    idGetter: (item) => item.id,
    childrenGetter: (item) => item.children,
  });
};
