// UTILS
import capitalize from "utils/capitalize";

// I18NEXT
import i18n from "libs/i18n";

// APOLLO
import {
  ApolloClient,
  InMemoryCache,
  HttpLink,
  split,
  ApolloLink,
} from "@apollo/client";
import { WebSocketLink } from "@apollo/client/link/ws";
import { setContext } from "@apollo/client/link/context";
import { getMainDefinition } from "@apollo/client/utilities";
import { onError } from "@apollo/client/link/error";

// COMPONENTS
import { NotifyError } from "./GlobalNotification/GlobalNotificationService";

const identityErrorDictionary = (message: string) => {
  switch (message) {
    case 'update or delete on table "sot_org_unit" violates foreign key constraint "sot_user_org_unit_id_fkey" on table "sot_user"':
      return i18n.t("app:errors.deleteSectorWithUsers");
    default:
      return message;
  }
};

export enum ApolloEndpoints {
  Hasura = "hasura",
  AssetsApi = "assets-api",
  Identity = "identity",
}

export const ApolloContexts = {
  Hasura: { context: { endpoint: ApolloEndpoints.Hasura } },
  AssetsApi: { context: { endpoint: ApolloEndpoints.AssetsApi } },
  Identity: { context: { endpoint: ApolloEndpoints.Identity } },
};

// Set the authorization header for each request
const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      get authorization() {
        return `Bearer ${ApolloService._authToken}`;
      },
    },
  };
});

/**
 * ApolloService is a service class that provides methods for initializing and accessing the Apollo Client.
 */
export class ApolloService {
  static _authToken: string | undefined;
  static client: ApolloClient<any>;

  /**
   * Initializes the Apollo Client with the provided configuration.
   * @param hasuraUri The URI for the Hasura API.
   * @param hasuraWsUri The WebSocket URI for the Hasura API.
   * @param identityUri The URI for the Identity Server.
   * @param assetsApiUri The URI for the Assets API.
   * @param assetsApiWsUri The WebSocket URI for the Assets API.
   * @param token The authentication token.
   */
  static async init(
    hasuraUri: string,
    hasuraWsUri: string,
    identityUri: string,
    assetsApiUri: string,
    assetsApiWsUri: string,
    token: string
  ) {
    this._authToken = token;
    const identityLink = this.createIdentityLink(identityUri);
    const hasuraLink = this.createHasuraLink(hasuraUri, hasuraWsUri);
    const assetsApiLink = this.createAssetsApiLink(
      assetsApiUri,
      assetsApiWsUri
    );

    const link = this.createLink(identityLink, hasuraLink, assetsApiLink);

    this.client = new ApolloClient({
      link,
      cache: new InMemoryCache({ addTypename: false }),
    });
  }

  /**
   * Splits the link based on the operation type (subscription or not).
   * @param httpLink The HTTP link for the operation.
   * @param wsLink The WebSocket link for the operation.
   * @returns The split link.
   */
  private static definitionLink(httpLink: HttpLink, wsLink: WebSocketLink) {
    return split(
      ({ query }) => {
        const definition = getMainDefinition(query);
        return (
          definition.kind === "OperationDefinition" &&
          definition.operation === "subscription"
        );
      },
      wsLink,
      httpLink
    );
  }

  /**
   * Creates the ApolloLink for the Identity Server.
   * @param identityUri The URI for the Identity Server.
   * @returns The ApolloLink for the Identity Server.
   */
  private static createIdentityLink(identityUri: string) {
    // Create HTTP link for the Identity Server
    const identityHttpLink = new HttpLink({
      uri: identityUri,
    });

    // Handle errors from the Identity Server
    const identityErrorLink = onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        graphQLErrors.forEach(({ message, locations, path }) => {
          if (message.indexOf("23505") >= 0 || message.indexOf("25P02") >= 0) {
            return false;
          }
          NotifyError(new Error(identityErrorDictionary(message)));
          console.log(
            `[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(
              locations
            )}, Path: ${path}`
          );
        });
      }
      if (networkError) {
        console.log(networkError);
      }
    });
    return ApolloLink.from([
      identityErrorLink,
      authLink.concat(identityHttpLink),
    ]);
  }

  /**
   * Creates the ApolloLink for the Hasura API.
   * @param hasuraUri The URI for the Hasura API.
   * @param hasuraWsUri The WebSocket URI for the Hasura API.
   * @returns The ApolloLink for the Hasura API.
   */
  private static createHasuraLink(hasuraUri: string, hasuraWsUri: string) {
    // Create HTTP link for the Hasura API
    const hasuraHttpLink = new HttpLink({
      uri: hasuraUri,
    });

    // Create WebSocket link for the Hasura API
    const hasuraWsLink = new WebSocketLink({
      uri: hasuraWsUri,
      options: {
        reconnect: true,
        connectionParams: {
          headers: {
            get authorization() {
              return `Bearer ${ApolloService._authToken}`;
            },
          },
        },
      },
    });

    // Handle errors from the Hasura API
    const hasuraErrorLink = onError(
      ({ graphQLErrors, networkError, response }) => {
        if (graphQLErrors) {
          graphQLErrors.forEach(({ message, locations, path, extensions }) => {
            console.log(
              `[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(
                locations
              )}, Path: ${path}, Code: ${extensions?.code}`
            );
          });
          // If triggered when an unauthorized operation is done into the storage api due to lack of permissions
          if (
            graphQLErrors.some(
              (e) => e.extensions?.code === "permission-error"
            ) &&
            response
          ) {
            NotifyError(
              new Error(
                capitalize(i18n.t("app:apollo.storageApi.errors.noPermission"))
              )
            );
            response.errors = undefined;
          }
        }

        if (networkError) {
          NotifyError(networkError);
        }
      }
    );

    // Create the link for the Hasura API with error handling and authorization
    const hasuraLink = ApolloLink.from([
      hasuraErrorLink,
      authLink.concat(this.definitionLink(hasuraHttpLink, hasuraWsLink)),
    ]);
    return hasuraLink;
  }

  /**
   * Creates the ApolloLink for the Assets API.
   * @param assetsApiUri The URI for the Assets API.
   * @param assetsApiWsUri The WebSocket URI for the Assets API.
   * @returns The ApolloLink for the Assets API.
   */
  private static createAssetsApiLink(
    assetsApiUri: string,
    assetsApiWsUri: string
  ) {
    // Create HTTP link for the Assets API
    const assetsApiHttpLink = new HttpLink({
      uri: assetsApiUri,
    });

    // Create WebSocket link for the Assets API
    const assetsApiWsLink = new WebSocketLink({
      uri: assetsApiWsUri,
      options: {
        reconnect: true,
        connectionParams: {
          headers: {
            get authorization() {
              return `Bearer ${ApolloService._authToken}`;
            },
          },
        },
      },
    });

    // Handle errors from the Assets API
    const assetsApiErrorLink = onError(
      ({ graphQLErrors, networkError, response }) => {
        if (graphQLErrors) {
          graphQLErrors.forEach(({ message, locations, path, extensions }) => {
            console.warn(
              `[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(
                locations
              )}, Path: ${path}, Code: ${extensions?.code}`
            );
            NotifyError(new Error(message));
          });
        }

        if (networkError) {
          NotifyError(networkError);
        }
      }
    );

    // Create the link for the Assets API with error handling and authorization
    const assetsApiLink = ApolloLink.from([
      assetsApiErrorLink,
      authLink.concat(this.definitionLink(assetsApiHttpLink, assetsApiWsLink)),
    ]);
    return assetsApiLink;
  }

  /**
   * Creates the ApolloLink based on the provided links.
   * @param identityLink The ApolloLink for the Identity Server.
   * @param hasuraLink The ApolloLink for the Hasura API.
   * @param assetsApiLink The ApolloLink for the Assets API.
   * @returns The created ApolloLink.
   */
  private static createLink(
    identityLink: ApolloLink,
    hasuraLink: ApolloLink,
    assetsApiLink: ApolloLink
  ) {
    // Split the link based on the context endpoint (Hasura or Assets API)
    const assetsLink = split(
      ({ getContext }) => getContext().endpoint === ApolloEndpoints.Hasura,
      hasuraLink,
      assetsApiLink
    );

    // Split the link based on the context endpoint (Hasura, Assets API, or Identity Server)
    const link = split(
      ({ getContext }) => {
        const { endpoint } = getContext();
        return [ApolloEndpoints.Hasura, ApolloEndpoints.AssetsApi].includes(
          endpoint
        );
      },
      assetsLink,
      identityLink
    );
    return link;
  }

  /**
   * Returns the Apollo Client instance.
   * @returns The Apollo Client instance.
   */
  static getClient() {
    return this.client;
  }

  /**
   * Updates the authentication token.
   * @param token The new authentication token.
   */
  static updateToken(token: string) {
    this._authToken = token;
  }
}
