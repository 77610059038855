import * as React from 'react';

export interface HtmlAttributes {
  /** HTML standard attribute, applied to component root element.  */
  id?: string;

  /** HTML standard attribute. Additional class name(s) applied to component root element.  */
  className?: string;

  /** HTML standard attribute. Custom styles applied to component root element.  */
  style?: React.CSSProperties;

  /** HTML data-attribute used as target in testing  */
  ['data-test']?: string;
};


export function getHtmlAttributes(props: any) {
  // Note: classes are not included in this, since they are practically always included manually.
  return {
    id: props.id,
    style: props.style,
    // eslint-disable-next-line 
    ['data-test']: props['data-test']
  };
}