// REACT
import { useMemo } from "react";

// UTILS
import capitalize from "utils/capitalize";

// I18NEXT
import { useTranslation } from "react-i18next";

// CONTEXT
import { useConfig } from "components/Config/ConfigProvider";

// INTERFACES
import { AppLauncherApp } from "types/appLauncher/app";

// LIBS
import { getAllApps } from "@abb/app-selector-client";
import { ViewLayout, ViewLayoutContent } from "@abb/common-ux";
import { useQuery } from "@tanstack/react-query";

// COMPONENTS
import App from "./App";
import Skeleton from "components/Skeletons/Skeleton";
import { Footer } from "components/Footer/Footer";
import AppSkeleton from "components/Skeletons/commonux/App";
import GeneralMessage from "./GeneralMessage";

// STYLING
import styles from "./Launcher.module.scss";

const Launcher = () => {
  const { config } = useConfig();

  const { t } = useTranslation();

  const { data, isLoading, isError } = useQuery({
    queryKey: ["getAllAppsKey"],
    queryFn: () =>
      getAllApps(
        config.apollo.storageApi.uri,
        config.apollo.storageApi.headerAuth
      ),
    retry: 3,
    retryDelay: 1000,
    refetchOnWindowFocus: false,
    staleTime: Infinity, // The data should be permanent so stale should not apply on this case.
  });

  const products = useMemo(
    () => data?.filter((p) => p.serviceName !== config.serviceName),
    [config.serviceName, data]
  );

  const renderContent = () => {
    if (!isLoading) {
      if (isError) {
        return (
          <GeneralMessage
            message={capitalize(t("app:screen.general.errorApps.title"))}
            subtitle={capitalize(t("app:screen.general.errorApps.subtitle"))}
          />
        );
      }
      if (!products?.length) {
        return (
          <GeneralMessage
            message={capitalize(t("app:screen.general.noAppsAvailable.title"))}
            subtitle={capitalize(
              t("app:screen.general.noAppsAvailable.subtitle")
            )}
          />
        );
      }
      return (
        <div className={styles.applications}>
          {products?.map((app: AppLauncherApp) => {
            return <App app={app} key={app.serviceName}></App>;
          })}
        </div>
      );
    } else {
      return (
        <div className={styles.applications}>
          <Skeleton>
            <AppSkeleton />
          </Skeleton>
        </div>
      );
    }
  };

  return (
    <ViewLayout
      navigationTitle={capitalize(t("app:screen.launcher.header"))}
      footerTemplate={<Footer />}
    >
      <ViewLayoutContent title={capitalize(t("app:screen.launcher.subheader"))}>
        {renderContent()}
      </ViewLayoutContent>
    </ViewLayout>
  );
};

export default Launcher;
