// COMPONENTS
import { AppLauncherApp } from "types/appLauncher/app";

// STYLING
import styles from "./App.module.scss";

interface Props {
  app: AppLauncherApp;
}
const App = ({ app }: Props) => {
  return (
    <a href={app.path} className={styles.container}>
      <div className={styles.icon}>
        <img
          src={app.iconBase64}
          className={styles.imageIcon}
          alt={app.name}
        ></img>
      </div>
      <div>
        <div className={styles.title}>{app.name}</div>
        <div className={styles.description}>{app.description}</div>
      </div>
    </a>
  );
};

export default App;
