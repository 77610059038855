// UTILS
import capitalize from "utils/capitalize";

// REACT
import React from "react";

// REACT-I18NEXT
import { useTranslation } from "react-i18next";

// STYLING
import styles from "./Unauthorized.module.scss";

const Unauthorized: React.FC = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.container} data-cy="Unauthorized">
      <h1>{capitalize(t("app:screen.general.noAccessAllowed.title"))}</h1>
      <h2>{capitalize(t("app:screen.general.noAccessAllowed.subtitle"))}</h2>
    </div>
  );
};

export default Unauthorized;
