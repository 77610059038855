import { LoadingIndicator } from "@abb/abb-common-ux-react";
import { LoadingBoxProps } from "./LoadingBoxProps";
import "./LoadingBox.scss";
import classnames from "classnames";

export const LoadingBox = ({
  isFullScreen,
  sizeClass,
  style,
}: LoadingBoxProps) => {
  const className = classnames("loading-box", {
    "is-full-screen": isFullScreen,
    "is-small": sizeClass === "small",
    "is-medium": sizeClass === "small",
    "is-large": sizeClass === "small",
  });
  return (
    <div className={className} style={style}>
      <LoadingIndicator
        type="radial"
        determinate={false}
        sizeClass={isFullScreen ? "large" : sizeClass || "medium"}
        color="blue"
      />
    </div>
  );
};
