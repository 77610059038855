import { useTranslation } from "react-i18next";

const usePaginationTranslation = () => {
  const { t } = useTranslation();

  const paginationTranslation = {
    showing: t("showing"),
    rowsPerPage: t("rowsPerPage"),
    goToPage: t("goToPage"),
    page: t("page"),
  };

  return paginationTranslation;
};

export default usePaginationTranslation;
