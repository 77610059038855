import { gql } from "@apollo/client";

export const getKeys = gql`
  query getKeys {
    getKeys {
      publicKey
    }
  }
`;

export interface GetKeysQueryResponse {
  getKeys: {
    publicKey: string;
  };
}
