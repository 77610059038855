import { IStructureTreeItem } from "components/StructureTree/StructureTreeItem";
import React, { Dispatch, SetStateAction, useContext, useState } from "react";

export interface StructureGridContextType {
  selectedStructure?: IStructureTreeItem;
  setSelectedStructure: Dispatch<
    SetStateAction<IStructureTreeItem | undefined>
  >;
  // Array of ids of the selected structure and all its children structures
  // to use for filtering purposes in the Assets datagrid
  selectedStructureChildrenIds?: string[];
  setSelectedStructureChildrenIds: Dispatch<SetStateAction<string[]>>;
  getAssetsCSVData?: undefined | (() => object[]);
  setGetAssetsCSVData: Dispatch<SetStateAction<() => object[]>>;
}

export const useStructureContext = () => useContext(StructureGridContext);

export const StructureGridContext = React.createContext<StructureGridContextType>(
  {
    selectedStructure: undefined,
    setSelectedStructure: () => {
      console.log("modal not loaded");
    },
    selectedStructureChildrenIds: [],
    setSelectedStructureChildrenIds: () => {
      console.log("modal not loaded");
    },
    getAssetsCSVData: () => [{ error: "CSV data function not set" }],
    setGetAssetsCSVData: () => console.log("CSV set function not set"),
  }
);

export const StructureProvider: React.FunctionComponent = ({ children }) => {
  const [
    selectedStructure,
    setSelectedStructure,
  ] = useState<IStructureTreeItem>();

  const [
    selectedStructureChildrenIds,
    setSelectedStructureChildrenIds,
  ] = useState<string[]>([]);

  const [getAssetsCSVData, setGetAssetsCSVData] = useState<() => any>(
    () => "CSV export not yet initialized"
  );

  return (
    <StructureGridContext.Provider
      value={{
        selectedStructure,
        setSelectedStructure,
        selectedStructureChildrenIds,
        setSelectedStructureChildrenIds,
        getAssetsCSVData,
        setGetAssetsCSVData,
      }}
    >
      {children}
    </StructureGridContext.Provider>
  );
};
