// REACT
import React from "react";

// STYLING
import styles from "./GeneralMessage.module.scss";

interface Props {
  message: string;
  subtitle?: string;
}

const GeneralMessage: React.FC<Props> = ({ message, subtitle }) => {
  return (
    <div className={styles.container} data-cy="GeneralMessage">
      <h1>{message}</h1>
      {subtitle && <h2>{subtitle}</h2>}
    </div>
  );
};

export default GeneralMessage;
