import { gql } from "@apollo/client";

export const GroupFields = `
	fragment Group on Group {
    id
    name
    description
    roles {
      name
      description
    }
    identities {
      id
      type
    }
    owner {
      id
      type
    }
	}
`;

// USER : FRAGMENTS
export const GroupFragment = gql`
  ${GroupFields}
`;

export const addGroup = gql`
  mutation addGroup($input: AddGroupInput!) {
    addGroup(input: $input) {
      id
      name
      description
    }
  }
`;

export const updateGroup = gql`
  mutation updateGroup($input: UpdateGroupInput!) {
    updateGroup(input: $input) {
      id
      name
      description
    }
  }
`;

export const addUserToGroup = gql`
  mutation addUserToGroup($input: UserToGroupInput!) {
    addUserToGroup(input: $input)
  }
`;

export const removeUserFromGroup = gql`
  mutation removeUserFromGroup($input: UserToGroupInput!) {
    removeUserFromGroup(input: $input)
  }
`;

export const removeGroup = gql`
  mutation removeGroup($id: String!) {
    removeGroup(input: { id: $id })
  }
`;

export const addIdentitiesToGroup = gql`
  mutation addIdentitiesToGroup($input: IdentitiesToGroupInput!) {
    addIdentitiesToGroup(input: $input) {
      success
    }
  }
`;

export const removeIdentitiesFromGroup = gql`
  mutation removeIdentitiesFromGroup($input: IdentitiesToGroupInput!) {
    removeIdentitiesFromGroup(input: $input) {
      success
    }
  }
`;
