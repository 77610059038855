import { gql } from "@apollo/client";

export const getAllGroups = gql`
  query getAllGroups {
    groupsByName(nameFilter: "") {
      id
      name
      label
      description
      identities {
        id
        type
      }
    }
  }
`;

export const getGroup = gql`
  query getGroup($id: String!) {
    group(id: $id) {
      id
      name
      label
      description
      roles {
        name
        label
        description
      }
      identities {
        id
        type
      }
      owner {
        id
        type
      }
    }
  }
`;

export const getAllGroupData = gql`
  query getAllGroupData {
    groupsByName(nameFilter: "") {
      id
      name
      label
      description
      roles {
        name
        label
        description
      }
      identities {
        id
        type
      }
      owner {
        id
        type
      }
    }
  }
`;
