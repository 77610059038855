
import { gql } from "@apollo/client";

export const subscribeAssetStatus = gql`
    subscription subscribeAssetStatus($assetid: String) {
        master_asset_status(limit: 1, order_by: {timestamp: desc}, where: {assetid: {_eq: $assetid}}) {
            master_code_list_item_object {
                value
            }
        }
    }
`