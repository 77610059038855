import i18n from "libs/i18n";
import * as yup from "yup";

// Validate IP address
yup.addMethod<yup.StringSchema>(
  yup.string,
  "ip",
  function (message = i18n.t("yup:errors.validIp")) {
    return this.test("ip", message, function (value: any) {
      const rx = /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
      return rx.test(value);
    });
  }
);

// Validate Password
yup.addMethod<yup.StringSchema>(
  yup.string,
  "password",
  function (message = i18n.t("yup:errors.validPassword")) {
    return this.test("password", message, function (value: any) {
      const rx = /^(?=.{8,}$)(?=.*[A-Z])(?=.*[!"$%^@]).*$/;
      return rx.test(value);
    });
  }
);

// Validate Port Number
yup.addMethod<yup.StringSchema>(
  yup.string,
  "port",
  function (message = i18n.t("yup:errors.validPort")) {
    return this.test("port", message, function (value: any) {
      const rx = /^([0-9]{1,4}|[1-5][0-9]{4}|6[0-4][0-9]{3}|65[0-4][0-9]{2}|655[0-2][0-9]|6553[0-5])$/;
      return rx.test(value);
    });
  }
);

// Validate Hostname Number
yup.addMethod<yup.StringSchema>(
  yup.string,
  "hostname",
  function (message = i18n.t("yup:errors.validHostname")) {
    return this.test("hostname", message, function (value: any) {
      // eslint-disable-next-line
      const rx = /^(([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9\-]*[a-zA-Z0-9])\.)*([A-Za-z0-9]|[A-Za-z0-9][A-Za-z0-9\-]*[A-Za-z0-9])$/;
      return rx.test(value);
    });
  }
);

// Validate URL
yup.addMethod<yup.StringSchema>(
  yup.string,
  "url",
  function (message = i18n.t("yup:errors.validURL")) {
    return this.test("url", message, function (value: any) {
      // eslint-disable-next-line
      const rx = /(\w.+):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
      return rx.test(value);
    });
  }
);

// Alphanumeric String
yup.addMethod<yup.StringSchema>(
  yup.string,
  "alphanumeric",
  function (message = i18n.t("yup:errors.validAlphanumeric")) {
    return this.test("alphanumeric", message, function (value: any) {
      const rx = /^[a-zA-Z0-9\s]*$/;
      return rx.test(value);
    });
  }
);

// Alphanumeric, hypens and underscores String
yup.addMethod<yup.StringSchema>(
  yup.string,
  "alphanumericHypensUnderscores",
  function (message = i18n.t("yup:errors.validAlphanumericHyphensUnderscore")) {
    return this.test("alphanumeric", message, function (value: any) {
      // eslint-disable-next-line
      const rx = /^[a-zA-Z0-9\-\_\s]*$/;
      return rx.test(value);
    });
  }
);

export default yup;
