// UTILS
import capitalize from "utils/capitalize";
import { findItem } from "components/StructureTree/utils";

// REACT
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

// NAVIGATION
import { useHistory } from "react-router-dom";

// I18NEXT
import { useTranslation } from "react-i18next";

// APOLLO
import { useMutation } from "@apollo/client";
import { deleteAsset } from "gql/storageApi/mutations/assetMutations";

// CUSTOM HOOKS
import { useLoggedUserPermission } from "libs/hooks/useLoggedUserPermission";

// COMPONENTS

import { GlobalNotificationService } from "services/GlobalNotification/GlobalNotificationService";
import { GlobalNotificationType } from "services/GlobalNotification/GlobalNotificationType";
import { DashboardContainer } from "components/DashboardContainer/DashboardContainer";

import { AssetsDataGrid, AssetsDataGridRef } from "./AssetsDataGrid";
import { IStructureTreeItem } from "components/StructureTree/StructureTreeItem";
import { Filter, StructureFilters } from "./panels/Filters";
import { AssetDetail } from "./panels/AssetDetail";
import PopupButton from "components/PopupButton/PopupButton";
import { StructureSidebar } from "./sidebar/Sidebar";
import MoveAssets from "./MoveAssets";
import RemoveAsset from "./panels/RemoveAsset";
import { ImportStructureFromCSV } from "components/ImportFromCSV/Structure";
import ExportStructureToCSV from "./panels/ExportToCSV";

// STYLING
import cx from "classnames";
import styles from "./Structure.module.scss";
import { BreadcrumbLevel, Button, Filters, ViewLayout } from "@abb/common-ux";
import { Footer } from "components/Footer/Footer";
import { usePanel } from "components/Panel/PanelContext";
import { StructureProvider, useStructureContext } from "./StructureContext";
import DeviceWizard from "./wizards/DeviceWizard";
import { Route, Switch } from "react-router-dom";
import { ApolloContexts } from "services/ApolloService";
import { throttle } from "lodash";
import { DELETE_ASSET_BUTTON_THROTTLE_MS } from "config/constants";
import { getStructureChildrenIds } from "utils/assetStucture";
import { Modal } from "components/Modal/Modal";
import { useModal } from "components/Modal/ModalContext";

interface AssetsGridContextType {
  selectedAssets: string[];
  setSelectedAssets: (assets: string[]) => void;
}

interface BreadcrumbContextType {
  breadcrumb: BreadcrumbLevel[];
  setBreadcrumb: (breadcrumb: BreadcrumbLevel[]) => void;
}

export const AssetsGridContext = React.createContext<AssetsGridContextType>({
  selectedAssets: [],
  setSelectedAssets: () => {},
});

export const BreadcrumbContext = React.createContext<BreadcrumbContextType>({
  breadcrumb: [],
  setBreadcrumb: () => {},
});

const getFilterStatus = (filters?: Filter) => {
  let count = 0;
  let key: keyof Filter;
  if (filters) {
    for (key in filters) {
      if (
        (typeof filters![key] === "string" && filters![key] !== "") ||
        (typeof filters![key] === "object" && filters![key]!.length)
      ) {
        count++;
      }
    }
    return count > 0;
  }
  return false;
};

export const StructureView = () => {
  // STATE
  const [selectedAssets, setSelectedAssets] = useState<string[]>([]);
  const [submitting, setSubmitting] = useState(false);
  const [search, setSearch] = useState("");
  const [filters, setFilters] = useState<Filter | undefined>({
    name: "",
    id: "",
    type: [],
    tags: [],
  });
  const [breadcrumb, setBreadcrumb] = useState<BreadcrumbLevel[]>([]);

  // I18NEXT
  const { t } = useTranslation();

  // REFS
  const assetsDataGridRef = useRef<AssetsDataGridRef>();

  // CONTEXT
  const { setPanel, closePanel } = usePanel();
  const { setModal, closeModal } = useModal();
  const {
    selectedStructure,
    setSelectedStructure,
    setSelectedStructureChildrenIds,
  } = useStructureContext();

  // CUSTOM HOOKS
  const { hasEditStructurePermission } = useLoggedUserPermission();

  // REACT-ROUTER
  const history = useHistory();
  function handleClick() {
    history.push(`/structure/add`);
  }

  // MUTATIONS
  const [deleteAssetMutation] = useMutation(deleteAsset, {
    context: ApolloContexts.AssetsApi.context,
  });

  // METHODS
  const reloadDatagrid = useCallback(() => {
    assetsDataGridRef?.current?.refetch &&
      assetsDataGridRef?.current?.refetch();
  }, [assetsDataGridRef]);

  const onClickOnTreeItem = useCallback(
    (item: IStructureTreeItem) => {
      setSelectedAssets([]);
      setSelectedStructure(item);
      setSelectedStructureChildrenIds(getStructureChildrenIds([item]));
    },
    [setSelectedStructure, setSelectedStructureChildrenIds]
  );

  // When selected structure changes, update the breadcrumb
  useEffect(() => {
    if (!selectedStructure) {
      return;
    }

    setBreadcrumb([
      ...selectedStructure.parents.map((item) => ({
        title: item.name,
        onClick: () => onClickOnTreeItem(item),
      })),
      {
        title: selectedStructure.name,
        onClick: () => onClickOnTreeItem(selectedStructure),
      },
    ]);
  }, [selectedStructure, onClickOnTreeItem]);

  const onClickOnDeleteAsset = (assetId: string) => {
    // Throttle function is created as useMemo instead of useCallback because React wants
    // the function to be inline so it knows its dependencies.
    const onClickOnConfirmDeleteAsset = throttle(
      async (assetId: string) => {
        setSubmitting(true);
        await deleteAssetMutation({ variables: { assetid: assetId } })
          .then(() => {
            GlobalNotificationService.publishNotification({
              text: t(`assetSuccessfullyDeleted`),
              type: GlobalNotificationType.Success,
            });
            closePanel();
            setSelectedAssets((prevSelectedAssets) =>
              prevSelectedAssets.filter((id) => id !== assetId)
            );
            reloadDatagrid();
          })
          .finally(() => setSubmitting(false));
      },
      DELETE_ASSET_BUTTON_THROTTLE_MS,
      { leading: true, trailing: false }
    );

    setModal(
      <Modal
        isOpen
        title={t(`confirmAction`)}
        body={
          <div>
            <p>{t(`areYouSureWantToDeleteAsset`)}</p>
          </div>
        }
        closeModal={closeModal}
        footer={{
          footer: {
            leftButton: {
              text: t("cancel"),
              type: "discreet-blue",
              size: "small",
              onPress: closeModal,
            },
            rightButton: {
              text: t("confirm"),
              type: "primary-blue",
              size: "small",
              onPress: () => {
                closeModal();
                onClickOnConfirmDeleteAsset(assetId);
              },
            },
          },
        }}
      />
    );
  };

  const onClickOnEditAsset = (assetId: string) => {
    history.push(`/structure/edit/${assetId}`);
  };

  // When tree changes, update the selected structure
  const onTreeChange = useCallback(
    (tree: IStructureTreeItem[]) => {
      const newSelectedItem = findItem(tree, selectedStructure?.id || "");

      if (newSelectedItem.name !== selectedStructure?.name) {
        // The useEffect will update the breadcrumb accordingly
        setSelectedStructure(newSelectedItem);
        setSelectedStructureChildrenIds(
          getStructureChildrenIds([newSelectedItem])
        );
      }
    },
    [
      selectedStructure?.id,
      selectedStructure?.name,
      setSelectedStructure,
      setSelectedStructureChildrenIds,
    ]
  );

  return (
    <BreadcrumbContext.Provider value={{ breadcrumb, setBreadcrumb }}>
      <AssetsGridContext.Provider
        value={{
          selectedAssets,
          setSelectedAssets: (assets: string[]) => setSelectedAssets(assets),
        }}
      >
        <DashboardContainer
          sidebar={
            <StructureSidebar
              onClickOnTreeItem={onClickOnTreeItem}
              onChange={onTreeChange}
            />
          }
        >
          <ViewLayout
            navigationTitle={t("factoryStructure")}
            footerTemplate={<Footer />}
            breadcrumbsLevels={breadcrumb}
            viewLayoutRight={
              selectedAssets && selectedAssets.length > 0 ? (
                <div
                  className={cx(
                    styles.assetActions,
                    hasEditStructurePermission ? "" : "hidden"
                  )}
                >
                  <h4 className={styles.assetActionsCounter}>
                    {selectedAssets.length} {t("selected")}
                  </h4>
                  <PopupButton
                    text={capitalize(
                      t("app:screen.structure.header.actions.moveBulk.title")
                    )}
                    component={<MoveAssets assets={selectedAssets} />}
                  />
                  <Button
                    icon="trash"
                    type="primary-red"
                    onPress={() =>
                      setPanel(
                        <RemoveAsset
                          assets={selectedAssets}
                          onAfterDelete={() => reloadDatagrid()}
                        />
                      )
                    }
                  />
                </div>
              ) : (
                <div className={styles.headerButtons}>
                  <>
                    <Button
                      onPress={() =>
                        setPanel(
                          <ImportStructureFromCSV
                            structureId={selectedStructure?.id}
                            onlyAllowImportAssets={
                              !!(
                                selectedStructure?.parents &&
                                selectedStructure.parents.length
                              )
                            }
                          />
                        )
                      }
                      type="discreet-blue"
                      size="large"
                      text={t("dashboardImportCSV")}
                      icon="upload"
                      iconSize={16}
                    />
                    <Button
                      onPress={() =>
                        setPanel(
                          <ExportStructureToCSV
                            structureId={selectedStructure?.id}
                            importWithOutPanel={
                              !!(
                                selectedStructure?.parents &&
                                selectedStructure.parents.length
                              )
                            }
                          />
                        )
                      }
                      type="discreet-blue"
                      size="large"
                      text={t("dashboardExportCSV")}
                      icon="download"
                      iconSize={16}
                    />
                    {hasEditStructurePermission && (
                      <Button
                        type="primary-blue"
                        onPress={handleClick}
                        text={t("addAsset")}
                      ></Button>
                    )}
                  </>
                </div>
              )
            }
          >
            <Filters
              filtersInUse={getFilterStatus(filters)}
              onSearch={setSearch}
              title={breadcrumb[breadcrumb.length - 1]?.title || ""}
              openPanel={() =>
                setPanel(
                  <StructureFilters filters={filters} setFilter={setFilters} />
                )
              }
              placeholder={t("search")}
            >
              <AssetsDataGrid
                ref={assetsDataGridRef}
                filters={{
                  modelid: filters?.type,
                  assetname: filters?.name,
                  assetid: filters?.id,
                  domainid: filters?.tags?.map((tag) => parseFloat(tag)),
                }}
                search={search}
                onClickOnAssetDetails={(asset) =>
                  setPanel(
                    <AssetDetail
                      onClickOnEditAsset={() =>
                        onClickOnEditAsset(asset.assetid)
                      }
                      onClickOnDeleteAsset={() =>
                        onClickOnDeleteAsset(asset.assetid)
                      }
                      submitting={submitting}
                      assetId={asset.assetid}
                    ></AssetDetail>
                  )
                }
              />
            </Filters>
          </ViewLayout>
        </DashboardContainer>
      </AssetsGridContext.Provider>
    </BreadcrumbContext.Provider>
  );
};

export const Structure = () => {
  return (
    <StructureProvider>
      <Switch>
        <Route path={["/structure/add", "/structure/edit/:assetId"]}>
          <DeviceWizard />
        </Route>
        <Route path="/structure">
          <StructureView />
        </Route>
      </Switch>
    </StructureProvider>
  );
};
