import { createContext, FC, useCallback, useContext, useState } from "react";

export interface ModalContextValue {
  setModal: (template: JSX.Element) => void;
  closeModal: () => void;
  modalContent?: JSX.Element;
  isOpen: boolean;
}

const defaultValue = {
  setModal: () => undefined,
  isOpen: false,
  closeModal: () => undefined,
};

const ModalContext = createContext<ModalContextValue>(defaultValue);

export const useModal = () => {
  return useContext(ModalContext);
};

export const ModalProvider: FC = ({ children }) => {
  const [modalContent, setModalContent] = useState<JSX.Element | undefined>();
  const [isOpen, setIsOpen] = useState<boolean>(defaultValue.isOpen);

  const setModal = useCallback((content: JSX.Element) => {
    setModalContent(content);
    setIsOpen(true);
  }, []);

  const closeModal = useCallback(() => {
    setIsOpen(false);
    setModalContent(() => <></>);
  }, []);

  return (
    <ModalContext.Provider
      value={{
        modalContent,
        isOpen,
        setModal,
        closeModal,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};

export const ModalContent = () => {
  const { modalContent } = useModal();

  return <>{modalContent}</>;
};
