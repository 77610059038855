import { useTranslation } from "react-i18next";
import { useQuery } from "@apollo/client";

import * as Yup from "yup";
import { useFormik } from "formik";
import useKeyPress from "libs/@abbrda/abb-common-ux-react/internalUtils/useKeyPress";

import TextInput from "components/FormikCommonUX/Text";
import FormDropdown from "components/FormikCommonUX/Dropdown";
import { QueryResultRenderer } from "components/QueryResultRenderer/QueryResultRenderer";

import { getModels } from "gql/storageApi/queries/modelQueries";
import { getDomains } from "gql/storageApi/queries/domainQueries";
import { QueryModel } from "types/storageApi/QueryModel";
import { QueryDomain } from "types/storageApi/QueryDomain";
import styles from "./Filters.module.scss";
import { Button, SlidePanel } from "@abb/common-ux";
import { usePanel } from "components/Panel/PanelContext";
import { ApolloContexts } from "services/ApolloService";

const FilterSchema = Yup.object().shape({
  name: Yup.string().max(50, "Too Long!"),
  id: Yup.string().max(50, "Too Long!"),
  type: Yup.array(),
  tags: Yup.array(),
});
export interface Filter {
  name: string;
  id: string;
  type: string[];
  tags: string[];
}
export const StructureFilters = ({
  setFilter,
  filters,
}: {
  setFilter: (filter?: Filter) => void;
  filters?: Filter;
}) => {
  const { t } = useTranslation();
  const { context } = ApolloContexts.Hasura;
  const modelsQueryResult = useQuery(getModels, {
    variables: {
      where: {
        _and: [
          { modelid: { _similar: "asset.%.dfa.raro%" } },
          { modelid: { _neq: "asset.azureiothub.dfa.raro" } },
        ],
      },
    },
    fetchPolicy: "network-only",
    context,
  });
  const domainsQueryResult = useQuery(getDomains, {
    fetchPolicy: "network-only",
    context,
  });
  const { isOpen, closePanel } = usePanel();
  const formik = useFormik({
    initialValues: filters || {
      name: "",
      id: "",
      type: [],
      tags: [],
    },
    validationSchema: FilterSchema,
    onSubmit: (values: Filter) => {
      // same shape as initial values
      setFilter(values);
      closePanel();
    },
  });

  const clearFilters = () => {
    formik.setValues({
      name: "",
      id: "",
      type: [],
      tags: [],
    });
  };

  useKeyPress("Enter", () => {
    formik.submitForm();
  });

  return (
    <SlidePanel
      title={t("filters")}
      isOpen={isOpen}
      closePanel={closePanel}
      headerActions={
        <Button
          onPress={clearFilters}
          type="discreet-blue"
          text={t("clearAll")}
          size="small"
        />
      }
      bottomActions={
        <Button
          disabled={!formik.dirty}
          type="primary-blue"
          onPress={formik.submitForm}
          text={t("apply")}
        />
      }
    >
      <QueryResultRenderer
        queryResults={[
          { queryResult: modelsQueryResult, dataKey: "master_model" },
          { queryResult: domainsQueryResult, dataKey: "master_code_list_item" },
        ]}
      >
        {([models, tags]: [QueryModel[], QueryDomain[]]) => (
          <form className={styles.form} onSubmit={formik.handleSubmit}>
            <TextInput name="name" formik={formik} />
            <TextInput name="id" formik={formik} />
            <FormDropdown
              name="type"
              formik={formik}
              multiselect
              selectedValues={!formik.touched.type ? formik.values.type : []}
              values={models.map((model) => ({
                label: t(`app:assets.${model.modelid.replace(/\./g, "-")}`),
                value: model.modelid,
              }))}
            />
            <FormDropdown
              name="tags"
              formik={formik}
              multiselect
              selectedValues={!formik.touched.tags ? formik.values.tags : []}
              values={tags.map((tag) => ({
                label: tag.value.name || "-",
                value: tag.id.toString() || "-",
              }))}
            />
          </form>
        )}
      </QueryResultRenderer>
    </SlidePanel>
  );
};
