// TYPES
import { IStructureTreeItem } from "components/StructureTree/StructureTreeItem";
const getLevelsDown = (item: IStructureTreeItem): number => {
  if (item.children && item.children.length) {
    return (
      1 +
      Math.max(
        ...item.children.map((child: IStructureTreeItem) =>
          getLevelsDown(child)
        )
      )
    );
  }
  return 0;
};
export const canMoveStructure = (
  from: IStructureTreeItem,
  to: IStructureTreeItem,
  structureTreeMaxLevels: number
) => {
  if (
    to.type !== "subgroup" ||
    (from.children && includesOrChildrenInclude(from.children, to)) ||
    from === to ||
    (to.children && to.children.includes(from)) ||
    to.level + getLevelsDown(from) >= structureTreeMaxLevels
  )
    return false;
  return true;
};

const includesOrChildrenInclude = (itemList: any[], item: any): boolean => {
  return (
    itemList.includes(item) ||
    itemList.reduce(
      (acc: boolean, it: IStructureTreeItem) =>
        acc || (it.children && includesOrChildrenInclude(it.children, item)),
      false
    )
  );
};
