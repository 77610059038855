
import { Dropdown, DropdownOption } from "@abb/abb-common-ux-react";
import { Icon } from "@abb/common-ux";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./RetentionPolicyDialog.module.scss";

interface Props {
  formerPolicy?: string;
  selectedPolicy: string;
  setSelectedPolicy: (policy: string) => void;
}

const RetentionPolicyDialog = ({setSelectedPolicy,selectedPolicy, formerPolicy}: Props) => {
  const { t } = useTranslation();
  const [message, setMessage] = useState<string>("");
  const policies = ["7 days", "14 days", "28 days"];
  const policyLabels= [t("app:screen.structure.dialog.7days"), t("app:screen.structure.dialog.14days"), t("app:screen.structure.dialog.28days")];

  useEffect(() => {
    if(formerPolicy){
      setSelectedPolicy(formerPolicy);
    }
  }, [formerPolicy, setSelectedPolicy]);

  const handlePolicyChange = (policySelection: {value: string, label: string}[]) => {
    const policy = policySelection[0].value;
    setSelectedPolicy(policy);
    if(policies.indexOf(policy) < policies.indexOf(formerPolicy||"14 days")){
      setMessage(t("app:screen.structure.dialog.lowerPolicyChangeWarning"));
      return
    }
    if(policies.indexOf(policy) > policies.indexOf(formerPolicy||"14 days")){
      setMessage(t("app:screen.structure.dialog.higherPolicyChangeWarning"));
      return
    }
    setMessage("");
  }

  return (
    <>
        <Dropdown
          label={t("retentionPolicy")}
          value={[{value: selectedPolicy, label: policyLabels[policies.indexOf(selectedPolicy||"14 days")]}]}
          onChange={handlePolicyChange}
        >
          {policies.map((policy, index) => (
            <DropdownOption key={policy} value={policy} label={policyLabels[index]} />
          ))}
        </Dropdown>
        {message && <><div className={styles.message}><Icon style={{color: "inherit", marginTop:2}} size={16} name={"warning-circle-2"}  /> {message}</div></>}
    </>
  );
};

export default RetentionPolicyDialog;
