import { gql } from "@apollo/client";

export const getModels = gql`
    query getModels($where: master_model_bool_exp, $limit: Int, $offset: Int, $orderBy: [master_model_order_by!]) {
        master_model(limit: $limit, offset: $offset, order_by: $orderBy, where: $where) {
            modelid
            description
        }
    }
`;

export const getModelWithProperties = gql`
    query getModel($id: String!) {
        master_model(where: {modelid: {_eq: $id}}) {
            modelid
            master_properties_array {
                propertyid
                propertydescription
                propertytype
            }
        }
    }
`
