import { gql } from "@apollo/client";

export const getAllRoles = gql`
  query getAllRoles {
    rolesByName(nameFilter: "") {
      id
      name
      description
    }
  }
`;

export const getAllRolesData = gql`
  query getAllRoles {
    rolesByName(nameFilter: "") {
      id
      name
      label
      description
      owner {
        id
        type
      }
      permissions {
        id
        name
      }
      storagePermissions {
        id
        name
      }
      groups {
        id
        name
        label
      }
    }
  }
`;
