import { IdentityTypeEnum } from "../types/identity/Identity";
import { Group } from "../types/identity/Group";
import { filterInternalGroups } from "./filterInternal";

/**
 * Filters the user groups that can be selected by following criteria:
 * 1. Groups created by local users
 * 2. Groups created by external AAD users
 * 3. Groups created by the current service
 *
 * @name filterSelectableGroups
 * @param {Group[]} groups - The groups to be filtered
 * @param {string?} service - The current service
 * @return {Group[]} - The filtered groups
 */
export function filterSelectableGroups(groups: Group[], service?: string) {
  return filterInternalGroups(groups)!.filter(
    (g: Group) =>
      g.owner?.type === IdentityTypeEnum.LOCAL ||
      g.owner?.type === IdentityTypeEnum.EXTERNAL ||
      (service &&
        g.owner?.type === IdentityTypeEnum.SERVICE &&
        g.owner?.id === service)
  );
}
