// UTILS
import capitalize from "utils/capitalize";

// REACT
import { useEffect, useState } from "react";

// I18NEXT
import { useTranslation } from "react-i18next";

// APOLLO
import { useMutation } from "@apollo/client";
import { updateUser } from "gql/identity/mutations/userMutations";
import { ApolloContexts } from "services/ApolloService";

// FORMIK
import { useFormik } from "formik";
import yup from "libs/validation/yup";

// COMPONENTS
import TextInput from "components/FormikCommonUX/Text";
import { GlobalNotificationService } from "services/GlobalNotification/GlobalNotificationService";
import { GlobalNotificationType } from "services/GlobalNotification/GlobalNotificationType";

// STYLING
import styles from "./ResetPassword.module.scss";
import { usePanel } from "components/Panel/PanelContext";
import { Button, SlidePanel } from "@abb/common-ux";
import { passStrengthRegExp } from "./hooks/useUserHook";

interface IChangePasswordForm {
  newPassword: string;
  repeatPassword: string;
}

interface IResetPasswordProps {
  userId: string;
}

const ResetPassword = ({ userId }: IResetPasswordProps) => {
  // STATE
  const [submitting, setSubmitting] = useState(false);

  // CONTEXT
  const { closePanel, isOpen } = usePanel();

  // I18NEXT
  const { t } = useTranslation();


  // MUTATIONS
  const [updateUserMutation] = useMutation(updateUser, {
    context: ApolloContexts.Identity.context,
  });

  // SUBMIT
  const onSubmit = async () => {
    setSubmitting(true);
    try {
      await updateUserMutation({
        variables: {
          input: {
            userId: userId,
            password: values.newPassword,
          },
        },
      });
      GlobalNotificationService.publishNotification({
        text: capitalize(
          t(`app:screen.user.panel.resetPassword.notifications.success`)
        ),
        type: GlobalNotificationType.Success,
      });
      closePanel();
    } catch (e: any) {
      GlobalNotificationService.publishNotification({
        text:
          e.message ||
          capitalize(
            t(`app:screen.user.panel.resetPassword.notifications.error`)
          ),
        type: GlobalNotificationType.Alarm,
      });
      setSubmitting(false);
    }
  };

  // FORM : FORMIK
  const formik = useFormik<IChangePasswordForm>({
    initialValues: {
      newPassword: "",
      repeatPassword: "",
    },
    validationSchema: yup.object().shape(
      {
        newPassword: yup
          .string()
          .required(capitalize(t("mandatoryField")))
          .max(50, t("yup:errors.tooLong"))
          .matches(passStrengthRegExp, t("yup:errors.passwordStrength"))
          .min(8, t("yup:errors.min8chars")),
        repeatPassword: yup
          .string()
          .required(capitalize(t("mandatoryField")))
          .max(50, t("yup:errors.tooLong"))
          .when("newPassword", {
            is: (newPassword: any) => !!newPassword,
            then: (schema) =>
              schema.oneOf(
                [yup.ref("newPassword")],
                capitalize(t("yup:errors.passwordMustMatch"))
              ),
            otherwise: (schema) => schema,
          }),
      },
      [["repeatPassword", "newPassword"]]
    ),
    onSubmit,
  });

  const { values, isValid, validateForm } = formik;

  // EFFECTS
  useEffect(() => {
    validateForm();
  }, [validateForm]);

  return (
    <SlidePanel
      title={capitalize(t("app:screen.user.panel.resetPassword.title"))}
      isOpen={isOpen}
      closePanel={closePanel}
      bottomActions={
        <>
          <Button
            type="discreet-black"
            text={capitalize(t("cancel"))}
            onPress={closePanel}
          />
          <Button
            type="primary-blue"
            text={capitalize(
              t("app:screen.user.panel.resetPassword.buttons.reset")
            )}
            disabled={!isValid || submitting}
            onPress={onSubmit}
          />
        </>
      }
    >
      <div className={styles.form}>
        <TextInput
          name="newPassword"
          label={capitalize(
            t("app:screen.user.panel.resetPassword.fields.newPassword")
          )}
          dataType="password"
          formik={formik}
          placeholder={""}
          required
        />
        <TextInput
          name="repeatPassword"
          label={capitalize(
            t("app:screen.user.panel.resetPassword.fields.repeatPassword")
          )}
          dataType="password"
          formik={formik}
          placeholder={""}
          required
        />
      </div>
    </SlidePanel>
  );
};

export default ResetPassword;
