// REACT
import React, { useRef, useState } from "react";

// COMPONENTS
import { Icon } from "@abb/abb-common-ux-react";

// STYLES
import styles from "./PopupButton.module.scss";

import Tippy from "@tippyjs/react";

interface Props {
  text: string;
  component: React.ReactElement;
}
const PopupButton = ({ text, component }: Props) => {
  // STATE
  const [openPopupComponent, setOpenPopupComponent] = useState(false);

  // REFS
  const wrapperRef = useRef(null);

  return (
    <>
      <Tippy
        content={openPopupComponent?
            React.cloneElement(component, {
              close: () => setOpenPopupComponent(false),
            }):<></>
        }
        delay={100}
        interactive={true}
        appendTo={() => document.body}
        reference={wrapperRef}
        placement="bottom-start"
        onHide={()=>setOpenPopupComponent(false)}
        visible={openPopupComponent}
      >
        <div ref={wrapperRef} className={styles.container}>
          <div
            className={styles.popupButton}
            onClick={() => {
              setOpenPopupComponent(!openPopupComponent);
            }}
          >
            <div className={styles.buttonText}>
              <span>{text}</span>
            </div>
            <div className={styles.buttonIcon}>
              <Icon name={"abb/caret-down"}></Icon>
            </div>
          </div>
        </div>
      </Tippy>
    </>
  );
};

export default PopupButton;
