// UTILS
import capitalize from "utils/capitalize";

// I18NEXT
import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";

// FORMIK
import { FormikProps } from "formik";

// COMPONENTS
import { Dropdown, DropdownOption } from "@abb/abb-common-ux-react";
import TextInput from "components/FormikCommonUX/Text";
import { StepProps } from "../DeviceWizard";
import { AssetType } from "views/Structure/types/AssetType";
import { RobotControllerType } from "views/Structure/types/RobotControllerType";
import { DatabaseType } from "views/Structure/types/DatabaseType";
import { OpcuaSecurityPolicy } from "../../types/OpcuaSecurityPolicy";
import { OpcuaMessageSecurityMode } from "../../types/OpcuaMessageSecurityMode";
import { WizardFormModel } from "../WizardFormModel";
import { ProtocolType } from "views/Structure/types/ProtocolType";

// STYLING
import styles from "../Wizard.module.scss";
import { MAX_ENCRYPT_LENGTH } from "utils/encrypt";

const fieldsToClearAfterRobotTypeChange = [
  "robapiport",
  "rwversion",
  "systemid",
];

const fieldsToClearAfterDatabaseTypeChange = ["collection", "table"];

const handleValidate = (formik: any, value: any): string => {
  return formik.errors[value] && formik.touched[value]
    ? (formik.errors[value] as string)
    : "";
};

const handleDropdownChange = (formik: any, field: string, value: any) => {
  formik.setFieldValue(field, value);
  setTimeout(() => formik.setFieldTouched(field, true), 500);
};

const protocolToTranslationString = {
  [ProtocolType.OPC_TCP]:
    "app:screen.structure.wizard.form.fields.protocolOptions.OPCTCP",
};

const DeviceStep = ({ formik, isEdit }: StepProps) => {
  const { t } = useTranslation();

  const clearRobotVirtualControllerFields = () => {
    fieldsToClearAfterRobotTypeChange.forEach((field) => {
      formik.setFieldValue(field, "");
      formik.setFieldTouched(field, false);
    });
  };

  const clearDatabaseFields = () => {
    fieldsToClearAfterDatabaseTypeChange.forEach((field) => {
      formik.setFieldValue(field, "");
      formik.setFieldTouched(field, false);
    });
  };

  return (
    <div className={styles.stepContainer}>
      {formik.values?.type === "database" ? (
        <>
          <Dropdown
            onChange={(option) => {
              handleDropdownChange(formik, "databaseType", option[0].value);
              clearDatabaseFields();
              if (option[0].value === DatabaseType.PostgreSQL) {
                // formik.setFieldValue("persistinterval", "5000");  // field hidden due the US: 13076
              }
            }}
            sizeClass="large"
            label={t("databaseType")}
            placeholder={t("selectDatabaseType")}
            value={
              formik.values.databaseType
                ? [
                    {
                      value: formik.values?.databaseType,
                      label: t(formik.values?.databaseType as string),
                    },
                  ]
                : []
            }
            style={{ marginBottom: 16 }}
            required
            showValidationBarWhenInvalid
            showValidationIconWhenInvalid
            validationState={{
              valid: handleValidate(formik, "databaseType") === "",
              message: handleValidate(formik, "databaseType"),
            }}
          >
            <DropdownOption
              key={DatabaseType.MongoDB}
              label={t("mongoDB")}
              value={DatabaseType.MongoDB}
            />
            <DropdownOption
              key={DatabaseType.PostgreSQL}
              label={t("postgresql")}
              value={DatabaseType.PostgreSQL}
            />
          </Dropdown>
        </>
      ) : null}
      {formik.values?.type === AssetType.Robot &&
        renderRobotTypeSelector(t, formik, () =>
          clearRobotVirtualControllerFields()
        )}

      {renderProtocolHostUserPasswordFields(t, formik, Boolean(isEdit))}

      {formik.values?.type === AssetType.Robot &&
      formik.values.robotControllerType ===
        RobotControllerType.VirtualController
        ? renderRobotVirtualControllerFields(t, formik)
        : null}
      {formik.values?.type === AssetType.PLCOpcUA &&
        renderOpcUAFields(t, formik)}

      {/* {formik.values?.type === AssetType.MQTT && renderMQTTFields(t, formik)} */}
      {formik.values?.type === AssetType.Database &&
      formik.values.databaseType === DatabaseType.MongoDB
        ? renderMongoDBFields(t, formik)
        : null}
      {formik.values?.type === AssetType.Database &&
      formik.values.databaseType === DatabaseType.PostgreSQL
        ? renderPosgreSQLFields(t, formik)
        : null}
    </div>
  );
};

export default DeviceStep;

const renderProtocolHostUserPasswordFields = (
  t: TFunction,
  formik: FormikProps<WizardFormModel>,
  isEdit: boolean
) => {
  /**
   * Password field is a special case. It should not be filled when updating an asset.
   * It will only be filled if the user wants to change its value.
   * This leads us to showing a different placeholder if the asset is being updated.
   */
  const passwordPlaceholder = t(
    isEdit
      ? "app:screen.structure.wizard.device.passwordLeaveEmptyNotChanged"
      : "app:screen.structure.wizard.device.passwordExample"
  );

  return (
    <>
      <div style={{ display: "flex", alignContent: "stretch", gap: 16 }}>
        {formik.values?.type === AssetType.PLCOpcUA && (
          <div style={{ flex: 1 }}>
            <Dropdown
              onChange={(option) => {
                handleDropdownChange(formik, "protocol", option[0].value);
              }}
              sizeClass="large"
              label={capitalize(
                t("app:screen.structure.wizard.form.fields.protocol")
              )}
              placeholder={t(
                "app:screen.structure.wizard.form.fields.protocol"
              )}
              value={
                formik.values?.protocol
                  ? [
                      {
                        value: formik.values?.protocol,
                        label: t(
                          protocolToTranslationString[
                            formik.values?.protocol as ProtocolType
                          ]
                        ),
                      },
                    ]
                  : []
              }
              disabled
              showValidationBarWhenInvalid
              showValidationIconWhenInvalid
              validationState={{
                valid: handleValidate(formik, "protocol") === "",
                message: handleValidate(formik, "protocol"),
              }}
            >
              <DropdownOption
                key={ProtocolType.OPC_TCP}
                label={t(
                  "app:screen.structure.wizard.form.fields.protocolOptions.OPCTCP"
                )}
                value={ProtocolType.OPC_TCP}
              />
            </Dropdown>
          </div>
        )}
        <div
          style={{
            display: "flex",
            flex: formik.values?.type === AssetType.MQTT ? 1 : 4,
            gap: 16,
          }}
        >
          <div style={{ flex: 1 }}>
            <TextInput
              name="hostname"
              label={t("host")}
              placeholder={t("app:screen.structure.wizard.device.hostExample")}
              formik={formik}
              required
            />
          </div>

          {formik.values?.type != AssetType.Robot && (
            <div
              style={{
                flex: formik.values?.type != AssetType.PLCOpcUA ? 1 : 1.72,
              }}
            >
              <TextInput
                name="port"
                label={t("port")}
                placeholder={t(
                  "app:screen.structure.wizard.device.portExampleMQTT"
                )}
                formik={formik}
                required
              />
            </div>
          )}
        </div>
      </div>
      <div style={{ display: "flex", alignContent: "stretch", gap: 16 }}>
        <div style={{ flex: 1 }}>
          <TextInput
            name="user"
            label={capitalize(
              t("app:screen.structure.wizard.form.fields.username")
            )}
            placeholder={t(
              "app:screen.structure.wizard.device.usernameExample"
            )}
            formik={formik}
          />
        </div>
        <div style={{ flex: 1 }}>
          <TextInput
            name="password"
            label={t("password")}
            placeholder={passwordPlaceholder}
            maxLength={MAX_ENCRYPT_LENGTH}
            dataType="password"
            formik={formik}
          />
        </div>
      </div>
    </>
  );
};

const renderRobotTypeSelector = (
  t: TFunction,
  formik: FormikProps<WizardFormModel>,
  onChange: () => void
) => {
  return (
    <Dropdown
      onChange={(option) => {
        handleDropdownChange(formik, "robotControllerType", option[0].value);
        onChange();
      }}
      sizeClass="large"
      label={t("robotControllerType")}
      placeholder={t("selectRobotControllerType")}
      value={
        formik.values?.robotControllerType
          ? [
              {
                value: formik.values?.robotControllerType,
                label: t(
                  `app:screen.structure.wizard.form.fields.${formik.values?.robotControllerType}`
                ),
              },
            ]
          : []
      }
      style={{ marginBottom: 16 }}
      required
      showValidationBarWhenInvalid
      showValidationIconWhenInvalid
      validationState={{
        valid: handleValidate(formik, "robotControllerType") === "",
        message: handleValidate(formik, "robotControllerType"),
      }}
    >
      <DropdownOption
        key={RobotControllerType.RobotController}
        label={capitalize(
          t("app:screen.structure.wizard.form.fields.robotController")
        )}
        value={RobotControllerType.RobotController}
      />
      <DropdownOption
        key={RobotControllerType.VirtualController}
        label={capitalize(
          t("app:screen.structure.wizard.form.fields.virtualController")
        )}
        value={RobotControllerType.VirtualController}
      />
    </Dropdown>
  );
};

const renderRobotVirtualControllerFields = (
  t: TFunction,
  formik: FormikProps<WizardFormModel>
) => {
  return (
    <>
      <div style={{ display: "flex", alignContent: "stretch", gap: 16 }}>
        <div style={{ flex: 1 }}>
          <TextInput
            name="robapiport"
            label={t("robApiPort")}
            placeholder={t(
              "app:screen.structure.wizard.device.robApiPortExample"
            )}
            formik={formik}
            required
          />
        </div>

        <div style={{ flex: 1 }}>
          <TextInput
            name="rwversion"
            label={t("robotWareVersion")}
            placeholder={t(
              "app:screen.structure.wizard.device.robotWareVersionExample"
            )}
            formik={formik}
            required
          />
        </div>
      </div>
      <div style={{ flex: 1 }}>
        <TextInput
          name="systemid"
          label={t("systemId")}
          placeholder={t("app:screen.structure.wizard.device.systemIdExample")}
          formik={formik}
          required
        />
      </div>
    </>
  );
};

const renderOpcUAFields = (
  t: TFunction,
  formik: FormikProps<WizardFormModel>
) => {
  return (
    <>
      <div style={{ display: "flex", alignContent: "stretch", gap: 16 }}>
        <div style={{ flex: 1 }}>
          <Dropdown
            onChange={(option) =>
              handleDropdownChange(
                formik,
                "opcuaSecurityPolicy",
                option[0].value
              )
            }
            sizeClass="large"
            label={t(
              "app:screen.structure.wizard.form.fields.opcuaSecurityPolicy"
            )}
            value={
              formik.values?.opcuaSecurityPolicy
                ? [
                    {
                      value: formik.values?.opcuaSecurityPolicy,
                      label: t(
                        `app:screen.structure.wizard.form.fields.opcuaSecurityPolicyOptions.${formik.values?.opcuaSecurityPolicy}`
                      ),
                    },
                  ]
                : []
            }
          >
            <DropdownOption
              key={OpcuaSecurityPolicy.None}
              label={t(
                `app:screen.structure.wizard.form.fields.opcuaSecurityPolicyOptions.${OpcuaSecurityPolicy.None}`
              )}
              value={OpcuaSecurityPolicy.None}
            />
            <DropdownOption
              key={OpcuaSecurityPolicy.Basic128RSA15}
              label={t(
                `app:screen.structure.wizard.form.fields.opcuaSecurityPolicyOptions.${OpcuaSecurityPolicy.Basic128RSA15}`
              )}
              value={OpcuaSecurityPolicy.Basic128RSA15}
            />
            <DropdownOption
              key={OpcuaSecurityPolicy.Basic256}
              label={t(
                `app:screen.structure.wizard.form.fields.opcuaSecurityPolicyOptions.${OpcuaSecurityPolicy.Basic256}`
              )}
              value={OpcuaSecurityPolicy.Basic256}
            />
            <DropdownOption
              key={OpcuaSecurityPolicy.Basic256Sha256}
              label={t(
                `app:screen.structure.wizard.form.fields.opcuaSecurityPolicyOptions.${OpcuaSecurityPolicy.Basic256Sha256}`
              )}
              value={OpcuaSecurityPolicy.Basic256Sha256}
            />
          </Dropdown>
        </div>

        <div style={{ flex: 1 }}>
          <Dropdown
            onChange={(option) =>
              handleDropdownChange(
                formik,
                "opcuaMessageSecurityMode",
                option[0].value
              )
            }
            sizeClass="large"
            label={t(
              "app:screen.structure.wizard.form.fields.opcuaMessageSecurityMode"
            )}
            value={
              formik.values?.opcuaMessageSecurityMode
                ? [
                    {
                      value: formik.values?.opcuaMessageSecurityMode,
                      label: t(
                        `app:screen.structure.wizard.form.fields.opcuaMessageSecurityModeOptions.${formik.values?.opcuaMessageSecurityMode}`
                      ),
                    },
                  ]
                : []
            }
            style={{ marginBottom: 16 }}
          >
            <DropdownOption
              key={OpcuaMessageSecurityMode.None}
              label={t(
                `app:screen.structure.wizard.form.fields.opcuaMessageSecurityModeOptions.${OpcuaMessageSecurityMode.None}`
              )}
              value={OpcuaMessageSecurityMode.None}
            />
            <DropdownOption
              key={OpcuaMessageSecurityMode.Sign}
              label={t(
                `app:screen.structure.wizard.form.fields.opcuaMessageSecurityModeOptions.${OpcuaMessageSecurityMode.Sign}`
              )}
              value={OpcuaMessageSecurityMode.Sign}
            />
            <DropdownOption
              key={OpcuaMessageSecurityMode.SignAndEncrypt}
              label={t(
                `app:screen.structure.wizard.form.fields.opcuaMessageSecurityModeOptions.${OpcuaMessageSecurityMode.SignAndEncrypt}`
              )}
              value={OpcuaMessageSecurityMode.SignAndEncrypt}
            />
          </Dropdown>
        </div>
      </div>
      <div style={{ display: "flex", alignContent: "stretch", gap: 16 }}>
        <div style={{ flex: 1 }}>
          <TextInput
            name="pathname"
            label={capitalize(
              t("app:screen.structure.wizard.form.fields.pathname")
            )}
            placeholder={t(
              "app:screen.structure.wizard.device.pathnameExampleOpcUA"
            )}
            formik={formik}
          />
        </div>
      </div>
    </>
  );
};

const renderMongoDBFields = (
  t: TFunction,
  formik: FormikProps<WizardFormModel>
) => {
  return (
    <div style={{ display: "flex", alignContent: "stretch", gap: 16 }}>
      <div style={{ flex: 1 }}>
        <TextInput
          name="database"
          label={t("database")}
          placeholder={t("app:screen.structure.wizard.device.databaseExample")}
          formik={formik}
          required
        />
      </div>

      <div style={{ flex: 1 }}>
        <TextInput
          name="collection"
          label={t("collection")}
          placeholder={t(
            "app:screen.structure.wizard.device.collectionExample"
          )}
          formik={formik}
          required
        />
      </div>
    </div>
  );
};

const renderPosgreSQLFields = (
  t: TFunction,
  formik: FormikProps<WizardFormModel>
) => {
  return (
    <>
      <div style={{ display: "flex", alignContent: "stretch", gap: 16 }}>
        <div style={{ flex: 1 }}>
          <TextInput
            name="database"
            label={t("database")}
            placeholder={t(
              "app:screen.structure.wizard.device.databaseExample"
            )}
            formik={formik}
            required
          />
        </div>

        <div style={{ flex: 1 }}>
          <TextInput
            name="table"
            label={t("table")}
            placeholder={t("app:screen.structure.wizard.device.tableExample")}
            formik={formik}
            required
          />
        </div>
      </div>
      {/* Code commented due the US- 13076 */}
      {/* <div style={{ display: "flex", alignContent: "stretch", gap: 16 }}>
        <div style={{ flex: 1 }}>
          <Dropdown
            onChange={(option) =>
              formik.setFieldValue("persistinterval", option[0].value)
            }
            sizeClass="large"
            label={t("persistInterval")}
            value={[{ value: formik.values?.persistinterval, label: formik.values?.persistinterval }] || []}
            style={{ marginBottom: 16 }}
            required
          >
            <DropdownOption key={`100`} label={t("every100ms")} value={`100`} />
            <DropdownOption
              key={`1000`}
              label={t("everySecond")}
              value={`1000`}
            />
            <DropdownOption
              key={`3000`}
              label={t("every3seconds")}
              value={`3000`}
            />
            <DropdownOption
              key={`5000`}
              label={t("every5seconds")}
              value={`5000`}
            />
          </Dropdown>
        </div>
      </div> */}
    </>
  );
};
