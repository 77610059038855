import { gql } from "@apollo/client";

export const getAllServices = gql`
  query {
    servicesByName(nameFilter: "") {
      id
      name
      identity {
        id
        type
        status
        ownedRoles {
          id
          name
        }
      }
    }
  }
`;

export const getServiceByName = gql`
  query getServiceByName($nameFilter: String!) {
    servicesByName(nameFilter: $nameFilter) {
      id
      name
      identity {
        id
        type
        status
        ownedRoles {
          id
          label
          name
          description
          permissions {
            name
            description
          }
          groups {
            id
            name
            label
            description
          }
        }
      }
    }
  }
`;

export const getServiceByIdentityId = gql`
  query getServiceByIdentityId($identityId: String!) {
    serviceByIdentityId(identityId: $identityId) {
      id
      name
      identity {
        id
        type
        status
        ownedRoles {
          id
          name
        }
      }
    }
  }
`;
