// UTILS
import capitalize from "utils/capitalize";

// REACT
import { useContext, useEffect, useState } from "react";

// I18NEXT
import { useTranslation } from "react-i18next";

// APOLLO
import { PureQueryOptions, useMutation, useQuery } from "@apollo/client";
import { getAllGroupData } from "gql/identity/queries/groupQueries";
import { getAllRolesData } from "gql/identity/queries/roleQueries";
import { GlobalNotificationService } from "services/GlobalNotification/GlobalNotificationService";
import { GlobalNotificationType } from "services/GlobalNotification/GlobalNotificationType";
import {
  addRoleToGroup,
  removeRoleFromGroup,
} from "gql/identity/mutations/roleMutations";

// COMPONENTS
import { ThreeStateValue } from "@abb/abb-common-ux-react";
import AbbSelect from "components/Select/Select";
import { QueryResultRenderer } from "components/QueryResultRenderer/QueryResultRenderer";
import { ProductsGridContext } from "layouts/main/MainLayout";
import FormSkeleton from "components/Skeletons/commonux/Form";
import { Identity } from "types/identity/Identity";
import { Role } from "types/identity/Role";
import { Group } from "types/identity/Group";

// STYLING
import styles from "./Groups.module.scss";
import {
  filterInternalGroups,
  filterInternalRoles,
} from "utils/filterInternal";
import { SlidePanel, Button } from "@abb/common-ux";
import { usePanel } from "components/Panel/PanelContext";
import { ApolloContexts } from "services/ApolloService";
import { filterSelectableGroups } from "utils/filterSelectableGroups";

interface Props {
  roles: string[];
  service: string;
}

const returnCheckBoxType = (
  group: { id: string; label: string },
  roles: Role[]
) => {
  if (
    roles.every(
      (r) => r.groups && r.groups.some((rg: Group) => rg.id === group.id)
    )
  ) {
    return ThreeStateValue.Checked;
  }
  if (
    roles.some(
      (r) => r.groups && r.groups.some((rg: Group) => group.id === rg.id)
    )
  ) {
    return ThreeStateValue.Indeterminate;
  } else {
    return ThreeStateValue.Unchecked;
  }
};

const getFilteredGroups = (
  groups: Group[],
  roles: Role[],
  currentService: string
) => {
  const filteredGroupsIds: string[] = filterSelectableGroups(
    groups,
    currentService
  ).map((g: Group) => g.id);
  const currentGroups = roles
    .flatMap((r) => {
      return (
        r.groups &&
        filterInternalGroups(r.groups)!.map((g) => {
          return { id: g.id, label: g.label };
        })
      );
    })
    .filter((g): g is { id: string; label: string } => !!g)
    .filter((g) => filteredGroupsIds.includes(g.id))
    .filter(
      (g, index, groupsArray) =>
        groupsArray.findIndex((innerGroup) => innerGroup.id === g.id) === index
    );
  roles
    .flatMap(
      (r) =>
        r.groups &&
        r.groups.map((g) => {
          return { id: g.id, name: g.name, owner: g.owner };
        })
    )
    .filter(
      (g): g is { id: string; name: string; owner: Identity | undefined } => !!g
    );

  return currentGroups;
};

interface GroupOption {
  label: Group["label"];
  value: Group["id"];
}

const Groups = ({ roles: roleIds, service }: Props) => {
  const [options, setOptions] = useState<GroupOption[]>([]);
  const [values, setValues] = useState<
    { value: string; label: string; state: ThreeStateValue }[]
  >([]);
  const { t } = useTranslation();
  const { closePanel, isOpen } = usePanel();
  const { setSelectedProductRoles } = useContext(ProductsGridContext);
  const refetchQueries: (string | PureQueryOptions)[] = [
    "getAllRoles",
    "getServiceByName",
  ];
  const { context } = ApolloContexts.Identity;

  const [addRoleToGroupMutation] = useMutation(addRoleToGroup, {
    refetchQueries,
    context,
  });
  const [removeRoleFromGroupMutation] = useMutation(removeRoleFromGroup, {
    refetchQueries,
    context,
  });

  const groupsQuery = useQuery(getAllGroupData, {
    fetchPolicy: "cache-and-network",
    context,
  });
  const { data: groupsData } = groupsQuery;

  const rolesQuery = useQuery(getAllRolesData, {
    fetchPolicy: "cache-and-network",
    context,
  });
  const { data: rolesData } = rolesQuery;

  useEffect(() => {
    const filteredGroupOptions: GroupOption[] = filterSelectableGroups(
      groupsData?.groupsByName || [],
      service
    ).map((g: Group) => ({
      label: g.label,
      value: g.id,
    }));

    setOptions(filteredGroupOptions);
  }, [groupsData, setOptions, service]);

  useEffect(() => {
    if (rolesData && groupsData) {
      const currentRoles: Role[] =
        rolesData &&
        filterInternalRoles(rolesData.rolesByName)!.filter((r: Role) =>
          roleIds.includes(r.id)
        );
      const groups = getFilteredGroups(
        groupsData?.groupsByName,
        currentRoles,
        service
      );
      const selectValues = groups.map(
        (group: { id: string; label: string }) => ({
          value: group.id,
          label: group.label,
          state: returnCheckBoxType(group, currentRoles),
        })
      );
      setValues(selectValues);
    }
  }, [rolesData, groupsData, setValues, roleIds, service]);

  const singleSubmit = async () => {
    try {
      const currentRoles: Role[] =
        rolesData &&
        filterInternalRoles(rolesData.rolesByName)!.filter((r: Role) =>
          roleIds.includes(r.id)
        );
      const originalGroups = getFilteredGroups(
        groupsData?.groupsByName,
        currentRoles,
        service
      );

      const addedGroups = values.filter(
        (g) => !originalGroups.some((og) => og.id === g.value)
      );
      const removedGroups = originalGroups.filter(
        (og) => !values.some((g) => og.id === g.value)
      );
      for (let group of addedGroups) {
        await addRoleToGroupMutation({
          variables: {
            input: {
              roleId: currentRoles[0].id,
              groupId: group.value,
            },
          },
        });
      }
      for (let group of removedGroups) {
        await removeRoleFromGroupMutation({
          variables: {
            input: {
              roleId: currentRoles[0].id,
              groupId: group.id,
            },
          },
        });
      }
      GlobalNotificationService.publishNotification({
        text: capitalize(
          t(
            `app:screen.products.panel.group.edit.single.form.notifications.success`
          )
        ),
        type: GlobalNotificationType.Success,
      });
      setSelectedProductRoles([]);
      closePanel();
    } catch (e: any) {
      GlobalNotificationService.publishNotification({
        text:
          e.message ||
          capitalize(
            t(
              `app:screen.products.panel.group.edit.single.form.notifications.error`
            )
          ),
        type: GlobalNotificationType.Alarm,
      });
    }
  };

  const multipleSubmit = async () => {
    try {
      const currentRoles: Role[] =
        rolesData &&
        rolesData.rolesByName.filter((r: Role) => roleIds.includes(r.id));
      const originalGroups = getFilteredGroups(
        groupsData?.groupsByName,
        currentRoles,
        service
      );

      const removedGroups = originalGroups.filter(
        (og) => !values.some((g) => og.id === g.value)
      );
      for (let group of removedGroups) {
        const rolesInGroup = currentRoles.filter(
          (r) => r.groups && r.groups.some((g: Group) => g.id === group.id)
        );
        for (let role of rolesInGroup) {
          await removeRoleFromGroupMutation({
            variables: {
              input: {
                roleId: role.id,
                groupId: group.id,
              },
            },
          });
        }
      }

      const groupsSelected = values.filter(
        (g) => g.state === ThreeStateValue.Checked
      );
      for (let group of groupsSelected) {
        const rolesNotInGroup = currentRoles.filter(
          (r) => r.groups && !r.groups.some((g: Group) => g.id === group.value)
        );
        for (let role of rolesNotInGroup) {
          await addRoleToGroupMutation({
            variables: {
              input: {
                roleId: role.id,
                groupId: group.value,
              },
            },
          });
        }
      }
      GlobalNotificationService.publishNotification({
        text: capitalize(
          t(
            `app:screen.products.panel.group.edit.multiple.form.notifications.success`
          )
        ),
        type: GlobalNotificationType.Success,
      });
      setSelectedProductRoles([]);
      closePanel();
    } catch (e: any) {
      GlobalNotificationService.publishNotification({
        text:
          e.message ||
          capitalize(
            t(
              `app:screen.products.panel.group.edit.multiple.form.notifications.error`
            )
          ),
        type: GlobalNotificationType.Alarm,
      });
    }
  };

  const onSubmit = () => {
    if (roleIds.length === 1) {
      singleSubmit();
    } else if (roleIds.length > 1) {
      multipleSubmit();
    }
  };

  const groupsText = `${values.length} ${capitalize(
    t("app:screen.products.panel.group.edit.single.form.others.groupsSelected")
  )}`;
  return (
    <div>
      <SlidePanel
        title={capitalize(
          t("app:screen.products.panel.group.edit.single.form.title")
        )}
        isOpen={isOpen}
        closePanel={closePanel}
        bottomActions={
          <>
            <Button
              type="discreet-black"
              text={t("cancel")}
              onPress={closePanel}
            />
            <Button
              type="primary-blue"
              text={capitalize(
                t(
                  "app:screen.products.panel.group.edit.single.form.buttons.save"
                )
              )}
              onPress={onSubmit}
            />
          </>
        }
      >
        <QueryResultRenderer
          queryResults={[
            { queryResult: groupsQuery, dataKey: `groupsByName` },
            { queryResult: rolesQuery, dataKey: `rolesByName` },
          ]}
          skeleton={<FormSkeleton />}
        >
          {() => {
            return (
              <div className={styles.form}>
                <AbbSelect
                  values={options}
                  title={capitalize(
                    t(
                      "app:screen.products.panel.group.edit.single.form.fields.groups"
                    )
                  )}
                  onValueChange={(v) => setValues(v)}
                  value={values}
                  placeholder={capitalize(
                    t(
                      "app:screen.products.panel.group.edit.single.form.placeholders.groups"
                    )
                  )}
                  stylesSelectContainer={styles.selectContainer}
                  customValue={groupsText}
                />
              </div>
            );
          }}
        </QueryResultRenderer>
      </SlidePanel>
    </div>
  );
};

export default Groups;
