import React, { useContext, useEffect, useState } from "react";
import { StructureTreeWithDrag } from "components/StructureTree/StructureTreeView";
import { IStructureTreeItem } from "components/StructureTree/StructureTreeItem";
import { useTranslation } from "react-i18next";
import { Input } from "@abb/abb-common-ux-react";
import { useStructureTree } from "components/StructureTree/structureHooks";
import { useStructureContext } from "../StructureContext";
import { BreadcrumbContext } from "../Structure";
import { getStructureChildrenIds } from "utils/assetStucture";
import { STRUCTURE_SIDEBAR_ASSET_TREE_MAX_WIDTH } from "config/constants";

export interface Props {
  onClickOnTreeItem: (item: IStructureTreeItem) => void;
  onChange?: (item: IStructureTreeItem[]) => void;
}
export const StructureSidebar = ({ onClickOnTreeItem, onChange }: Props) => {
  const [filter, setFilter] = useState("");
  const { t } = useTranslation();
  const { tree } = useStructureTree();
  const {
    setSelectedStructure,
    selectedStructure,
    setSelectedStructureChildrenIds,
  } = useStructureContext();
  const { setBreadcrumb } = useContext(BreadcrumbContext);

  useEffect(() => {
    if (tree && !selectedStructure) {
      setSelectedStructure(tree[0]);
      setSelectedStructureChildrenIds(getStructureChildrenIds([tree[0]]));
      setBreadcrumb([
        {
          title: tree[0].name,
          onClick: () => onClickOnTreeItem(tree[0]),
        },
      ]);
    }
  }, [
    onClickOnTreeItem,
    selectedStructure,
    setBreadcrumb,
    setSelectedStructure,
    setSelectedStructureChildrenIds,
    tree,
  ]);

  const handleOnClickOnItem = (item: IStructureTreeItem) => {
    setSelectedStructure(item);
    setSelectedStructureChildrenIds(getStructureChildrenIds([item]));
    onClickOnTreeItem(item);
  };

  return (
    <div className="sidebar-container">
      <Input
        className="sidebar-search"
        data-type="text"
        placeholder={t("search")}
        icon="abb/search"
        value={filter}
        onValueChange={(v) => setFilter(v)}
        dataType="search"
        type="discreet"
      />
      {tree && (
        <StructureTreeWithDrag
          items={tree}
          expandOnSelectedItem
          onClickOnItem={handleOnClickOnItem}
          filter={filter}
          selectedId={selectedStructure?.id}
          onChange={onChange}
          maxWidth={STRUCTURE_SIDEBAR_ASSET_TREE_MAX_WIDTH}
        />
      )}
    </div>
  );
};
