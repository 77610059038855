// REACT
import { useContext } from "react";

// APOLLO
import { useMutation, useQuery } from "@apollo/client";
import { deleteAssets } from "gql/storageApi/mutations/assetMutations";
import {
  AssetListQueryResponse,
  assetListQuery,
} from "gql/storageApi/queries/assetQueries";

// COMPONENTS
import { AssetsGridContext } from "../Structure";
import RemovePanel from "components/Panel/RemovePanel";
import { ApolloContexts } from "services/ApolloService";

interface Props {
  assets: string[];
  onAfterDelete?: () => void;
}

const RemoveAsset = ({ assets, onAfterDelete }: Props) => {
  // CONTEXT
  const { setSelectedAssets } = useContext(AssetsGridContext);
  const { context } = ApolloContexts.AssetsApi;
  // QUERIES
  const { data: assetsData } = useQuery<AssetListQueryResponse>(
    assetListQuery,
    {
      fetchPolicy: "cache-and-network",
      context,
    }
  );

  // MUTATIONS
  const [deleteAssetsMutation] = useMutation(deleteAssets, {
    context,
  });

  const removeHandler = async () => {
    const { data } = await deleteAssetsMutation({
      variables: { assetIds: assets },
    });
    onAfterDelete && onAfterDelete();
    return (data?.deleteAssets.length as number) || 0;
  };

  const filteredAssets =
    (assetsData &&
      assetsData.assets.filter((u) => assets.includes(u.assetid))) ||
    [];

  return (
    <RemovePanel
      elementIds={assets}
      elementName={filteredAssets[0]?.assetname}
      resourcePath={"app:screen.structure.panel.deleteAsset"}
      setSelectedElements={() => setSelectedAssets([])}
      removeHandler={removeHandler}
    ></RemovePanel>
  );
};

export default RemoveAsset;
