import { FC } from "react";
import { GroupsTreeView } from "./GroupsTreeView";

export interface UsersSidebarProps {
  preSelectedGroupId?: string;
  onGroupSelected: () => void;
  onAllUsersSelected: () => void;
  preSelectedAllUsersId: string;
}

export const UsersSidebar: FC<UsersSidebarProps> = ({
  onGroupSelected,
  preSelectedGroupId,
}) => {
  return (
    <div className={"sidebar-container"}>
      <GroupsTreeView
        onGroupSelect={onGroupSelected}
        preSelectedGroupId={preSelectedGroupId}
      />
    </div>
  );
};
