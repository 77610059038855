import { gql } from "@apollo/client";

export const addRoleToGroup = gql`
  mutation addRoleToGroup($input: AddRoleToGroupInput!) {
    addRoleToGroup(input: $input)
  }
`;

export const removeRoleFromGroup = gql`
  mutation removeRoleFromGroup($input: RemoveRoleFromGroupInput!) {
    removeRoleFromGroup(input: $input)
  }
`;
