// LIBS
import { useDrag, useDrop } from "react-dnd";

// CONTEXT
import { useConfig } from "components/Config/ConfigProvider";

// COMPONENTS
import { IStructureTreeItem } from "components/StructureTree/StructureTreeItem";
import { canMoveStructure } from "./validators";

// STYLING
import { DragItemTypes } from "./types";
import { DraggableBase } from "./DraggableBase";

export const DraggableStruct = ({
  children,
  title,
  data,
  onDrop,
}: {
  children: React.ReactNode;
  title: string;
  data: IStructureTreeItem;
  onDrop: (from: IStructureTreeItem, to: IStructureTreeItem) => void;
}) => {
  const { config } = useConfig();
  const { structureTreeMaxLevels } = config;
  const [{ isDragging, draggedItem }, drag, dragPreview] = useDrag({
    item: { type: DragItemTypes.STRUCTURE, data: data },
    collect: (monitor) => {
      return {
        isDragging: !!monitor.isDragging(),
        draggedItem: monitor.getItem(),
      };
    },
  });
  const [{ isOver, canDrop }, drop] = useDrop({
    accept: DragItemTypes.STRUCTURE,
    drop: () => onDrop(draggedItem.data, data),
    canDrop: () =>
      canMoveStructure(draggedItem.data, data, structureTreeMaxLevels),
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
      canDrop: !!monitor.canDrop(),
    }),
  });
  return (
    <DraggableBase
      canDrop={canDrop}
      draggedItem={draggedItem}
      drop={drop}
      drag={drag}
      dragPreview={dragPreview}
      icon={data.type === "subgroup" ? "folder" : ""}
      isDragging={isDragging}
      isOver={isOver}
      title={title}
    >
      {children}
    </DraggableBase>
  );
};
