import useKeyPress from "libs/@abbrda/abb-common-ux-react/internalUtils/useKeyPress";
import React, {
  createContext,
  FC,
  useCallback,
  useContext,
  useState,
} from "react";

export interface PanelContextValue {
  setPanel: (template: JSX.Element) => void;
  closePanel: () => void;
  panelContent?: JSX.Element;
  actions: JSX.Element | null;
  isOpen: boolean;
  setActions: React.Dispatch<React.SetStateAction<JSX.Element | null>>;
}

const defaultValue = {
  setPanel: () => undefined,
  isOpen: false,
  closePanel: () => undefined,
  actions: null,
  setActions: () => undefined,
};

const PanelContext = createContext<PanelContextValue>(defaultValue);

export const usePanel = () => {
  return useContext(PanelContext);
};

export const PanelProvider: FC = ({ children }) => {
  const [panelContent, setPanelContent] = useState<JSX.Element | undefined>();
  const [isOpen, setIsOpen] = useState<boolean>(defaultValue.isOpen);
  const [actions, setActions] = useState<JSX.Element | null>(null);

  useKeyPress("Escape", () => {
    if (isOpen) {
      closePanel();
    }
  });

  const setPanel = useCallback((content: JSX.Element) => {
    setPanelContent(content);
    setIsOpen(true);
  }, []);

  const closePanel = useCallback(() => {
    setIsOpen(false);
    // Wait for animation to complete.
    setTimeout(() => {
      setPanelContent(() => <></>);
    }, 300);
  }, []);

  return (
    <PanelContext.Provider
      value={{
        panelContent,
        isOpen,
        setPanel,
        closePanel,
        actions,
        setActions,
      }}
    >
      {children}
    </PanelContext.Provider>
  );
};

export const BottomActionButtons = () => {
  const { actions } = usePanel();
  return actions;
};

export const PanelForTests = () => {
  const { panelContent } = usePanel();
  return panelContent || <></>;
};
