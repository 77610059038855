export interface EditByIdInHierachyOptions<T> {
  items: T[];
  itemId: string;
  changes: Omit<Partial<T>, "id">;
  idGetter: (item: T) => string;
  childrenGetter: (parent: T) => T[];
}

/**
 * Edits an item from the hierachy structure by a given item id.

 * @param items hierachical item collection
 * @param itemId id of the item to find.
 * @param idGetter a getter function that given a item returns its id.
 * @param childrenGetter a getter function that given a parent node returns its children.
 */
export function editByIdInHierarchy<T>({
  items,
  itemId,
  changes,
  idGetter,
  childrenGetter,
}: EditByIdInHierachyOptions<T>): T[] {
  return items.map((item) => {
    const newItem = idGetter(item) === itemId ? { ...item, ...changes } : item;

    return {
      ...newItem,
      children: editByIdInHierarchy<T>({
        items: childrenGetter(item) || [],
        itemId,
        changes,
        idGetter,
        childrenGetter,
      }),
    };
  });
}
