import React, { Dispatch, SetStateAction, useState } from "react";
import { TopNavigation } from "../../components/TopNavigation/TopNavigation";
import styles from "./MainLayout.module.scss";
import { PanelProvider } from "components/Panel/PanelContext";
import { ModalContent, ModalProvider } from "components/Modal/ModalContext";

export const PanelContext = React.createContext<
  | [undefined, () => {}]
  | [JSX.Element | undefined, Dispatch<SetStateAction<JSX.Element | undefined>>]
>([
  undefined,
  () => {
    console.log("modal not loaded");
  },
]);
interface UsersGridContextType {
  selectedUsers: string[];
  setSelectedUsers: (users: string[]) => void;
}

interface ProductsGridContextType {
  selectedProductRoles: string[];
  setSelectedProductRoles: (users: string[]) => void;
}

export const UsersGridContext = React.createContext<UsersGridContextType>({
  selectedUsers: [],
  setSelectedUsers: () => {},
});

export const ProductsGridContext = React.createContext<ProductsGridContextType>(
  {
    selectedProductRoles: [],
    setSelectedProductRoles: () => {},
  }
);

export const MainLayout: React.FunctionComponent = ({ children }) => {
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  const [selectedProductRoles, setSelectedProductRoles] = useState<string[]>(
    []
  );

  return (
    <ModalProvider>
      <ModalContent />
      <PanelProvider>
        <UsersGridContext.Provider
          value={{
            selectedUsers,
            setSelectedUsers: (users: string[]) => setSelectedUsers(users),
          }}
        >
          <ProductsGridContext.Provider
            value={{
              selectedProductRoles,
              setSelectedProductRoles,
            }}
          >
            <div className={`${styles.mainLayout} h-100`}>
              <div className={styles.topNavigation}>
                <TopNavigation />
              </div>
              <div className={styles.mainArea}>{children}</div>
            </div>
          </ProductsGridContext.Provider>
        </UsersGridContext.Provider>
      </PanelProvider>
    </ModalProvider>
  );
};
