// REACT
import React, { useRef, useState } from "react";

// COMPONENTS
import { Icon } from "@abb/abb-common-ux-react";
import SelectOptions, { SelectValueProps } from "./SelectOptions";

// STYLING
import styles from "./Select.module.scss";
import cx from "classnames";
import { useOutsideAlerter } from "libs/hooks/useOutsideAlerter";

interface Props {
  values: any[];
  customValue?: string;
  title?: string;
  placeholder?: string;
  value: SelectValueProps[];
  errorMsg?: string;
  required?: boolean;
  className?: string;
  stylesSelectContainer: string;
  onValueChange: (v: any) => void;
  onBlur?: () => void;
}

const AbbSelect = ({
  values,
  title,
  customValue,
  placeholder = "",
  value,
  errorMsg = "",
  stylesSelectContainer,
  required = false,
  className,
  onValueChange,
  onBlur = () => {},
}: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOnClick = () => {
    setIsOpen(!isOpen);
    onBlur();
  };

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, () => setIsOpen(false));
  return (
    <div className={className}>
      {title && (
        <div className={styles.dropdownTitle}>
          <span>{title}</span>
          {required && <span className={styles.required}>*</span>}
        </div>
      )}
      <div className={styles.dropdownContainer} ref={wrapperRef}>
        <div onClick={handleOnClick} className={styles.valueContainer}>
          <span
            className={
              value.length > 0 ? styles.selectedItems : styles.placeholder
            }
          >
            {value.length > 0 ? customValue : placeholder}
          </span>
          <div className={styles.valueIcon}>
            <Icon name={isOpen ? "abb/caret-up" : "abb/caret-down"}></Icon>
          </div>
        </div>
        {isOpen && (
          <div className={cx(styles.selectContainer, stylesSelectContainer)}>
            <SelectOptions
              onValueChange={onValueChange}
              value={value}
              values={values}
            ></SelectOptions>
          </div>
        )}
      </div>
      {!isOpen && errorMsg && (
        <div className={styles.errorContainer}>
          <div className={styles.errorIcon}>
            <Icon name="abb/error-circle-1"></Icon>
          </div>
          <span className={styles.errorText}>{errorMsg}</span>
        </div>
      )}
    </div>
  );
};

export default AbbSelect;
