import { gql } from "@apollo/client";
import { AssetConfiguration, AssetModel } from "types/storageApi/QueryAsset";
//Assets API
export const assetsDatagridQuery = gql`
  query assetsDatagrid(
    $limit: Float
    $offset: Float
    $orderBy: OrderBy
    $search: String
    $assetid: ID
    $assetname: String
    $modelids: [String!]
    $domainids: [Float!]
    $structureids: [String!]
  ) {
    assets(
      options: {
        limit: $limit
        offset: $offset
        orderBy: $orderBy
        search: $search
        assetid: $assetid
        assetname: $assetname
        modelids: $modelids
        domainids: $domainids
        structureids: $structureids
      }
    ) {
      assetid
      assetname
      modelid
      domains
      structure
      domains
      activeat
      configuration
    }
    filteredAssetList: assets(
      options: {
        search: $search
        assetid: $assetid
        assetname: $assetname
        modelids: $modelids
        domainids: $domainids
        structureids: $structureids
      }
    ) {
      assetid
      configuration
    }
    count: countAssets(
      options: {
        search: $search
        assetid: $assetid
        assetname: $assetname
        modelids: $modelids
        domainids: $domainids
        structureids: $structureids
      }
    )
  }
`;

export const assetListQuery = gql`
  query getAllAssets {
    assets {
      assetid
      assetname
    }
  }
`;

export const getAsset = gql`
  query getAsset($assetId: String) {
    asset(assetid: $assetId) {
      assetid
      assetname
      modelid
      domains
      structure
      domains
      activeat
      configuration
    }
  }
`;

export const updateAssetRetentionPolicy = gql`
  mutation UpdateRetentionPolicy ($retentionPolicy: String!){
    updateRetentionPolicy(retentionPolicy: $retentionPolicy)
  }
`;

export interface AssetsDatagridQueryResponse {
  assets: AssetModel[];
  filteredAssetList: { assetid: string; configuration: AssetConfiguration }[];
  count: number;
}

export interface AssetListQueryResponse {
  assets: {
    assetid: string;
    assetname: string;
  }[];
}

export interface GetAssetQueryResponse {
  asset: AssetModel;
}

// TODO Replace when implementing Import/Export from CSV
export const getAllAssets = gql`
  query getAllAssets {
    master_asset(
      where: {
        _and: [
          { modelid: { _similar: "asset.%.dfa.raro%" } }
          { modelid: { _neq: "asset.azureiothub.dfa.raro" } }
        ]
      }
    ) {
      assetid
      assetname
      modelid
      master_assetconfiguration_array {
        propertyconfig
      }
      master_assetstructure_array {
        structureid
        master_structure_object {
          properties
        }
      }
      master_asset_domain_array {
        master_code_list_item_object {
          id
          value
        }
      }
      master_model_object {
        modelid
      }
    }
  }
`;
