import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import {
  setLocale as setYupLocale,
  defaultLocale as defaultYupLocale,
  LocaleObject as YupLocaleObject,
} from "yup";
import { zh as zhYupLocale } from "yup-locales";

// English
import enCommon from "./locales/en/common.json";

// Chinese
import zhCommon from "./locales/zh/common.json";

// Supported languages (just the prefix of the i18n string)
// Example: en-US and en-GB are both supported by "en"
enum Language {
  EN = "en", // English
  ZH = "zh", // Chinese
}

// Map of supported languages to Yup locales
const yupLocalesMap: Record<Language, YupLocaleObject> = {
  [Language.EN]: defaultYupLocale,
  [Language.ZH]: zhYupLocale,
};

// Default language is English
const defaultLanguage = Language.EN;

i18n.use(initReactI18next).init({
  resources: {
    [Language.EN]: {
      common: enCommon,
      app: require("./locales/en/app.json"),
      yup: require("./locales/en/yup.json"),
    },
    [Language.ZH]: {
      common: zhCommon,
      app: require("./locales/zh/app.json"),
      yup: require("./locales/zh/yup.json"),
      roles: require("./locales/zh/roles.json"),
    },
  },
  fallbackLng: defaultLanguage,
  defaultNS: "common",
  debug: true,
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
});

const allowedLanguages = Object.values<string>(Language);

// Get the supported browser language in preference order
// Note: Not all browsers support navigator.languages: https://caniuse.com/?search=navigator.languages
const browserLanguages = (navigator?.languages || [])
  .map((language) => language.toLowerCase().split("-")[0])
  .filter((language) => allowedLanguages.includes(language)) as Language[];

// Get the first supported language from the browser languages
const selectedLanguage = browserLanguages[0] || defaultLanguage;

// Set the language for Yup and the entire application
i18n.changeLanguage(selectedLanguage);
setYupLocale(yupLocalesMap[selectedLanguage]);

export default i18n;
