import { User } from "@abb/identity-auth-react";
import { useMutation, useQuery } from "@apollo/client";
import { updateUser } from "gql/identity/mutations/userMutations";
import { getAllGroups } from "gql/identity/queries/groupQueries";
import { getAllUsers } from "gql/identity/queries/userQueries";
import { ApolloContexts } from "services/ApolloService";
import { Group } from "types/identity/Group";

export interface IParams {
  type: string;
  id: string;
}
export const DEFAULT_ORG_NAME = "default";

export const useUpdateUserMutation = () => {
  return useMutation(updateUser, {
    context: ApolloContexts.Identity.context
  });
};

export const useAllGroups = () => {
  return useQuery<{ groupsByName: Group[] }>(getAllGroups, {
    fetchPolicy: "network-only",
    context: ApolloContexts.Identity.context
  });
};

export const useAllUsers = () => {
  return useQuery<{ usersByNameAndEmail: User[] }>(getAllUsers, {
    fetchPolicy: "network-only",
    context: ApolloContexts.Identity.context
  });
};
