import { Fragment } from "react";
import ContentLoader from "react-content-loader";
import skeletonDefaults from "../Skeleton";

const FormSkeleton = (props: any) => {
  const formDefaults = {
    ...skeletonDefaults,
    rx: 4,
    ry: 4,
    width: 20,
    height: 20,
    heightTotal: 80,
    padding: 2,
    gap: 8,
  };

  const labelConfig = {
    ...formDefaults,
    y: 0,
    width: 120,
    height: 20,
    heightTotal: 28,
  };

  const inputConfig = {
    ...formDefaults,
    y: 30,
    width: 260,
    height: 40,
    heightTotal: 48,
  };

  const fullInputQuantity = Math.ceil(
    (props.height || formDefaults["heightTotal"]) / formDefaults["heightTotal"]
  );

  const input = (num: number) => {
    const {heightTotal: inputHeightTotal,...restInput}=inputConfig;
    const {heightTotal: labelHeightTotal,...restLabel}=labelConfig;
    const labelYPosition =
      (num >= 1
        ? (labelHeightTotal + inputHeightTotal) * num + 20
        : 0) + labelConfig["y"];
    const inputYPosition =
      (num >= 1
        ? (inputHeightTotal + labelHeightTotal) * num + 20
        : 0) + inputConfig["y"];
        
    return (
      <Fragment key={`skeleton-item-${num}`}>
        <rect {...restLabel} y={labelYPosition} />
        <rect {...restInput} y={inputYPosition} width={props.width} />
      </Fragment>
    );
  };

  let inputs = [];
  for (let i = 0; i < fullInputQuantity; i++) {
    inputs.push(input(i));
  }

  return (
    <ContentLoader
      height={
        props.inputs * formDefaults["heightTotal"] || formDefaults["heightTotal"]
      }
      backgroundColor={`#f5f5f5`}
      foregroundColor={`#ebebeb`}
    >
      {inputs}
    </ContentLoader>
  );
};

export default FormSkeleton;
