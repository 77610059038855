// UTILS
import capitalize from "utils/capitalize";

// REACT
import { useState } from "react";

// REACT-ROUTER
import { useHistory, useParams } from "react-router-dom";

// I18NEXT
import i18n from "libs/i18n";
import { useTranslation } from "react-i18next";

// LIBS
import Yup from "libs/validation/yup";
import { pick } from "lodash";
import moment from "moment";
import { FormikProps } from "formik";

// APOLLO
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import {
  updateAsset,
  createAsset,
} from "gql/storageApi/mutations/assetMutations";
import { getModelWithProperties } from "gql/storageApi/queries/modelQueries";
import {
  GetAssetQueryResponse,
  getAsset,
} from "gql/storageApi/queries/assetQueries";

// SERVICES
import { ApolloContexts, ApolloService } from "services/ApolloService";

// TYPES
import { AssetType } from "../types/AssetType";
import { GlobalNotificationType } from "services/GlobalNotification/GlobalNotificationType";
import { ConfigType } from "config";
import { RobotControllerType } from "../types/RobotControllerType";
import { DatabaseType } from "../types/DatabaseType";
import { AssetConfiguration, AssetModel } from "types/storageApi/QueryAsset";

// COMPONENTS
import { Step, Wizard } from "components/Wizard";
import { Add, Device, LocationDomain, Confirm, MqttParameters } from "./steps";

import { GlobalNotificationService } from "services/GlobalNotification/GlobalNotificationService";
import { WizardFormModel } from "./WizardFormModel";
import { QueryResultRenderer } from "components/QueryResultRenderer/QueryResultRenderer";
import { useConfig } from "components/Config/ConfigProvider";
import { usePanel } from "components/Panel/PanelContext";
import DefaultErrorBoundary from "layouts/ErrorBoundary/DefaultErrorBoundary";
import { OpcuaSecurityPolicy } from "../types/OpcuaSecurityPolicy";
import { OpcuaMessageSecurityMode } from "../types/OpcuaMessageSecurityMode";
import { useStructureContext } from "../StructureContext";
import { useMainStore } from "components/MainStore/MainStoreProvider";
import {
  GetKeysQueryResponse,
  getKeys,
} from "gql/storageApi/queries/keychainQueries";
import encryptWithPublicKey from "utils/encrypt";

export interface StepProps {
  formik: FormikProps<WizardFormModel>;
  isEdit?: boolean;
}

/**
 * Array of configuration properties of the asset that should never be shown in the details panel
 */
const propertyConfigToOmit = [`password`];

const steps: Step<WizardFormModel>[] = [
  {
    id: "add",
    component: Add,
    disableNextButtonOnInvalidForm: true,
    validationSchema: Yup.object().shape({
      name: Yup.string().required(),
      id: (Yup.string() as any).alphanumericHypensUnderscores().required(),
      type: Yup.string().required(),
    }),
  },
  {
    id: "device",
    component: Device,
    disableNextButtonOnInvalidForm: true,
    validationSchema: Yup.object().shape({
      protocol: Yup.string().when("type", {
        is: AssetType.PLCOpcUA,
        then: (schema) => schema.required(),
        otherwise: (schema) => schema,
      }),
      hostname: Yup.string().required(),
      user: Yup.string(),
      password: Yup.string(),
      pathname: Yup.string(),
      robotControllerType: Yup.string().when("type", {
        is: AssetType.Robot,
        then: (schema) => schema.required(),
        otherwise: (schema) => schema,
      }),
      databaseType: Yup.string().when("type", {
        is: AssetType.Database,
        then: (schema) => schema.required(),
        otherwise: (schema) => schema.nullable(),
      }),

      robapiport: Yup.number()
        .typeError(i18n.t("yup:errors.shouldBeNumber"))
        .when(["type", "robotControllerType"], {
          is: (type: AssetType, robotControllerType: RobotControllerType) =>
            type === AssetType.Robot &&
            robotControllerType === RobotControllerType.VirtualController,
          then: (schema) =>
            schema.required().typeError(i18n.t("yup:errors.shouldBeNumber")),
          otherwise: (schema) =>
            schema
              .typeError(i18n.t("yup:errors.shouldBeNumber"))
              .transform((value) => (isNaN(value) ? undefined : value))
              .nullable(),
        }),
      rwversion: Yup.string().when(["type", "robotControllerType"], {
        is: (type: AssetType, robotControllerType: RobotControllerType) =>
          type === AssetType.Robot &&
          robotControllerType === RobotControllerType.VirtualController,
        then: (schema) => schema.required(),
        otherwise: (schema) => schema,
      }),
      systemid: Yup.string().when(["type", "robotControllerType"], {
        is: (type: AssetType, robotControllerType: RobotControllerType) =>
          type === AssetType.Robot &&
          robotControllerType === RobotControllerType.VirtualController,
        then: (schema) => schema.required(),
        otherwise: (schema) => schema,
      }),
      port: Yup.number().when("type", {
        is: (type: AssetType) =>
          type === AssetType.PLCOpcUA ||
          type === AssetType.MQTT ||
          type === AssetType.Database,
        then: (schema) =>
          schema.required().typeError(i18n.t("yup:errors.shouldBeNumber")),
        otherwise: (schema) =>
          schema
            .typeError(i18n.t("yup:errors.shouldBeNumber"))
            .transform((value) => (isNaN(value) ? undefined : value))
            .nullable(),
      }),
      database: Yup.string().when("type", {
        is: AssetType.Database,
        then: (schema) => schema.required(),
        otherwise: (schema) => schema,
      }),
      collection: Yup.string().when(["type", "databaseType"], {
        is: (type: AssetType, databaseType: DatabaseType) =>
          type === AssetType.Database && databaseType === DatabaseType.MongoDB,
        then: (schema: any) =>
          schema.alphanumericHypensUnderscores().required(),
        otherwise: (schema: any) => schema.alphanumericHypensUnderscores(),
      }),
      table: Yup.string().when(["type", "databaseType"], {
        is: (type: AssetType, databaseType: DatabaseType) =>
          type === AssetType.Database &&
          databaseType === DatabaseType.PostgreSQL,
        then: (schema) => schema.required(),
        otherwise: (schema) => schema,
      }),
    }),
  },
  {
    id: "mqttParameters",
    component: MqttParameters,
    disableNextButtonOnInvalidForm: true,
    validationSchema: Yup.object().shape({
      sourceTopics: Yup.array().when(["type", "destinationTopics"], {
        is: (typeValue: AssetType, destinationTopicsValue: string[]) =>
          typeValue === AssetType.MQTT && !destinationTopicsValue?.length,
        then: (schema) => schema.min(1),
      }),
      destinationTopics: Yup.array(),
    }),
    showStepIf: (formik) => formik.values.type === AssetType.MQTT,
  },
  {
    id: "location",
    component: LocationDomain,
    disableNextButtonOnInvalidForm: true,
    validationSchema: Yup.object().shape({
      location: Yup.object().shape({
        id: Yup.string().required(),
        label: Yup.string().required(),
      }),
    }),
  },
  {
    id: "confirm",
    component: Confirm,
  },
];

export const deviceInitialValues: WizardFormModel = {
  name: "",
  id: "",
  type: AssetType.Robot,
  robotControllerType: RobotControllerType.RobotController,
  databaseType: null,
  connectionString: "",
  opcuaSecurityPolicy: OpcuaSecurityPolicy.None,
  opcuaMessageSecurityMode: OpcuaMessageSecurityMode.None,
  protocol: "",
  hostname: "",
  user: "",
  password: "",
  database: "",
  collection: "",
  table: "",
  pathname: "",
  location: { id: "", label: "" },
  tag: [],
  robapiport: "",
  port: "",
  rwversion: "",
  systemid: "",
  sourceTopics: [],
  destinationTopics: [],
};

const DeviceWizard = () => {
  const { selectedStructure } = useStructureContext();
  const { panelContent } = usePanel();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { config } = useConfig();
  const { context } = ApolloContexts.AssetsApi;
  const [createAssetMutation] = useMutation(createAsset, { context });
  const [updateAssetMutation] = useMutation(updateAsset, { context });

  const history = useHistory();
  const { assetId }: { assetId?: string } = useParams();
  const { t } = useTranslation();
  const { tabBarConfirm } = useMainStore();

  const assetQueryResult = useQuery<GetAssetQueryResponse>(getAsset, {
    variables: { assetId },
    skip: !assetId,
    fetchPolicy: "network-only",
    context,
  });

  const [callGetKeysQuery] = useLazyQuery<GetKeysQueryResponse>(getKeys, {
    fetchPolicy: "network-only",
    context,
  });

  const onConfirm = async (rawValues: WizardFormModel) => {
    // Send empty string instead of None for opcuaSecurityPolicy and opcuaMessageSecurityMode
    const values: WizardFormModel = {
      ...rawValues,
      opcuaSecurityPolicy:
        rawValues.opcuaSecurityPolicy === OpcuaSecurityPolicy.None
          ? ""
          : rawValues.opcuaSecurityPolicy,
      opcuaMessageSecurityMode:
        rawValues.opcuaMessageSecurityMode === OpcuaMessageSecurityMode.None
          ? ""
          : rawValues.opcuaMessageSecurityMode,
    };
    setIsSubmitting(true);
    const modelId = getModelIdFromFormModel(values, config);

    let configFields;

    if (!modelId) {
      throw new Error(`modelid not found for given form model`);
    }

    try {
      configFields = await getPropertyConfigFieldsFromModelId(modelId);
    } catch (e: any) {
      GlobalNotificationService.publishNotification({
        text: `Couldn't create device`,
        type: GlobalNotificationType.Alarm,
      });
      console.error(
        `Couldn't fetch property config fields from given modelid: ${modelId}`,
        e
      );
      setIsSubmitting(false);
      return;
    }

    if (!configFields) {
      setIsSubmitting(false);
      return;
    }

    // Encrypt the password if it will be sent to the backend
    if (values.password) {
      // Get the public key from ApiHub to encrypt the password
      const getKeysQueryResult = await callGetKeysQuery();
      const publicKey = getKeysQueryResult?.data?.getKeys?.publicKey;

      if (!publicKey) {
        GlobalNotificationService.publishNotification({
          text: t("app:screen.structure.wizard.errors.apiHubPublicKeyError"),
          type: GlobalNotificationType.Alarm,
        });
        setIsSubmitting(false);
        return;
      }

      values.password = encryptWithPublicKey(values.password, publicKey);
    } else {
      // Remove from the values object to prevent sending an empty string it to the backend
      delete values.password;
    }

    const mutation = assetId ? updateAssetMutation : createAssetMutation;

    const mode = await getModeFromModelId(modelId, config);

    mutation({
      variables: {
        assetid: values.id,
        assetname: values.name,
        modelid: modelId,
        structureid: values.location.id,
        configuration: {
          ...pick(
            {
              ...values,
              mode,
              isEditable: true,
            },
            configFields
          ),
        },
        domainids: values.tag.map(({ value }) => parseFloat(value)),
        activeat: moment(),
      },
    })
      .then(
        () => {
          GlobalNotificationService.publishNotification({
            text: assetId
              ? t(`assetUpdatedSuccessfully`)
              : t(`assetCreatedSuccessfully`),
            type: GlobalNotificationType.Success,
          });
          history.push("/structure");
        },
        (e) => {
          let errorCode =
            e.graphQLErrors.length && e.graphQLErrors[0].extensions?.code;
          let text =
            errorCode === "constraint-violation"
              ? `${t(`couldntCreateAsset`)}: ${capitalize(
                  t("app:screen.structure.wizard.errors.assetIdRepeated")
                )}`
              : assetId
              ? t(`couldntUpdateAsset`)
              : t(`couldntCreateAsset`);
          GlobalNotificationService.publishNotification({
            text: text,
            type: GlobalNotificationType.Alarm,
          });
        }
      )
      .finally(() => setIsSubmitting(false));
  };

  const getModeFromModelId = async (
    modelId: string,
    config: ConfigType
  ): Promise<string | undefined> => {
    switch (modelId) {
      case config.modelIds.robotController:
      case config.modelIds.opcua:
      case config.modelIds.robotVirtualController:
        return "SOURCE";
      case config.modelIds.mqtt:
      case config.modelIds.mongodb:
      case config.modelIds.timescale:
        return "DESTINATION";
    }
  };

  const formikInitialValues = {
    ...deviceInitialValues,
    location: selectedStructure
      ? { id: selectedStructure.id, label: selectedStructure.name }
      : deviceInitialValues.location,
  };

  const renderWizard = (asset?: AssetModel) => {
    // Remove properties to omit from the configuration object
    // Ideally, this should be done in the backend but better safe than sorry
    propertyConfigToOmit.forEach((property) => {
      delete asset?.configuration?.[property as keyof AssetConfiguration];
    });

    return (
      <>
        <Wizard<WizardFormModel>
          steps={steps}
          clickableSteps
          isEdit={!!assetId}
          initialValues={
            asset
              ? getWizardFormModelFromAsset(asset, config)
              : formikInitialValues
          }
          onConfirm={(values) => {
            tabBarConfirm.confirmCallback = () => onConfirm(values);
            tabBarConfirm.clearConfirm();
          }}
          isSubmitting={isSubmitting}
        />
        <DefaultErrorBoundary>
          <>{panelContent}</>
        </DefaultErrorBoundary>
      </>
    );
  };

  return assetId ? (
    <QueryResultRenderer
      queryResults={[{ queryResult: assetQueryResult, dataKey: "asset" }]}
    >
      {([asset]: AssetModel[]) => renderWizard(asset)}
    </QueryResultRenderer>
  ) : (
    renderWizard()
  );
};

const getModelIdFromFormModel = (
  formModel: WizardFormModel,
  config: ConfigType
) => {
  if (
    formModel.type === AssetType.Robot &&
    formModel.robotControllerType === RobotControllerType.RobotController
  ) {
    return config.modelIds.robotController;
  }
  if (
    formModel.type === AssetType.Robot &&
    formModel.robotControllerType === RobotControllerType.VirtualController
  ) {
    return config.modelIds.robotVirtualController;
  }
  if (
    formModel.type === AssetType.Database &&
    formModel.databaseType === DatabaseType.MongoDB
  ) {
    return config.modelIds.mongodb;
  }
  if (
    formModel.type === AssetType.Database &&
    formModel.databaseType === DatabaseType.PostgreSQL
  ) {
    return config.modelIds.timescale;
  }
  if (formModel.type === AssetType.MQTT) {
    return config.modelIds.mqtt;
  }
  if (formModel.type === AssetType.PLCOpcUA) {
    return config.modelIds.opcua;
  }
};

const getSpecificPropsFromModelId = (
  modelId: string,
  config: ConfigType
): {
  type: AssetType;
  databaseType?: DatabaseType;
  robotControllerType?: RobotControllerType;
} => {
  switch (modelId) {
    case config.modelIds.robotController:
      return {
        type: AssetType.Robot,
        robotControllerType: RobotControllerType.RobotController,
      };
    case config.modelIds.robotVirtualController:
      return {
        type: AssetType.Robot,
        robotControllerType: RobotControllerType.VirtualController,
      };
    case config.modelIds.mongodb:
      return { type: AssetType.Database, databaseType: DatabaseType.MongoDB };
    case config.modelIds.timescale:
      return {
        type: AssetType.Database,
        databaseType: DatabaseType.PostgreSQL,
      };
    case config.modelIds.mqtt:
      return { type: AssetType.MQTT };
    case config.modelIds.opcua:
      return { type: AssetType.PLCOpcUA };
    default:
      return { type: AssetType.Robot };
  }
};

const getPropertyConfigFieldsFromModelId = async (
  modelId: string
): Promise<string[]> => {
  const modelPropertiesResponse = await ApolloService.getClient().query({
    query: getModelWithProperties,
    variables: { id: modelId },
    fetchPolicy: "network-only",
    context: ApolloContexts.Hasura.context,
  });
  return modelPropertiesResponse.data.master_model[0].master_properties_array.map(
    (property: any) => property.propertydescription
  );
};

const getWizardFormModelFromAsset = (
  asset: AssetModel,
  config: ConfigType
): WizardFormModel => {
  return {
    ...getSpecificPropsFromModelId(asset.modelid, config),
    id: asset.assetid || deviceInitialValues.id,
    name: asset.assetname || deviceInitialValues.name,
    tag:
      asset.domains?.map((domain) => ({
        value: domain.id.toString(),
        label: domain.value.name,
      })) || deviceInitialValues.tag,
    location: {
      id: asset.structure?.structureid || deviceInitialValues.location.id,
      label: asset.structure?.name || deviceInitialValues.location.label,
    },
    protocol: asset.configuration.protocol || deviceInitialValues.protocol,
    hostname: asset.configuration.hostname || deviceInitialValues.hostname,
    connectionString:
      asset.configuration.connectionString ||
      deviceInitialValues.connectionString,
    user: asset.configuration.user || deviceInitialValues.user,
    password: asset.configuration.password || deviceInitialValues.password,
    database: asset.configuration.database || deviceInitialValues.database,
    collection:
      asset.configuration.collection || deviceInitialValues.collection,
    table: asset.configuration.table || deviceInitialValues.table,
    pathname: asset.configuration.pathname || deviceInitialValues.pathname,
    robapiport:
      asset.configuration.robapiport || deviceInitialValues.robapiport,
    port: asset.configuration.port || deviceInitialValues.port,
    rwversion: asset.configuration.rwversion || deviceInitialValues.rwversion,
    systemid: asset.configuration.systemid || deviceInitialValues.systemid,
    opcuaMessageSecurityMode:
      asset.configuration.opcuaMessageSecurityMode ||
      deviceInitialValues.opcuaMessageSecurityMode,
    opcuaSecurityPolicy:
      asset.configuration.opcuaSecurityPolicy ||
      deviceInitialValues.opcuaSecurityPolicy,
    sourceTopics:
      asset.configuration.sourceTopics || deviceInitialValues.sourceTopics,
    destinationTopics:
      asset.configuration.destinationTopics ||
      deviceInitialValues.destinationTopics,
  };
};
export default DeviceWizard;
