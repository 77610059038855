import { useEffect, useState } from "react";
import { ApolloError, useQuery } from "@apollo/client";
// GRAPHQL
import { getDomains } from "gql/storageApi/queries/domainQueries";
import {
  getModels,
  getModelWithProperties,
} from "gql/storageApi/queries/modelQueries";
import { useTranslation } from "react-i18next";
//TYPES
import { QueryModel } from "types/storageApi/QueryModel";
import { ApolloContexts } from "services/ApolloService";
import { AssetModel } from "../Structure";

interface Model {
  propertyid: string;
  propertydescription: string;
  propertytype: string;
}

export const fullAssetPropertyList = [
  "modelid",
  "assetid",
  "assetname",
  "hostname",
  "user",
  "password",
  "robapiport",
  "systemid",
  "rwversion",
  "mode",
  "pathname",
  "opcuaMessageSecurityMode",
  "opcuaSecurityPolicy",
  "certificate",
  "privateKey",
  "certificateFilename",
  "privateKeyFilename",
  "sourceTopics",
  "destinationTopics",
  "port",
  "collection",
  "protocol",
  "database",
  "persistinterval",
  "connectionString",
  "tags",
];

function useQueryAssetsData() {
  const [loading, setLoading] = useState(true);
  const [model, setModel] = useState(AssetModel.All);
  const [error, setError] = useState<undefined | ApolloError>();
  const { t } = useTranslation();
  const { context } = ApolloContexts.Hasura;
  const {
    data: modelsData,
    loading: modelsLoading,
    error: modelsError,
  } = useQuery(getModels, {
    variables: {
      where: {
        _and: [
          { modelid: { _similar: "asset.%.dfa.raro%" } },
          { modelid: { _neq: "asset.azureiothub.dfa.raro" } },
        ],
      },
    },
    fetchPolicy: "network-only",
    context,
  });
  const {
    data: domainsData,
    loading: domainsLoading,
    error: domainsError,
  } = useQuery(getDomains, {
    fetchPolicy: "network-only",
    context,
  });
  const {
    data: modelData,
    loading: modelLoading,
    error: modelError,
  } = useQuery(getModelWithProperties, {
    fetchPolicy: "network-only",
    variables: {
      id: model,
    },
    skip: !model,
    context,
  });
  useEffect(() => {
    setLoading(modelsLoading || domainsLoading || modelLoading);
  }, [modelsLoading, domainsLoading, modelLoading]);
  useEffect(() => {
    setError(modelsError || domainsError || modelError);
  }, [modelsError, domainsError, modelError]);
  let modelList = undefined;
  if (
    modelData &&
    modelData.master_model &&
    modelData.master_model.length === 1 &&
    modelData.master_model[0].master_properties_array
  ) {
    modelList = [
      ...["assetid", "assetname"],
      ...modelData.master_model[0].master_properties_array.map(
        (model: Model) => model.propertydescription
      ),
    ];
  } else if (model === AssetModel.All) {
    modelList = fullAssetPropertyList;
  }
  return {
    loading,
    setLoading,
    setModel,
    loaded: modelsData && domainsData,
    error,
    modelList: modelList,
    modelDetailsPresent:
      model === AssetModel.All ||
      (modelData?.master_model?.length === 1 &&
        modelData.master_model[0].master_properties_array),
    types:
      modelsData && modelsData.master_model
        ? modelsData.master_model.map((model: QueryModel) => ({
            value: model.modelid,
            label: t(`app:assets.${model.modelid.replace(/\./g, "-")}`),
          }))
        : [],
    domains:
      domainsData && domainsData.master_code_list_item
        ? domainsData && domainsData.master_code_list_item
        : [],
  };
}

export default useQueryAssetsData;
