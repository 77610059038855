import * as React from "react";
import classNames from "classnames";
import { Icon } from "@abb/abb-common-ux-react";
import { CloseIcon } from "@abb/abb-common-ux-react";
import { ThemeContext } from "../../../utils/ThemeContext";
import {
  HtmlAttributes,
  getHtmlAttributes,
} from "../../../internalUtils/HtmlAttributes";
import styles from "./Input.module.scss";
import cx from "classnames";

const ESCAPE_KEY = 27;
const validationIconForValid = "abb/check-mark-circle-1";
const validationIconForInvalid = "abb/error-circle-1";

export interface InputProps extends HtmlAttributes {
  /**
   * Most standard HTML input types, plus one extra: textarea. Passed directly to the underlying
   * HTML input type (except textarea, which changes the underlying HTML element from 'input' to 'textarea').
   * Affects the browser-specific editor (mostly relevant in mobile) and sets the validation state
   * if no custom validation function is given). All basic HTML input types are supported - minus those that either:
   *  - are explicitly handled by other components (button, checkbox, file, image, radio, range, submit), or
   *  - are too complex to handle with this component (file, image, submit), or
   *  - don't really make much sense in this context (note, reset)
   */
  dataType:
    | "color"
    | "date"
    | "email"
    | "month"
    | "number"
    | "password"
    | "search"
    | "tel"
    | "text"
    | "textarea"
    | "time"
    | "url"
    | "week";
  /** Show asterisk before the label and draw a red border around the input field when empty. */
  required?: boolean;
  /** Short help text above the input field */
  label?: string;
  /** Additional help text below the input field itself. */
  description?: string;
  /** Different visual styles. Currently two styles designed and implemented. */
  type: "normal" | "discreet";
  /** Fired when the component the user enters a new character (i.e. release keyboard key). */
  onValueChange?: (value: string) => void;
  /** Provide your custom validation function. Called whenever the value changes. Return either an error message (non-empty message indicates error), a boolean value (false indicates error, without a message), or an object containing both. */
  validator?: (
    value: null | undefined | string
  ) => string | boolean | CustomValidationResult;
  /** Provide custom validation result totally asynchronously. Return either an error message (non-empty message indicates error), a boolean value (false indicates error, without a message), or an object containing both. Mostly same as 'validator' above, but the value is provided directly as a prop. Note: this makes the above 'validator' function prop mostly useless, and it will be removed at some point. */
  validationResult?: string | boolean | CustomValidationResult | null;
  /** Custom icon, shown on the right end of the input field */
  icon?: string;
  /** Slightly dimmed placeholder text. */
  placeholder?: null | string;
  /** Show green borders when the value is valid. Note, this in itself does not set the error state, but just customizes how the error state is shown. */
  showValidationBarWhenValid: boolean;
  /** Show green valid-icon when the value is valid. Note, this in itself does not set the error state, but just customizes how the error state is shown. */
  showValidationIconWhenValid: boolean;
  /** Show red borders when the value is invalid. Note, this in itself does not set the error state, but just customizes how the error state is shown. */
  showValidationBarWhenInvalid: boolean;
  /** Show red invalid-icon when the value is invalid. Note, this in itself does not set the error state, but just customizes how the error state is shown. */
  showValidationIconWhenInvalid: boolean;
  /** Dim the whole control and prevent input. */
  disabled?: boolean;
  /** The current value of the input field. Note, you **must** provide this, as the component is controlled. */
  value?: null | undefined | string;
  /** Additional, standard HTML input-element attributes passed to the input field. */
  inputAttributes?: { [index: string]: any };
  /** Fore onValueChange-event with empty string when user hits Escape key on keyboard. */
  clearOnEscape?: boolean;
  /** Show the cross-icon on the right end of the input field (but before possible custom icon), clicking of which causes onValueChange-event to be fired with empty string. */
  showClearIcon?: boolean;
  /** When true, show blue border around the input field. Note, the component does not keep track of changed status, and this merely draws the border. Keep track of value changes in application code. Also, this is not applicable to 'textarea'. */
  indicateChanged?: boolean;
  /** Run the validation always after the user enter a new character (i.e. release keyboard key). If false, run the validation only when the element loses focus. */
  instantValidation?: boolean;
  /** Allow user resize the element. Only valid for textarea. */
  resizable: boolean;
  /** If given, the input field stops accepting characters after this limit is reached. Note: the field *will* show more characters than the limit, if those are coming from outside the component. */
  maxLength?: number;
  /**
   * Custom keyboard handler to act on specific keys (most probably on Escape or Return).
   * Return false to cancel all further event handling, including the component internal handlers.
   * @param {KeyboardEvent} key The original keyboard event.
   */
  onKeyUp?: (
    key: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  /** Separate click handler for the icon. Useful for example in search fields, where the icon could be a magnifier and clicking it causes the search to be performed. */
  onIconClick?: (text: null | undefined | string) => void;
  /** Fired when the inner input/textarea -element receives focus. */
  onGotFocus?: (
    e:
      | React.FocusEvent<HTMLInputElement>
      | React.FocusEvent<HTMLTextAreaElement>
  ) => void;
  /** Fired when the inner input/textarea -element loses focus (i.e. blurs). */
  onLostFocus?: (
    e:
      | React.FocusEvent<HTMLInputElement>
      | React.FocusEvent<HTMLTextAreaElement>
  ) => void;
  /** Prevent any children. */
  children?: never;
}

export interface InputState {
  focused: boolean;
}

export interface CustomValidationResult {
  valid: boolean;
  text?: string;
}

/**
 * Generic value input component, building on top of the standard HTML input-element. Supports various value validation
 * mechanisms (instant validation, on-lost-focus-validation) and different visual cues to signal the state of the input
 * to the user, such as is-required (red bottom border when empty), has-changed (blue border), etc. Validation mechanism
 * can also be customized with custom message and to either show/hide the icon plus possibly empty message, and the red
 * overall border when in invalid state. Can be cleared through icon-click or via keyboard.
 *
 * **Notes:**
 * - Component interface is quite large and complicated, due to supporting multiple styles and validation modes.
 * - This component is **controlled**, meaning that you **must** implement the state handling in the parent component.
 */
export class Input extends React.Component<InputProps, InputState> {
  static defaultProps = {
    required: false,
    type: "normal",
    disabled: false,
    icon: "",
    placeholder: null,
    showValidationBarWhenValid: false,
    showValidationIconWhenValid: false,
    showValidationBarWhenInvalid: true,
    showValidationIconWhenInvalid: false,
    value: null,
    inputAttributes: {},
    clearOnEscape: true,
    showClearIcon: false,
    indicateChanged: false,
    instantValidation: false,
    resizable: true,
  };

  readonly state = {
    focused: false,
  };

  private inputRef: React.RefObject<HTMLInputElement | HTMLTextAreaElement>;

  constructor(props: InputProps) {
    super(props);
    this.inputRef = React.createRef();
    this._handleValueChange = this._handleValueChange.bind(this);
    this._handleFocus = this._handleFocus.bind(this);
    this._handleBlur = this._handleBlur.bind(this);
    this._handleKeyPress = this._handleKeyPress.bind(this);
    this._handleIconClick = this._handleIconClick.bind(this);
    this._handleClearIconClick = this._handleClearIconClick.bind(this);
  }

  public focus(): void {
    if (this.inputRef && this.inputRef.current) {
      this.inputRef.current.focus();
    }
  }

  public render(): JSX.Element {
    const {
      className,
      type,
      value,
      label,
      description,
      icon,
      disabled,
      required,
      placeholder,
      dataType,
      showValidationBarWhenValid,
      showValidationIconWhenValid,
      showValidationBarWhenInvalid,
      showValidationIconWhenInvalid,
      inputAttributes,
      resizable,
      maxLength,
      showClearIcon,
      indicateChanged,
      instantValidation,
    } = this.props;
    const { focused } = this.state;

    if (type !== "normal" && type !== "discreet") {
      throw new Error(
        "Invalid input type. Please use one of [normal, discreet]. You used: " +
          type
      );
    }

    const styleType = (styles as any)[type];
    const validationResult = this._validate(value, this.inputRef.current);
    const validationIcon = validationResult.valid
      ? validationIconForValid
      : validationIconForInvalid;

    let showValidationBar =
      (validationResult.valid && showValidationBarWhenValid) ||
      (!validationResult.valid &&
        showValidationBarWhenInvalid &&
        (dataType !== "textarea" || (dataType === "textarea" && !resizable)));
    let showValidationIcon =
      (validationResult.valid && showValidationIconWhenValid) ||
      (!validationResult.valid && showValidationIconWhenInvalid);
    let showHasChangedBar =
      (dataType !== "textarea" || (dataType === "textarea" && !resizable)) &&
      indicateChanged;

    if (disabled || (!instantValidation && focused)) {
      showValidationBar = false;
      showValidationIcon = false;
      showHasChangedBar = false;
    }

    const shouldShowClearIcon = showClearIcon && !!value && !disabled;
    let maybeMaxLength = {};
    if (maxLength && maxLength > -1) {
      maybeMaxLength = { maxLength };
    }
    return (
      <ThemeContext.Consumer>
        {(theme) => (
          <span
            {...getHtmlAttributes(this.props)}
            className={classNames(
              styles.root,
              (styles as any)[theme],
              { [styles.isTextarea]: dataType === "textarea" },
              styleType,
              className,
              {
                [styles.disabled]: disabled,
                [styles.hasChanged]: showHasChangedBar,
                [styles.isValid]: validationResult.valid,
                [styles.isInvalid]: !validationResult.valid,
                [styles.showValidationResult]: showValidationBar,
                [styles.hasIcon]: !!icon,
                [styles.hasClearIcon]: shouldShowClearIcon,
              }
            )}
          >
            <span className={classNames(styles.label)}>
              {label}
              {required && <span className={styles.requiredAsterisk}>*</span>}
            </span>
            <span
              className={classNames(styles.inputWrapper, {
                [styles.hasFixedSize]:
                  dataType !== "textarea" ||
                  (dataType === "textarea" && !resizable),
              })}
            >
              {dataType !== "textarea" && (
                <input
                  {...maybeMaxLength}
                  className={styles.inputElement}
                  value={
                    (value === null || value === undefined ? "" : value) as any
                  }
                  disabled={disabled}
                  ref={this.inputRef as any}
                  placeholder={placeholder || ""}
                  type={dataType}
                  onChange={this._handleValueChange}
                  onKeyUp={this._handleKeyPress}
                  onFocus={this._handleFocus}
                  onBlur={this._handleBlur}
                  {...inputAttributes}
                />
              )}
              {dataType === "textarea" && (
                <textarea
                  {...maybeMaxLength}
                  className={classNames(styles.inputElement, {
                    [styles.resizable]: resizable,
                  })}
                  value={
                    (value === null || value === undefined ? "" : value) as any
                  }
                  disabled={disabled}
                  ref={this.inputRef as any}
                  placeholder={placeholder || ""}
                  onChange={this._handleValueChange}
                  onKeyUp={this._handleKeyPress}
                  onFocus={this._handleFocus}
                  onBlur={this._handleBlur}
                  {...inputAttributes}
                />
              )}
              {dataType !== "textarea" && (
                <span className={classNames(styles.iconContainer)}>
                  {shouldShowClearIcon && (
                    <span
                      className={styles.clearIcon}
                      onClick={this._handleClearIconClick}
                    >
                      <CloseIcon sizeClass="small" />
                    </span>
                  )}
                  {shouldShowClearIcon && icon && (
                    <span className={styles.iconSeparator} />
                  )}
                  {icon && (
                    <span
                      className={styles.iconWrapper}
                      onClick={this._handleIconClick}
                    >
                      <Icon name={icon} sizeClass="small" />
                    </span>
                  )}
                </span>
              )}
            </span>
            <div
              className={classNames(styles.validationMessageContainer, {
                [styles.visible]: showValidationIcon,
              })}
            >
              <span
                className={cx(
                  styles.validationIconWrapper,
                  validationResult.valid
                    ? styles.validationIconWrapper
                    : styles.validationIconWrapperError
                )}
              >
                <Icon name={validationIcon} sizeClass="small" />
              </span>
              <span className={styles.validationMessage}>
                {validationResult.text}
              </span>
            </div>
            {description && (
              <div className={styles.description}>{description}</div>
            )}
          </span>
        )}
      </ThemeContext.Consumer>
    );
  }

  private _validate(
    value: any,
    inputElement: null | HTMLInputElement | HTMLTextAreaElement
  ): CustomValidationResult {
    if (
      this.props.validationResult !== undefined &&
      this.props.validationResult !== null
    ) {
      if (typeof this.props.validationResult === "string") {
        return {
          valid: this.props.validationResult.length === 0,
          text: this.props.validationResult,
        };
      } else if (typeof this.props.validationResult === "boolean") {
        return { valid: this.props.validationResult };
      } else {
        return this.props.validationResult;
      }
    } else if (this.props.validator) {
      const res = this.props.validator(value);
      if (typeof res === "string") {
        return { valid: false, text: value };
      } else if (typeof res === "boolean") {
        return { valid: value };
      } else if (res.valid !== undefined) {
        return { valid: res.valid, text: res.text };
      } else {
        return { valid: false };
      }
    }

    switch (this.props.dataType) {
      case "text":
      case "color":
      case "date":
      case "email":
      case "month":
      case "number":
      case "password":
      case "search":
      case "tel":
      case "time":
      case "url":
      case "week":
        // Use the builtin HTML validation result only when there is some value
        // (otherwise it would mark empty 'email' as invalid, for example).
        if (inputElement && typeof value === "string" && value.length > 0) {
          return { valid: inputElement.validity.valid };
        } else {
          return { valid: true };
        }
      default:
        return { valid: true };
    }
  }

  private _handleValueChange(evt: any) {
    evt.preventDefault();
    if (this.props.onValueChange) {
      this.props.onValueChange(evt.currentTarget.value);
    }
  }

  private _handleKeyPress(
    evt: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    if (this.props.onKeyUp) {
      this.props.onKeyUp(evt);
    }
    if (evt.keyCode === ESCAPE_KEY) {
      this.clearValue();
    }
  }

  private _handleClearIconClick() {
    this.clearValue();
  }

  private clearValue() {
    if (this.props.onValueChange) {
      this.props.onValueChange("");
    }
  }

  private _handleIconClick() {
    if (this.props.onIconClick) {
      this.props.onIconClick(this.props.value);
    }
  }

  private _handleFocus(
    e:
      | React.FocusEvent<HTMLInputElement>
      | React.FocusEvent<HTMLTextAreaElement>
  ) {
    this.setState({ focused: true });
    if (this.props.onGotFocus) {
      this.props.onGotFocus(e);
    }
  }

  private _handleBlur(
    e:
      | React.FocusEvent<HTMLInputElement>
      | React.FocusEvent<HTMLTextAreaElement>
  ) {
    this.setState({ focused: false });
    if (this.props.onLostFocus) {
      this.props.onLostFocus(e);
    }
  }
}

export default Input;
