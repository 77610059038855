// STYLING
import styles from "./Tags.module.scss";
import cx from "classnames";

interface Tag {
  name: string;
}

interface Props {
  tags: Tag[];
  maxVisibleTags?: number;
  className?: string;
}

export const Tags = ({ tags, maxVisibleTags, className }: Props) => {
  const getAdditionalTags = (tags: Tag[]): string => {
    const names = tags.map((t) => t.name);
    return names.join("\n");
  };

  return (
    <ul className={cx(styles.tagList, className)}>
      {tags
        .slice(0, maxVisibleTags ? maxVisibleTags : tags.length)
        .map((tag) => (
          <li title={tag.name} key={tag.name}>
            {tag.name}
          </li>
        ))}
      {maxVisibleTags && maxVisibleTags < tags.length ? (
        <li key="more" title={getAdditionalTags(tags)}>
          +{tags.length - maxVisibleTags}
        </li>
      ) : null}
    </ul>
  );
};
