interface HasEmptyValuesObject {
    [key: string]: string | null | undefined
}

export default function hasEmptyValues(obj: HasEmptyValuesObject | any) {
    for (var key in obj) {
        if ((obj[key] !== null) && (obj[key] !== ""))
            return false;
    }
    return true;
}