// UTILS
import capitalize from "utils/capitalize";
import useKeyPress from "libs/@abbrda/abb-common-ux-react/internalUtils/useKeyPress";

// I18NEXT
import { useTranslation } from "react-i18next";

// REACT
import { useEffect, useState } from "react";

// NAVIGATION
import { useParams } from "react-router-dom";

// APOLLO
import { PureQueryOptions, useMutation, useQuery } from "@apollo/client";
import { getAllGroupData, getGroup } from "gql/identity/queries/groupQueries";
import {
  addIdentitiesToGroup,
  removeIdentitiesFromGroup,
  updateGroup,
} from "gql/identity/mutations/groupMutations";
import { getAllUsers } from "gql/identity/queries/userQueries";
import { addGroup } from "gql/identity/mutations/groupMutations";

// FORMIK
import { useFormik } from "formik";
import yup from "libs/validation/yup";

// TYPES
import { Group as GroupType } from "types/identity/Group";
import { User } from "types/identity/User";

// COMPONENTS
import { ThreeStateValue } from "@abb/abb-common-ux-react";
import { QueryResultRenderer } from "components/QueryResultRenderer/QueryResultRenderer";
import { GlobalNotificationService } from "services/GlobalNotification/GlobalNotificationService";
import { GlobalNotificationType } from "services/GlobalNotification/GlobalNotificationType";
import AbbSelect from "components/Select/Select";
import { Input } from "libs/@abbrda/abb-common-ux-react/src/components/Input";

// STYLING
import styles from "./Group.module.scss";
import FormSkeleton from "components/Skeletons/commonux/Form";
import { filterInternalGroups } from "utils/filterInternal";
import { Button, SlidePanel } from "@abb/common-ux";
import { usePanel } from "components/Panel/PanelContext";
import { useConfig } from "components/Config/ConfigProvider";
import { ApolloContexts } from "services/ApolloService";

interface GroupForm {
  name: string;
  description: string;
  users: any[];
}

interface Props {
  groupId?: string;
}

export const Group = ({ groupId = "" }: Props) => {
  const [submitting, setSubmitting] = useState(false);
  const [options, setOptions] = useState<any[]>([]);
  const [isFormInitialized, setIsFormInitialized] = useState(false);

  const { t } = useTranslation();

  const { isOpen, closePanel } = usePanel();
  const { config } = useConfig();
  const { context } = ApolloContexts.Identity;
  let params = useParams<{ type?: string; id: string }>();
  const refetchQueries: (string | PureQueryOptions)[] = [
    "getAllGroups",
    "getAllGroupData",
    "getAllUsers",
  ];
  if (params.id && params.type === "groups") {
    refetchQueries.push({ query: getGroup, variables: { id: params.id } });
  }
  const [addGroupMutation] = useMutation(addGroup, {
    refetchQueries,
    context
  });
  const [updateGroupMutation] = useMutation(updateGroup, {
    refetchQueries,
    context
  });
  const [addIdentitiesToGroupMutation] = useMutation(addIdentitiesToGroup, {
    refetchQueries,
    context
  });
  const [removeIdentitiesFromGroupMutation] = useMutation(
    removeIdentitiesFromGroup,
    {
      refetchQueries,
      context
    }
  );
  const groupsQueryResult = useQuery(getAllGroupData, {
    fetchPolicy: "cache-and-network",
    context
  });

  const groups: GroupType[] =
    (groupsQueryResult.data &&
      filterInternalGroups(groupsQueryResult.data.groupsByName)) ||
    [];

  const usersQuery = useQuery(getAllUsers, {
    fetchPolicy: "cache-and-network",
    context
  });
  const { data: usersData } = usersQuery;

  const allUsers: User[] = (usersData && usersData.usersByNameAndEmail) || [];

  const onSubmit = async () => {
    setSubmitting(true);
    if (!groupId) {
      try {
        const result = await addGroupMutation({
          variables: {
            input: {
              name: values.name,
              description: values.description,
            },
          },
        });
        const newGroup: GroupType = result.data?.addGroup;
        const addedUsers = values.users.map((u) => u.value);
        if (addedUsers.length > 0) {
          await addIdentitiesToGroupMutation({
            variables: {
              input: {
                identityIds: addedUsers,
                groupId: newGroup.id,
              },
            },
          });
        }
        GlobalNotificationService.publishNotification({
          text: capitalize(
            t(`app:screen.user.panel.group.create.form.notifications.success`)
          ),
          type: GlobalNotificationType.Success,
        });
        closePanel();
      } catch (e: any) {
        GlobalNotificationService.publishNotification({
          text:
            e.message ||
            capitalize(
              t(`app:screen.user.panel.group.create.form.notifications.error`)
            ),
          type: GlobalNotificationType.Alarm,
        });
        setSubmitting(false);
      }
    } else {
      const currentGroup = groups.find((g) => g.id === groupId);
      if (currentGroup) {
        try {
          if (
            currentGroup.label !== values.name ||
            currentGroup.description !== values.description
          ) {
            await updateGroupMutation({
              variables: {
                input: {
                  id: groupId,
                  name: values.name,
                  label: values.name,
                  description: values.description,
                },
              },
            });
          }

          const originalUsers = allUsers.filter((u) =>
            currentGroup.identities?.some((i) => i.id === u.identity.id)
          );
          const addedUsers = values.users
            .filter(
              (u) => !originalUsers.some((ou) => ou.identity.id === u.value)
            )
            .map((u) => u.value);
          const removedUsersIdentities = originalUsers
            .filter(
              (og) => !values.users.some((u) => og.identity.id === u.value)
            )
            .map((u) => u.identity.id);
          if (addedUsers.length > 0) {
            await addIdentitiesToGroupMutation({
              variables: {
                input: {
                  identityIds: addedUsers,
                  groupId: currentGroup.id,
                },
              },
            });
          }
          if (removedUsersIdentities.length > 0) {
            await removeIdentitiesFromGroupMutation({
              variables: {
                input: {
                  identityIds: removedUsersIdentities,
                  groupId: currentGroup.id,
                },
              },
            });
          }
          GlobalNotificationService.publishNotification({
            text: capitalize(
              t(`app:screen.user.panel.group.edit.form.notifications.success`)
            ),
            type: GlobalNotificationType.Success,
          });
          closePanel();
        } catch (e: any) {
          GlobalNotificationService.publishNotification({
            text:
              e.message ||
              capitalize(
                t(`app:screen.user.panel.group.edit.form.notifications.error`)
              ),
            type: GlobalNotificationType.Alarm,
          });
          setSubmitting(false);
        }
      }
    }
  };

  // FORM : FORMIK
  const {
    values,
    errors,
    touched,
    setFieldTouched,
    handleSubmit,
    setFieldValue,
    setValues,
    isValid,
    validateForm,
  } = useFormik<GroupForm>({
    initialValues: {
      name: "",
      description: "",
      users: [],
    },
    validationSchema: yup.object().shape({
      name: yup
        .string()
        .required(capitalize(t("mandatoryField")))
        .max(50, t("yup:errors.tooLong"))
        .test(
          "ends-with-internal",
          `"-internal" is a reserved suffix and cannot be used in a group name.`,
          (name) =>
            typeof name === "string" && !(name as string).endsWith("-internal")
        ),
      description: yup
        .string()
        .required(capitalize(t("mandatoryField")))
        .max(250, t("yup:errors.tooLong")),
      users: yup.array().optional(),
    }),
    onSubmit,
  });

  useEffect(() => {
    if (
      usersData &&
      usersData.usersByNameAndEmail.length &&
      groupsQueryResult.data &&
      groupsQueryResult.data.groupsByName &&
      !isFormInitialized
    ) {
      const groups: GroupType[] =
        (groupsQueryResult.data &&
          filterInternalGroups(groupsQueryResult.data.groupsByName)) ||
        [];
      const allUsers: User[] =
        (usersData && usersData.usersByNameAndEmail) || [];
      const currentGroup = groups.find((g) => g.id === groupId);
      let groupUsers: any[] = [];
      if (currentGroup) {
        const userIdentities = currentGroup.identities?.map((i) => i.id);
        groupUsers = allUsers
          .filter((u) => userIdentities?.includes(u.identity.id))
          .map((u) => ({
            value: u.identity.id,
            label: `${u.firstName} ${u.lastName}`,
            state: ThreeStateValue.Checked,
          }));
      }

      setValues({
        name: currentGroup ? currentGroup?.label : "",
        description: currentGroup ? currentGroup?.description ?? "" : "",
        users: groupUsers,
      });
      setIsFormInitialized(true);
    }
  }, [usersData, setValues, groupId, groupsQueryResult, isFormInitialized]);

  useEffect(() => {
    const allUsers: User[] = (usersData && usersData.usersByNameAndEmail) || [];

    setOptions(
      allUsers
        .filter(
          (user: User) =>
            !user.identity.assignedGroups.some(
              (group: GroupType) => group.name === config.admin.groupName
            )
        )
        .map((u: User) => ({
          label: u.email,
          value: u.identity.id,
          state: ThreeStateValue.Unchecked,
        }))
    );
  }, [usersData, setOptions, config.admin.groupName]);

  useEffect(() => {
    validateForm();
  }, [validateForm]);

  const handleValidate = (value: keyof GroupForm): string => {
    return errors[value] && touched[value] ? (errors[value] as string) : "";
  };

  const usersText = `${values.users.length} ${capitalize(
    t("app:screen.user.panel.group.create.form.others.userSelected", {
      count: values.users.length,
    })
  )}`;

  useKeyPress("Enter", () => {
    handleSubmit();
  });

  return (
    <div>
      <SlidePanel
        title={capitalize(
          groupId
            ? t("app:screen.user.panel.group.edit.form.title")
            : t("app:screen.user.panel.group.create.form.title")
        )}
        isOpen={isOpen}
        closePanel={closePanel}
        bottomActions={
          <>
            <Button
              type="discreet-black"
              text={t("cancel")}
              onPress={closePanel}
            />
            <Button
              disabled={!isValid || submitting}
              type="primary-blue"
              text={capitalize(
                groupId
                  ? t("app:screen.user.panel.group.edit.form.buttons.save")
                  : t("app:screen.user.panel.group.create.form.buttons.add")
              )}
              onPress={() => handleSubmit()}
            />
          </>
        }
      >
        <div className={styles.form}>
          <Input
            label={capitalize(
              t("app:screen.user.panel.group.create.form.fields.name")
            )}
            dataType={"text"}
            onValueChange={(v) => setFieldValue("name", v)}
            value={values.name}
            onLostFocus={() => setFieldTouched("name")}
            showValidationBarWhenInvalid
            showValidationIconWhenInvalid
            validationResult={handleValidate("name")}
            maxLength={50}
            required
          />
          <Input
            label={capitalize(
              t("app:screen.user.panel.group.create.form.fields.description")
            )}
            className={styles.description}
            dataType={"textarea"}
            onValueChange={(v) => setFieldValue("description", v)}
            value={values.description}
            onLostFocus={() => setFieldTouched("description")}
            resizable={false}
            showValidationBarWhenInvalid
            showValidationIconWhenInvalid
            validationResult={handleValidate("description")}
            maxLength={250}
            required
          />

          <QueryResultRenderer
            queryResults={[
              { queryResult: usersQuery, dataKey: `usersByNameAndEmail` },
            ]}
            skeleton={<FormSkeleton />}
          >
            {() => {
              return (
                <AbbSelect
                  values={options}
                  title={capitalize(
                    t("app:screen.user.panel.group.create.form.fields.users")
                  )}
                  onValueChange={(v) => {
                    setFieldValue("users", v);
                  }}
                  value={values.users}
                  placeholder={capitalize(
                    t(
                      "app:screen.user.panel.group.create.form.placeholders.users"
                    )
                  )}
                  errorMsg={""}
                  onBlur={() => setFieldTouched("users")}
                  stylesSelectContainer={styles.selectContainer}
                  customValue={usersText}
                  className={styles.dropdown}
                />
              );
            }}
          </QueryResultRenderer>
        </div>
      </SlidePanel>
    </div>
  );
};
