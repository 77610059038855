import { pki, util } from "node-forge";

export const MAX_ENCRYPT_LENGTH = 214;

/**
 * Encrypts using the RSA and the given public key.
 * @param {string} publicKey The public key to use for encryption
 * @param {string} plaintext The text to encrypt
 * @return {string} Base64 encrypted text
 */
export default function encryptWithPublicKey(
  plaintext: string,
  publicKey: string
) {
  if (plaintext.length > MAX_ENCRYPT_LENGTH) {
    throw new Error(
      `Text is too long to encrypt: max length is ${MAX_ENCRYPT_LENGTH}`
    );
  }

  const publicKeyInstance = pki.publicKeyFromPem(publicKey);
  const encrypted = util.encode64(
    publicKeyInstance.encrypt(plaintext, "RSA-OAEP")
  );
  return encrypted as string;
}
