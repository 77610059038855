import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import styles from "./Position.module.scss";
import classNames from "classnames";
import { Step } from ".";
import { useTranslation } from "react-i18next";
import { Button } from "@abb/common-ux";

interface WizardPositionProps<T> {
  steps: Step<T>[];
  showableSteps: Step<T>[];
  activeStepId: number;
  onClickOnStep: (step: number) => void;
  onClickOnBack: () => void;
  values: any;
  disableNextButton?: boolean;
  isEdit?: boolean;
}

export const WizardPosition = <T,>({
  steps,
  isEdit = false,
  showableSteps,
  disableNextButton,
  values,
  activeStepId,
  onClickOnStep,
  onClickOnBack,
}: WizardPositionProps<T>) => {
  const { t } = useTranslation();
  const dif = useRef({ jump: 0, val: activeStepId });
  const [disabled, setDisabled] = useState(showableSteps.map(() => true));
  useLayoutEffect(() => {
    if (activeStepId !== dif.current.val) {
      dif.current = { jump: activeStepId - dif.current.val, val: activeStepId };
    }
  }, [activeStepId]);
  const stepTime = dif.current.jump ? 0.25 / Math.abs(dif.current.jump) : 0.25;
  useEffect(() => {
    validateDisabledSteps();
    async function validateDisabledSteps() {
      let stepsValidation = showableSteps.map((step, idx) => {
        let validation = Promise.resolve(true);
        if (
          !!showableSteps[idx].disableNextButtonOnInvalidForm &&
          showableSteps[idx].validationSchema
        ) {
          validation = showableSteps[idx].validationSchema!.isValid(values);
        }
        return validation;
      });
      let stepsValidationResult = await Promise.all(stepsValidation);
      setDisabled(
        showableSteps.map((step, stepIdx) => {
          const currentStepIndex = steps.findIndex((s) => s.id === step.id);
          const anyPreviousStepIsNotValid = !stepsValidationResult.reduce(
            (state, val, validationIdx) =>
              validationIdx < stepIdx ? val && state : state,
            true
          );
          return (
            currentStepIndex > activeStepId &&
            (disableNextButton || anyPreviousStepIsNotValid)
          );
        })
      );
    }
  }, [steps, showableSteps, values, activeStepId, disableNextButton]);
  return (
    <div className={styles.navigation}>
      <div className={styles.line}>
        <ol>
          {showableSteps.map((step, idx) => {
            // As steps and showableSteps can differ index of both collection may differ for the same step.
            // so we use the steps var (all steps collection) as source of truth.
            // That's why here we find the current showable step index from the full collection, as activeStep comes from the full colection also
            const currentStepIndex = steps.findIndex((s) => s.id === step.id);
            return (
              <li
                key={step.id}
                className={classNames({
                  [styles.active]: activeStepId === currentStepIndex,
                  [styles.completed]: activeStepId > currentStepIndex,
                })}
              >
                <button
                  className={classNames(styles.link, {
                    [styles.activeLink]: activeStepId === currentStepIndex,
                  })}
                  disabled={disabled[idx]}
                  style={
                    disabled[idx]
                      ? { cursor: "not-allowed" }
                      : { cursor: "pointer" }
                  }
                  onClick={() => onClickOnStep(currentStepIndex)}
                >
                  {t(`app:screen.structure.wizard.steps.${step.id}`)}
                </button>
                <div className={styles.baseLine} />
                <div
                  className={styles.activeLine}
                  style={{
                    transitionDuration: `${stepTime}s`,
                    transitionDelay: `${
                      Math.abs(
                        -currentStepIndex + dif.current.val + dif.current.jump
                      ) * stepTime
                    }s`,
                  }}
                />
              </li>
            );
          })}
        </ol>
      </div>
      <Button
        onPress={onClickOnBack}
        type="discreet-black"
        size="large"
        icon="close"
      />
    </div>
  );
};
