import { useContext, useEffect, useState } from "react";

// UTILS
import capitalize from "utils/capitalize";

// I18NEXT
import { useTranslation } from "react-i18next";

// FORMIK
import { useFormik } from "formik";
import yup from "libs/validation/yup";

// COMPONENTS
import { Button } from "@abb/abb-common-ux-react";
import { GlobalNotificationService } from "services/GlobalNotification/GlobalNotificationService";
import { GlobalNotificationType } from "services/GlobalNotification/GlobalNotificationType";

// STYLING
import styles from "./MoveAssets.module.scss";
import { StructureTreeView } from "components/StructureTree/StructureTreeView";
import { AssetsGridContext } from "./Structure";
import { useMutation } from "@apollo/client";
import { updateAssetLocation } from "gql/storageApi/mutations/assetMutations";
import { useStructureTree } from "components/StructureTree/structureHooks";
import { ApolloContexts } from "services/ApolloService";

interface Props {
  assets: string[];
  close?: () => void;
}

interface MoveAssetsForm {
  location: string;
}

const MoveAssets = ({ assets, close = () => {} }: Props) => {
  // STATE
  const [submitting, setSubmitting] = useState(false);

  // I18NEXT
  const { t } = useTranslation();

  // CONTEXT
  const { setSelectedAssets } = useContext(AssetsGridContext);

  // MUTATIONS
  const [updateAssetLocationsMutation] = useMutation(updateAssetLocation, {
    refetchQueries: ["assetsDatagrid"],
    context: ApolloContexts.Hasura.context,
  });
  const { tree } = useStructureTree();

  const onSubmit = async () => {
    try {
      setSubmitting(true);
      updateAssetLocationsMutation({
        variables: {
          assetids: assets,
          structureid: values.location,
        },
      });
      GlobalNotificationService.publishNotification({
        text: capitalize(
          t(
            `app:screen.structure.header.actions.moveBulk.notifications.success`
          )
        ),
        type: GlobalNotificationType.Success,
      });
      setSelectedAssets([]);
      close();
    } catch (e: any) {
      GlobalNotificationService.publishNotification({
        text:
          e.message ||
          capitalize(
            t(
              `app:screen.structure.header.actions.moveBulk.notifications.error`
            )
          ),
        type: GlobalNotificationType.Alarm,
      });
      setSubmitting(false);
    }
  };

  // FORM : FORMIK
  const {
    values,
    handleSubmit,
    setFieldValue,
    validateForm,
    isValid,
  } = useFormik<MoveAssetsForm>({
    initialValues: {
      location: "",
    },
    validationSchema: yup.object().shape({
      location: yup.string().required(capitalize(t("mandatoryField"))),
    }),
    onSubmit,
  });

  useEffect(() => {
    validateForm();
  }, [validateForm]);

  return (
    <div className={styles.container}>
      <div className={styles.floatingForm}>
        <div className={styles.innerContainer}>
          <div className={styles.title}>
            <span>
              {capitalize(
                t("app:screen.structure.header.actions.moveBulk.popup.title")
              )}
            </span>
          </div>
          <div className={styles.treeContainer}>
            <span>
              {capitalize(
                t(
                  "app:screen.structure.header.actions.moveBulk.popup.fields.location.title"
                )
              )}
              <span className={styles.required}>*</span>
            </span>
            <div>
              <StructureTreeView
                selectedId={values.location}
                onlyView={true}
                onClickOnItem={(location) =>
                  setFieldValue("location", location.id)
                }
                items={tree}
              />
            </div>
          </div>
          <div className={styles.buttons}>
            <div className={styles.secondary}>
              <Button
                type="ghost"
                text={t("cancel")}
                className={styles.button}
                onClick={() => close()}
              />
            </div>
            <div className={styles.primary}>
              <Button
                disabled={!isValid}
                type="primary-blue"
                text={capitalize(
                  t("app:screen.user.addToGroup.form.buttons.save")
                )}
                onClick={() => handleSubmit()}
                className={styles.button}
                isLoading={submitting}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MoveAssets;
