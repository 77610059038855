import { Icon } from '@abb/abb-common-ux-react';
import React, { useState } from 'react';
import styles from './PasswordText.module.scss';

interface Props {
    password: string;
}

export const PasswordText = ({ password }: Props) => {
    const [isVisible, setIsVisible] = useState(false);
    return (
        <span className={styles.passwordText}>
            {isVisible ? password : ''.padStart(password.length, `•`)}
            <Icon onClick={() => setIsVisible(!isVisible)} name={isVisible ? `abb/hide` : `abb/view`}></Icon>
        </span>
    )
}