import React, { useCallback, useState } from "react";
import { useHistory } from "react-router-dom";
import { FormikProps, FormikValues, useFormik } from "formik";
import { useTranslation } from "react-i18next";

import isEmpty from "utils/isEmpty";

import { StepProps } from "views/Structure/wizards/DeviceWizard";
import { WizardBottomNavigation } from "./BottomNavigation";
import { WizardPosition } from "./Position";
import styles from "./Wizard.module.scss";
import hasEmptyValues from "utils/hasEmptyValues";
import { Schema } from "yup";
import { useEffect } from "react";
import { ViewLayoutContent } from "@abb/common-ux";
import { Footer } from "components/Footer/Footer";

// Context
import { observer } from "mobx-react";
import { Modal } from "components/Modal/Modal";
import { useModal } from "components/Modal/ModalContext";

export type Step<T> = {
  id: string;
  component: React.FunctionComponent<StepProps>;
  validationSchema?: Schema;
  disabled?: boolean;
  disableNextButtonOnInvalidForm?: boolean;
  showStepIf?: (formik: FormikProps<T>) => boolean;
};

export interface WizardProps<T> {
  children?: React.ReactNode;
  steps: Step<T>[];
  initialValues: T;
  onConfirm: (values: T) => void;
  debug?: boolean;
  isEdit?: boolean;
  isSubmitting?: boolean;
  clickableSteps?: boolean;
}

export const Wizard = observer(
  <T extends FormikValues>({
    steps,
    onConfirm,
    initialValues,
    debug = false,
    isEdit,
    isSubmitting = false,
    clickableSteps = false,
  }: WizardProps<T>) => {
    const { t } = useTranslation();
    const history = useHistory();
    const { setModal, closeModal } = useModal();

    const [activeStep, setActiveStep] = useState(0);
    const totalSteps = steps.length;
    const isLastStep = activeStep === totalSteps - 1;

    const WizardStep = steps[activeStep];
    const WizardStepComponent = WizardStep.component;

    const formik = useFormik<T>({
      initialValues,
      validationSchema: WizardStep.validationSchema,
      onSubmit: () => {
        onConfirm(formik.values);
      },
    });

    const isShowable = (step: Step<T>) => {
      if (step.showStepIf) {
        return step.showStepIf(formik);
      }
      return true;
    };

    const showableSteps = steps.filter((step) => isShowable(step));
    const { validateForm } = formik;
    useEffect(() => {
      validateForm();
    }, [activeStep, validateForm]);

    const disableNextButton =
      !!WizardStep.disableNextButtonOnInvalidForm && !formik.isValid;

    const nextStep = () => {
      const showableSteps = steps.filter((step) => isShowable(step));
      const currentStepIndex = showableSteps.indexOf(steps[activeStep]);
      const nextShowableStep = showableSteps[currentStepIndex + 1];
      const nextActiveIndex = steps.indexOf(nextShowableStep);
      setActiveStep(nextActiveIndex);
    };

    const prevStep = () => {
      const showableSteps = steps.filter((step) => isShowable(step));
      const currentStepIndex = showableSteps.indexOf(steps[activeStep]);
      const prevShowableStep = showableSteps[currentStepIndex - 1];
      const nextActiveIndex = steps.indexOf(prevShowableStep);
      setActiveStep(nextActiveIndex);
    };

    const handleOnSubmit = async () => {
      if (initialValues) {
        formik.validateForm().then((errors) => {
          if (isEmpty(errors)) {
            if (isLastStep) {
              return formik.handleSubmit();
            } else {
              return nextStep();
            }
          } else {
            const errorKeys = Object.keys(errors);
            // Force fields with errors to show it in case they were not touched
            errorKeys.forEach((e) => formik.setFieldTouched(e, true));
            console.error(`there were formik validation errors:`, errors);
            return false;
          }
        });
      } else {
        if (isLastStep) {
          return formik.handleSubmit();
        } else {
          return nextStep();
        }
      }
    };

    const handleOnBack = async () => {
      const formikFields = Object.keys(formik.values);
      formikFields.forEach((f) => formik.setFieldTouched(f, false));
      prevStep();
    };

    const handleOnClickOnStep = (step: number) => {
      if (clickableSteps) {
        setActiveStep(step);
      }
    };

    const handleClickBack = useCallback(() => {
      const closeAndRedirectModal = () => {
        closeModal();
        history.push("/structure");
      };

      setModal(
        <Modal
          isOpen
          closeModal={closeModal}
          title={t(`yourChangesMightNotBeSavedTitle`)}
          body={
            <div className={styles.popupText}>
              <p className={styles.text}>{t(`yourChangesMightNotBeSaved`)}</p>
              <p className={styles.text}>{t(`completeTheWholeProcess`)}</p>
            </div>
          }
          footer={{
            footer: {
              leftButton: {
                text: t("yesLeave"),
                type: "discreet-black",
                size: "small",
                onPress: closeAndRedirectModal,
              },
              rightButton: {
                text: t("cancel"),
                type: "primary-blue",
                size: "small",
                onPress: closeModal,
              },
            },
          }}
        />
      );
    }, [closeModal, history, setModal, t]);

    return (
      <div className={styles.main}>
        <WizardPosition<T>
          steps={steps}
          showableSteps={showableSteps}
          isEdit={isEdit}
          activeStepId={activeStep}
          values={formik.values}
          onClickOnStep={handleOnClickOnStep}
          disableNextButton={disableNextButton}
          onClickOnBack={handleClickBack}
        />
        <ViewLayoutContent
          title={t(`app:screen.structure.wizard.steps.${steps[activeStep].id}`)}
        >
          <WizardStepComponent isEdit={isEdit} formik={formik as any} />
          <div>
            {debug ? (
              <div className={styles.debug}>
                <h2>{`${t("wizard")} ${t("debug")}:`}</h2>
                <h4>hasErrors: {(!isEmpty(formik.errors)).toString()}</h4>
                <h4>
                  FORMIK {t("errors")}: {JSON.stringify(formik.errors)}
                </h4>
                <hr />
                <h4>
                  hasEmptyValues: {hasEmptyValues(formik.values).toString()}
                </h4>
                <h4>
                  FORMIK {t("values")}: {JSON.stringify(formik.values)}
                </h4>
              </div>
            ) : null}
          </div>
        </ViewLayoutContent>
        <WizardBottomNavigation
          onClickOnSubmit={handleOnSubmit}
          onClickOnBack={handleOnBack}
          showBackButton={activeStep !== 0}
          showConfirm={isLastStep}
          disableNextButton={disableNextButton || isSubmitting}
        />
        <div>
          <Footer />
        </div>
      </div>
    );
  }
);
export default Wizard;
