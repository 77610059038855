import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import * as Yup from "yup";
import i18n from "libs/i18n";
import { useQuery } from "@apollo/client";
import { getAllGroupData } from "gql/identity/queries/groupQueries";
import { Group } from "types/identity/Group";
import useKeyPress from "libs/@abbrda/abb-common-ux-react/internalUtils/useKeyPress";

import TextInput from "components/FormikCommonUX/Text";
import FormDropdown from "components/FormikCommonUX/Dropdown";
import styles from "./Filters.module.scss";
import { QueryResultRenderer } from "components/QueryResultRenderer/QueryResultRenderer";
import { useParams } from "react-router-dom";

import FormSkeleton from "components/Skeletons/commonux/Form";
import { filterInternalGroups} from "utils/filterInternal";
import { Button, SlidePanel } from "@abb/common-ux";
import { usePanel } from "components/Panel/PanelContext";
import { ApolloContexts } from "services/ApolloService";

const FilterSchema = Yup.object().shape({
  groups: Yup.array().of(Yup.string()),
  email: Yup.string().max(50, i18n.t("yup:errors.tooLong")),
  phone: Yup.string().max(50, i18n.t("yup:errors.tooLong")),
 
});
export interface UserFilter {
  groups?: string[] | [];
  email: string;
  phone: string;
  
}
export const UserFilters = ({
  setFilter,
  filters,
}: {
  setFilter: (filter?: UserFilter) => void;
  filters?: UserFilter;
}) => {
  const { t } = useTranslation();
  const { closePanel, isOpen } = usePanel();
  const { type, id } = useParams<{ type?: string; id?: string }>();

  const groupsQueryResult = useQuery(getAllGroupData, {
    fetchPolicy: "cache-and-network",
    variables: {},
    context: ApolloContexts.Identity.context,
  });

  const formik = useFormik({
    initialValues: filters || {
      groups: [],
      email: "",
      phone: "",
      
    },
    validationSchema: FilterSchema,
    onSubmit: (values: UserFilter) => {
      // same shape as initial values
      setFilter(values);
      closePanel();
    },
  });
  const clearFilters = () => {
    formik.setValues({
      groups: [],
      email: "",
      phone: "",
      
    });
  };

  useKeyPress("Enter", () => {
    formik.submitForm();
  });

  return (
    <SlidePanel
      isOpen={isOpen}
      closePanel={closePanel}
      headerActions={
        <Button
          onPress={clearFilters}
          type="discreet-blue"
          text={t("clearAll")}
          size="small"
        />
      }
      title={t("filters")}
      bottomActions={<Button
        disabled={!formik.dirty}
        type="primary-blue"
        onPress={formik.submitForm}
        text={t("apply")}
      />}
    >
      <form className={styles.form} onSubmit={formik.handleSubmit}>
        {id || !type || type === "org" ? (
          <>
            <QueryResultRenderer
              queryResults={[{ queryResult: groupsQueryResult, dataKey: "groupsByName" }]}
              skeleton={<FormSkeleton />}
            >
              {([groups]) => (
                <FormDropdown
                  name="groups"
                  multiselect
                  selectedValues={!formik.touched.groups ? formik.values.groups : undefined}
                  formik={formik}
                  values={filterInternalGroups(groups)!.map((group: Group) => ({
                    label: group.label,
                    value: group.id,
                  }))}
                />
              )}
            </QueryResultRenderer>
            <TextInput name="email" formik={formik} />
            <TextInput name="phone" formik={formik} />
            
          </>
        ) : (
          []
        )}
        
      </form>
    </SlidePanel>
  );
};
