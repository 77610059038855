import React from "react";
import ReactDOM from "react-dom";
import "./styles/index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "libs/i18n/index";
import { getAuthConfig } from "config";
import { AuthProvider, AuthScreen } from "@abb/identity-auth-react";
import { ConfigProvider } from "components/Config/ConfigProvider";
import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';

const init = async () => {
  const authConfig = await getAuthConfig();
  const queryClient = new QueryClient();
  ReactDOM.render(
    <React.StrictMode>
      <AuthProvider {...authConfig}>
        <AuthScreen productName="Factory Manager">
          <QueryClientProvider client={queryClient}>
            <ConfigProvider>
              <App />
            </ConfigProvider>
          </QueryClientProvider>
        </AuthScreen>
      </AuthProvider>
    </React.StrictMode>,
    document.getElementById("root")
  );
};

init();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
