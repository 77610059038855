import { gql } from "@apollo/client";

export const createAsset = gql`
  mutation createAsset(
    $assetid: String!
    $assetname: String!
    $modelid: String!
    $structureid: String!
    $configuration: JSON!
    $domainids: [Float!]
  ) {
    createAsset(
      assetInput: {
        assetid: $assetid
        assetname: $assetname
        modelid: $modelid
        structureid: $structureid
        configuration: $configuration
        domainids: $domainids
      }
    ) {
      assetid
      assetname
      modelid
      domains
      structure
      domains
      configuration
      activeat
    }
  }
`;

export const updateAsset = gql`
  mutation updateAsset(
    $assetid: String!
    $assetname: String!
    $modelid: String!
    $structureid: String!
    $configuration: JSON!
    $domainids: [Float!]
  ) {
    updateAsset(
      assetInput: {
        assetid: $assetid
        assetname: $assetname
        modelid: $modelid
        structureid: $structureid
        configuration: $configuration
        domainids: $domainids
      }
    ) {
      assetid
      assetname
      modelid
      domains
      structure
      domains
      configuration
      activeat
    }
  }
`;

export const deleteAsset = gql`
  mutation deleteAsset($assetid: String!) {
    deleteAsset(assetid: $assetid)
  }
`;

export const deleteAssets = gql`
  mutation deleteAssets($assetIds: [String!]!) {
    deleteAssets(assetIds: $assetIds)
  }
`;

// TODO: Migrate from Hasura to Asset API
// It is used in import/export CSV
export const upsertAssetsWithDomains = gql`
  mutation upsertAsset(
    $objects: [master_asset_insert_input!]!
    $on_conflict: master_asset_on_conflict!
    $assetIds: [String!]!
    $assetDomains: [master_asset_domain_insert_input!]!
  ) {
    insert_master_asset(objects: $objects, on_conflict: $on_conflict) {
      affected_rows
    }
    delete_master_asset_domain(where: { assetid: { _in: $assetIds } }) {
      affected_rows
    }
    insert_master_asset_domain(objects: $assetDomains) {
      affected_rows
    }
  }
`;

// TODO: Migrate from Hasura to Asset API
export const updateAssetLocation = gql`
  mutation updateAssetLocation($assetids: [String!], $structureid: String!) {
    update_master_assetstructure(
      where: { assetid: { _in: $assetids } }
      _set: { structureid: $structureid }
    ) {
      affected_rows
    }
  }
`;
