import { flatBy } from "./flatBy";

export interface FindByIdInHierachyOptions<T> {
  items: T[];
  itemId: string;
  idGetter: (item: T) => string;
  childrenGetter: (parent: T) => T[];
}

/**
 * Finds the given item from the hierachy structure by the given item id.
 * @param items hierachical item collection
 * @param itemId id of the item to find.
 * @param idGetter a getter function that given a item returns its id.
 * @param childrenGetter a getter function that given a parent node returns its children.
 */
export function findByIdInHierarchy<T>({
  items,
  itemId,
  idGetter,
  childrenGetter,
}: FindByIdInHierachyOptions<T>): T {
  const flattenItems = flatBy(items, childrenGetter);
  return flattenItems?.find((item) => idGetter(item) === itemId)!;
}
