import { defaultTheme, IModalProps, useModalStyles } from "@abb/common-ux";
import { FC } from "react";
import { Modal as CommonUXModal } from "@abb/common-ux";

import styles from "./Modal.module.scss";
import useKeyPress from "libs/@abbrda/abb-common-ux-react/internalUtils/useKeyPress";

export interface ModalProps extends IModalProps {
  isOpen: boolean;
  ignoreEscKey?: boolean;
  ignoreEnterKey?: boolean;
}

export const Modal: FC<ModalProps> = (props) => {
  // Click left button on ESC key press
  useKeyPress("Escape", () => {
    props.closeModal();
  });

  /**
   * This is the computed styles for the modal.
   * It is a combination of the default CommonUX theme, additional static styles hardcoded in
   * this component and the styles passed in the props.
   *
   * Note that paddings are multiple of 4 but they are still hardcoded, as CSS variables
   * cannot be used in TypeScript code.
   */
  const computedStyles = useModalStyles(defaultTheme, {
    container: {
      display: "flex",
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      zIndex: 999,
      ...(props.style?.container || {}),
    },
    modal: {
      maxWidth: "100%",
      maxHeight: "100%",
      width: 540,
      minWidth: "auto",
      ...(props.style?.modal || {}),
    },
    modalHeader: {
      padding: 12,
      paddingRight: 0,
      marginBottom: 0,
      ...(props.style?.modalHeader || {}),
    },
    modalBody: {
      whiteSpace: "break-spaces",
      overflow: "auto",
      padding: 12,
      width: 500,
      ...(props.style?.modalBody || {}),
    },
    modalCloseButton: {
      ...(props.style?.modalCloseButton || {}),
    },
    modalFooter: {
      minHeight: "auto",
      paddingTop: 0,
      ...(props.style?.modalFooter || {}),
    },
  });

  if (!props.isOpen) {
    return null;
  }

  /**
   * All styles are passed down as `style` prop so CommonUx Modal can compute styles again.
   * Note that the div wrapper also has `className`, but this is only for the header flex workaround.
   */
  return (
    <div className={styles.container} style={computedStyles.container}>
      <CommonUXModal {...props} style={computedStyles} />;
    </div>
  );
};
