import React from 'react';
import { FunctionComponent } from "react";
import classnames from 'classnames';
import styles from './AlertBox.module.scss';

interface Props {
    type: AlertBoxType
}

export enum AlertBoxType {
    Danger
}

export const AlertBox: FunctionComponent<Props> = ({ children, type }) => {
    return <div className={classnames(styles.alertBox, { [styles.alertBoxDanger]: type === AlertBoxType.Danger })}>{children}</div>
}