import {
  Button,
  Datagrid,
  DatagridRow,
  Icon,
  Pagination,
} from "@abb/abb-common-ux-react";
import { useQuery } from "@apollo/client";
import { QueryResultRenderer } from "components/QueryResultRenderer/QueryResultRenderer";
import DataGridSkeleton from "components/Skeletons/commonux/DataGrid";
import { Tags } from "components/Tags/Tags";
import { useLoggedUserPermission } from "libs/hooks/useLoggedUserPermission";
import {
  AssetsDatagridQueryResponse,
  assetsDatagridQuery,
} from "gql/storageApi/queries/assetQueries";
import { subscribeAssetStatus } from "gql/storageApi/subscriptions/assetStatusSubscriptions";

import {
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { ApolloContexts, ApolloService } from "services/ApolloService";
import { DataGridSortDetails } from "types/DataGridSortDetails";
import { AssetStatusSubscriptionResult } from "types/storageApi/AssetStatusSubscriptionResult";
import { AssetModel } from "types/storageApi/QueryAsset";
import { AssetsGridContext } from "./Structure";
import { mapAssetsAPIToCSVData } from "utils/mappers";

// STYLING
import styles from "./AssetsDataGrid.module.scss";
import usePaginationTranslation from "libs/hooks/usePagination";
import { useStructureContext } from "./StructureContext";
import isEqual from "lodash/isEqual";

interface Props {
  onClickOnAssetDetails: (asset: AssetModel) => void;
  filters?: {
    modelid?: string[];
    assetid?: string;
    assetname?: string;
    domainid?: number[];
  };
  search: string;
}

export interface AssetsDataGridRef {
  refetch: () => void;
}

enum ConnectionStatus {
  Available,
  Unavailable,
  Undeterminate,
}

const pageSizes = [10, 30, 50];

export const AssetsDataGrid = forwardRef(
  ({ filters, onClickOnAssetDetails, search }: Props, ref) => {
    // STATE
    const [assets, setAssets] = useState<DatagridRow[]>([]);

    const [sortDetails, setSortDetails] = useState<
      DataGridSortDetails | undefined
    >();
    const [page, setPage] = useState(0);
    const [assetConnectionStatus, setAssetConnectionStatus] = useState<{
      [assetid: string]: ConnectionStatus;
    }>({});
    const [pageSize, setPageSize] = useState(pageSizes[0]);
    const [selectAllClicked, setSelectAllClicked] = useState<boolean>(false);
    const [prevFilters, setPrevFilters] = useState(filters);

    // HOOK
    const paginationTranslation = usePaginationTranslation();
    // CONTEXT

    const {
      selectedStructureChildrenIds,
      setGetAssetsCSVData,
    } = useStructureContext();
    const { selectedAssets, setSelectedAssets } = useContext(AssetsGridContext);

    const { context } = ApolloContexts.AssetsApi;

    // I18NEXT
    const { t } = useTranslation();

    // REF
    const setSelectedAssetsRef = useRef(setSelectedAssets);

    const assetsDatagridQueryResult = useQuery<AssetsDatagridQueryResponse>(
      assetsDatagridQuery,
      {
        fetchPolicy: "cache-and-network",
        variables: {
          limit: pageSize,
          offset: pageSize * page,
          orderBy: mapSortDetails(sortDetails),
          search: search,
          assetid: filters?.assetid,
          assetname: filters?.assetname,
          modelids: filters?.modelid,
          domainids: filters?.domainid,
          structureids: selectedStructureChildrenIds,
        },
        context,
      }
    );
    const { data: assetsData, refetch } = assetsDatagridQueryResult;

    useImperativeHandle(ref, () => ({
      refetch() {
        refetch();
      },
    }));

    const mapAssetToRow = useCallback(
      (asset: AssetModel, status: ConnectionStatus): DatagridRow => {
        return {
          isSelected: false,
          rowStatus: getRowStatusFromConnectionStatus(status),
          fields: {
            assetname: asset.assetname,
            assetid: asset.assetid,
            location: asset.structure.name,
            domains: (
              <Tags
                maxVisibleTags={2}
                tags={asset.domains.map((domain) => ({
                  name: domain.value.name,
                }))}
              ></Tags>
            ),
            type: t(`app:assets.${asset.modelid.replace(/\./g, "-")}`),
            isEditable: (asset.configuration?.isEditable !== false).toString(),
            actions: (
              <div className={styles.container}>
                {asset.configuration?.isEditable === false && (
                  <Icon name="abb/lock-closed" className={styles.test} />
                )}
                <Button
                  icon="abb/more"
                  type="discreet-black"
                  onClick={() => onClickOnAssetDetails(asset)}
                ></Button>
              </div>
            ),
          },
        };
      },
      [onClickOnAssetDetails, t]
    );
    const { hasEditProductPermission } = useLoggedUserPermission();

    useEffect(() => {
      return () => {
        // This rule does not apply as it does not point to a DOM element
        // eslint-disable-next-line react-hooks/exhaustive-deps
        setSelectedAssetsRef.current([]);
      };
    }, []);

    useEffect(() => {
      if (!assetsData) {
        return;
      }
      const subscriptions: ZenObservable.Subscription[] = [];
      assetsData.assets.forEach((queryAsset: AssetModel) => {
        subscriptions.push(
          ApolloService.getClient()
            .subscribe<AssetStatusSubscriptionResult>({
              query: subscribeAssetStatus,
              variables: { assetid: queryAsset.assetid },
              context: ApolloContexts.Hasura.context,
            })
            .subscribe(({ data }) => {
              if (!data) {
                return;
              }
              setAssetConnectionStatus((state) => {
                state[
                  queryAsset.assetid
                ] = getConnectionStatusFromSubscriptionResult(data);
                return { ...state };
              });
            })
        );
      });

      return () => {
        subscriptions.forEach((sub) => sub.unsubscribe());
      };
    }, [assetsData]);

    useEffect(() => {
      if (assetsData) {
        setGetAssetsCSVData(() => () => {
          return mapAssetsAPIToCSVData(assetsData.assets);
        });
      }
    }, [assetsData, setGetAssetsCSVData]);

    useEffect(() => {
      if (assetsData) {
        const gridAssets = assetsData.assets
          .map((asset) =>
            mapAssetToRow(asset, assetConnectionStatus[asset.assetid])
          )
          .map((a: DatagridRow) => {
            return {
              ...a,
              isSelected: selectedAssets.includes(a.fields.assetid as string),
            };
          });

        setAssets(gridAssets);

        if (gridAssets.length === 0 && page > 0) {
          setPage(page - 1);
        }
      }
    }, [
      assetsData,
      assetConnectionStatus,
      mapAssetToRow,
      selectedAssets,
      page,
    ]);

    useEffect(() => {
      if (filters) {
        if (isEqual(filters, prevFilters)) {
          return;
        }
        setPrevFilters(filters);
        setPage(0);
      }
    }, [filters, prevFilters]);

    useEffect(() => {
      if (assetsData) {
        setSelectAllClicked(false);
      }
    }, [assetsData]);

    useEffect(() => {
      setAssets((prevAssets) =>
        prevAssets.map((a) => {
          return {
            ...a,
            isSelected: selectedAssets.includes(a.fields.assetid as string),
          };
        })
      );
    }, [selectedAssets, setAssets]);

    const handleSelectAll = () => {
      setSelectAllClicked(!selectAllClicked);
      if (selectAllClicked) {
        setSelectedAssets([]);
      } else {
        if (assetsData) {
          // We need to filter all the assets that are not editable
          const ids = assetsData.filteredAssetList
            .filter((a) => a.configuration?.isEditable !== false)
            .map((a: any) => a.assetid);
          setSelectedAssetsRef.current(ids);
        }
      }
    };

    const handleOnAssetSelected = (rowIndex: string, oldValue: boolean) => {
      const assetClicked = assets.filter(
        (asset: DatagridRow, index: number) => parseInt(rowIndex) === index
      )[0];
      if (assetClicked.fields.isEditable === "true") {
        const assetIdClicked = assetClicked.fields.assetid as string;
        setSelectedAssets(
          selectedAssets.includes(assetIdClicked)
            ? selectedAssets.filter((id) => id !== assetIdClicked)
            : [...selectedAssets, assetIdClicked]
        );
      }
    };

    return (
      <QueryResultRenderer
        queryResults={[
          { queryResult: assetsDatagridQueryResult, dataKey: `assets` },
        ]}
        skeleton={<DataGridSkeleton id={"assets_datagrid_skeleton"} />}
      >
        {() => {
          return (
            <div className={styles.main}>
              <Datagrid
                enableRowSelection={hasEditProductPermission}
                enableRowStatusIndicators
                className={styles.grid}
                data={assets}
                enableControlledPagination
                zebraColoring
                enableSorting
                borderStyle="discreet"
                onSort={setSortDetails}
                columns={[
                  { fieldKey: "assetid", title: t("id") },
                  { fieldKey: "assetname", title: t("name") },
                  {
                    fieldKey: "location",
                    title: t("location"),
                    sortable: false,
                  },
                  { fieldKey: "type", title: t("type"), sortable: false },
                  { fieldKey: "domains", title: t("tags"), sortable: false },
                  { fieldKey: "actions", align: "right", sortable: false },
                ]}
                onToggleRowSelect={(rowIndex, oldValue) =>
                  handleOnAssetSelected(rowIndex, oldValue)
                }
                enableColumnDragging={false}
                onToggleSelectAll={handleSelectAll}
              />
              <Pagination
                showPageSelectionInput={true}
                onGotoPage={(newPage) => setPage(newPage)}
                onSetPageSize={setPageSize}
                currentPage={page}
                currentPageSize={pageSize}
                rowCount={assetsData?.count}
                showPrevNext={true}
                showNumberButtons={false}
                showInput={true}
                pageSizeOptions={pageSizes}
                texts={paginationTranslation}
              />
            </div>
          );
        }}
      </QueryResultRenderer>
    );
  }
);

function mapSortDetails(sortDetails?: DataGridSortDetails) {
  if (!sortDetails) return undefined;
  const { field, desc } = sortDetails;
  return { field, direction: desc ? "desc" : "asc" };
}

function getConnectionStatusFromSubscriptionResult(
  subscriptionResult: AssetStatusSubscriptionResult
): ConnectionStatus {
  switch (
    subscriptionResult?.master_asset_status[0]?.master_code_list_item_object
      .value.status
  ) {
    case "Unavailable":
      return ConnectionStatus.Unavailable;
    case "Available":
      return ConnectionStatus.Available;
    default:
      return ConnectionStatus.Undeterminate;
  }
}

function getRowStatusFromConnectionStatus(
  connectionStatus: ConnectionStatus
): 0 | 2 | 1 | 3 | 4 | 5 | 6 | null {
  switch (connectionStatus) {
    case ConnectionStatus.Available:
      return 6;
    case ConnectionStatus.Unavailable:
      return 1;
    default:
      return 0;
  }
}
