import React from "react";
import styles from "./DashboardContainer.module.scss";
import cx from "classnames";
import DefaultErrorBoundary from "layouts/ErrorBoundary/DefaultErrorBoundary";
import { ReflexContainer, ReflexSplitter, ReflexElement } from "react-reflex";
import { usePanel } from "components/Panel/PanelContext";
import {
  STRUCTURE_SIDEBAR_MAX_WIDTH,
  STRUCTURE_SIDEBAR_MIN_WIDTH,
} from "config/constants";

interface Props {
  sidebar?: JSX.Element;
  children: React.ReactNode;
}

export const DashboardContainer = ({ sidebar, children }: Props) => {
  const { panelContent } = usePanel();

  return (
    <DefaultErrorBoundary>
      <div className={cx(styles.content, `d-flex h-100 sticky`)}>
        <ReflexContainer orientation="vertical">
          {sidebar && (
            <ReflexElement
              size={STRUCTURE_SIDEBAR_MIN_WIDTH}
              minSize={STRUCTURE_SIDEBAR_MIN_WIDTH}
              maxSize={STRUCTURE_SIDEBAR_MAX_WIDTH}
            >
              <div className={styles.sidebar}>
                <DefaultErrorBoundary>
                  <>{sidebar}</>
                </DefaultErrorBoundary>
              </div>
            </ReflexElement>
          )}
          {sidebar && <ReflexSplitter />}
          <ReflexElement>
            <div className={styles.dashboardContent}>
              <div className={styles.children}>
                <DefaultErrorBoundary>
                  <>{children}</>
                </DefaultErrorBoundary>
              </div>
            </div>
            <DefaultErrorBoundary>
              <>{panelContent}</>
            </DefaultErrorBoundary>
          </ReflexElement>
        </ReflexContainer>
      </div>
    </DefaultErrorBoundary>
  );
};
