import { gql } from "@apollo/client";

export const getStructures = gql`
  query getStructures {
    master_structure(
      where: { properties: { _contains: { factoryStructure: true } } }
    ) {
      location
      parentid
      properties
      structureid
      structurepath
    }
  }
`;

export const getStructure = gql`
  query getStructure($structureId: String!) {
    master_structure(
      where: {
        properties: { _contains: { factoryStructure: true } }
        structureid: { _eq: $structureId }
      }
    ) {
      location
      parentid
      properties
      structureid
      structurepath
    }
  }
`;

export const getParentStructure = gql`
  query getParentStructure {
    master_structure(
      where: {
        properties: { _contains: { factoryStructure: true } }
        parentid: { _is_null: true }
      }
    ) {
      properties
      structureid
    }
  }
`;
