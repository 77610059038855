import { AssetModel, QueryAsset } from "types/storageApi/QueryAsset";

export function mapAssetsAPIToCSVData(
  assets: AssetModel[],
  includeStructureId = false
) {
  return assets.map((asset: AssetModel) => {
    let structureid = includeStructureId
      ? asset.structure.structureid
      : {};
    return {
      assetid: asset.assetid,
      assetname: asset.assetname,
      modelid: asset.modelid,
      ...structureid,
      ...asset.configuration,
      tags: asset.domains.map(
        (item) => item.value.name
      ),
    };
  });
}


export function mapAssetsToCSVData(
  assets: QueryAsset[],
  includeStructureId = false
) {
  return assets.map((asset: QueryAsset) => {
    let structureid = includeStructureId
      ? {
          structureid:
            asset.master_assetstructure_array.length > 0
              ? asset.master_assetstructure_array[0].structureid
              : "",
        }
      : {};
    return {
      assetid: asset.assetid,
      assetname: asset.assetname,
      modelid: asset.modelid,
      ...structureid,
      ...asset.master_assetconfiguration_array[0].propertyconfig,
      tags: asset.master_asset_domain_array.map(
        (item) => item.master_code_list_item_object.value.name
      ),
    };
  });
}