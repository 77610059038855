import "./ErrorBox.scss";
import { useTranslation } from "react-i18next";
import { Icon } from "@abb/abb-common-ux-react";
import { ErrorBoxProps } from "./ErrorBoxProps";

export const ErrorBox = ({ messages, title }: ErrorBoxProps) => {
  const { t } = useTranslation();
  return (
    <div className="error-box">
      <Icon name="abb/warning-circle-1" sizeClass="large" color="#f03040" />
      <p className="title">
        {title ? title : messages.length === 1 ? t(`common:thereWasAnError`) : t(`common:thereWasSomeErrors`)}
      </p>
      {messages.length === 1 ? (
        <p className="description">{messages[0]}</p>
      ) : (
        <ul>
          {messages.map((message, ix) => (
            <li key={message + ix}>{message}</li>
          ))}
        </ul>
      )}
    </div>
  );
};
