import { IStructureTreeItem } from "components/StructureTree/StructureTreeItem";

/**
 * @name getStructureChildrenIds
 * @description Returns the ids of the provided tree Items and the ids of all their children recursively
 * @param {IStructureTreeItem[]} treeItems The tree items to get the ids from
 * @returns {string[]} Array of ids of the provided tree items and all their children
 */
export function getStructureChildrenIds(
  treeItems: IStructureTreeItem[]
): string[] {
  return treeItems.reduce<string[]>((acc, treeItem) => {
    return [...acc, treeItem.id, ...getStructureChildrenIds(treeItem.children)];
  }, []);
}
