import { Icon } from "@abb/common-ux";
import { useRef, useLayoutEffect, useState, FC } from "react";
import styles from "./ExpandableText.module.scss";
import ClipboardJS from "clipboard";
import { useGlobalNotification } from "services/GlobalNotification/GlobalNotificationService";
import { useTranslation } from "react-i18next";
import useDimensions from "react-cool-dimensions";
import { isOverflowing } from "utils/isOverflowing";

export interface ExpandableTextProps {
  text: string;
}
export const ExpandableText: FC<ExpandableTextProps> = ({ text }) => {
  const [isExpanded, setIsExpanded] = useState<Boolean>(false);
  const [showExpand, setShowExpand] = useState<Boolean>(false);

  const { publishSuccess } = useGlobalNotification();
  const { t } = useTranslation();
  const ref = useRef<HTMLDivElement>(null);
  const { ref: refDimension, width } = useDimensions<HTMLDivElement>();
  const copyToClipboard = () => {
    if (ClipboardJS.copy(text)) {
      publishSuccess(t("copied"));
    }
  };

  useLayoutEffect(() => {
    const isOverflow = isOverflowing(ref.current!);
    setShowExpand(isOverflow);
  }, [width]);

  const icons = () => (
    <div className={styles.icons}>
      {showExpand && (
        <Icon
          onClick={() => setIsExpanded(!isExpanded)}
          style={{ fontSize: 14 }}
          name={isExpanded ? "hide" : "view"}
        />
      )}
      <Icon onClick={copyToClipboard} name="reports" />
    </div>
  );

  return (
    <div ref={refDimension} className={styles.expandableText}>
      <div
        className={isExpanded ? styles.textExpanded : styles.text}
        title={text}
        ref={ref}
      >
        {text}
        {isExpanded && icons()}
      </div>
      {!isExpanded && icons()}
    </div>
  );
};
