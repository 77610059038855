// REACT
import React, { useRef, useState } from "react";

// STYLES
import styles from "./InfoButton.module.scss";

import Tippy from "@tippyjs/react";
import { Icon, Pressable, PressableChildrenProps, useTheme } from "@abb/common-ux";
import { Placement } from 'tippy.js';
import cx from "classnames";

interface Props {
  children: string | JSX.Element;
  placement?: Placement | undefined;
  colors?: { primaryColor: string, hoveredColor: string };
  className?: string;
}
export const InfoButton = ({ children, placement, colors, className }: Props) => {
  // STATE
  const [openPopupComponent, setOpenPopupComponent] = useState(false);
  const { theme:{
    preset:{
      colors:{
        disabledText,
        primaryText,
      }
    }
  } } = useTheme();

  const color = colors?.primaryColor ? colors.primaryColor : disabledText;
  const hoveredColor = colors?.hoveredColor ? colors.hoveredColor : primaryText;

  // REFS
  const wrapperRef = useRef(null);

  return (
    <div className={cx(styles.infoButton, `${className ? className : ''}`) } >
      <Tippy
        content={
          openPopupComponent ? (
            <div className={styles.popup}>
              <p >{children}</p>
            </div>
          ) : (
            <></>
          )
        }
        interactive={true}
        reference={wrapperRef}
        appendTo={() => document.body}
        onHidden={() => setOpenPopupComponent(false)}
        onClickOutside={() => setOpenPopupComponent(false)}
        visible={openPopupComponent}
        placement={placement}
      >
        <div ref={wrapperRef}>
        <Pressable  onPress={() => setOpenPopupComponent((status) => !status)}>
          {({ hovered }: PressableChildrenProps) => (
            <Icon name="information-circle-2" style={{ color: hovered ? hoveredColor : color}} size={16} />
          )}
        </Pressable>
        </div>
      </Tippy>
    </div>
  );
};
