import { FC } from "react";
import { Footer as CommonUXFooter } from "@abb/common-ux";
import { useConfig } from "components/Config/ConfigProvider";
import { useTranslation } from "react-i18next";

export const Footer: FC = () => {
  const {
    config: { appVersion },
  } = useConfig();
  const { t } = useTranslation();
  return (
    <CommonUXFooter
      text={t("app:footerText", { currentYear: new Date().getFullYear() })}
      appendTo={document.body}
      versions={[{ name: t("frontend"), number: appVersion }]}
      showInfo={true}
    />
  );
};
