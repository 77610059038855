// CONSTANTS
import { PERMISSIONS } from "config";

// LIBS
import { useAuth } from "@abb/identity-auth-react";

/**
 * Hook that returns the logged user permissions related to products, users and structures
 */
export const useLoggedUserPermission = () => {
  const { user } = useAuth();
  const currentPermissions: string[] =
    (user?.profile.permissions && [...user?.profile.permissions]) || [];
  // EDIT PERMISSIONS
  const hasEditStructurePermission = [
    PERMISSIONS.storageapi_edit,
    PERMISSIONS.structure_edit,
  ].every((permission) => currentPermissions.includes(permission));

  const hasEditUserPermission = [
    PERMISSIONS.storageapi_edit,
    PERMISSIONS.users_edit,
  ].every((permission) => currentPermissions.includes(permission));

  const hasEditProductPermission = [
    PERMISSIONS.storageapi_edit,
    PERMISSIONS.products_edit,
  ].every((permission) => currentPermissions.includes(permission));

  const hasEditShiftPermission = [
    PERMISSIONS.storageapi_edit,
    PERMISSIONS.shifts_edit,
  ].every((permission) => currentPermissions.includes(permission));

  // VIEW PERMISSIONS
  const hasViewStructurePermission = [
    PERMISSIONS.storageapi_view,
    PERMISSIONS.structure_view,
  ].every((permission) => currentPermissions.includes(permission));

  const hasViewUserPermission = [
    PERMISSIONS.storageapi_view,
    PERMISSIONS.users_view,
  ].every((permission) => currentPermissions.includes(permission));

  const hasViewProductPermission = [
    PERMISSIONS.storageapi_view,
    PERMISSIONS.products_view,
  ].every((permission) => currentPermissions.includes(permission));

  const hasViewShiftPermission = [
    PERMISSIONS.storageapi_view,
    PERMISSIONS.shifts_view,
  ].every((permission) => currentPermissions.includes(permission));

  return {
    hasEditStructurePermission,
    hasEditUserPermission,
    hasEditProductPermission,
    hasEditShiftPermission,
    hasViewStructurePermission,
    hasViewUserPermission,
    hasViewProductPermission,
    hasViewShiftPermission,
  };
};
