import { gql } from "@apollo/client";

export const getUser = gql`
  query getUser($id: String!) {
    user(id: $id) {
      id
      email
      phone
      firstName
      lastName
      identity {
        assignedGroups(includeInternal: true) {
          id
          name
          label
          isInternal
          roles {
            id
            name
          }
        }
      }
    }
  }
`;

export const getUserByIdentityId = gql`
  query getUserByIdentityId($identityId: String) {
    userByIdentityId(identityId: $identityId) {
      id
      email
      phone
      firstName
      lastName
      identity {
        assignedGroups(includeInternal: true) {
          id
          name
          label
          isInternal
          roles {
            id
            name
          }
        }
      }
    }
  }
`;

export const getAllUsers = gql`
  query getAllUsers {
    usersByNameAndEmail(firstNameFilter: "", emailFilter: "") {
      id
      email
      firstName
      lastName
      phone
      identity {
        id
        status
        type
        assignedGroups(includeInternal: true) {
          id
          name
          label
          isInternal
          roles {
            id
            name
            label
          }
        }
      }
    }
  }
`;

export const getUsersById = gql`
  query usersById($ids: [String!], $identityIds: [String!]) {
    usersById(ids: $ids, identityIds: $identityIds) {
      id
      email
      firstName
      lastName
      phone
      identity {
        id
        status
        assignedGroups(includeInternal: true) {
          id
          name
          label
          isInternal
          roles {
            id
            name
          }
        }
      }
    }
  }
`;
