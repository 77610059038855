//HOOKS
import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import { useQuery } from "@apollo/client";
import { useDownload } from "libs/hooks/useDownload";
import useKeyPress from "libs/@abbrda/abb-common-ux-react/internalUtils/useKeyPress";
//LIBS
import yup from "libs/validation/yup";
import { unparse } from "papaparse";
import cx from "classnames";
//COMPONENTS
import {
  Dropdown,
  DropdownOption,
  LoadingIndicator,
} from "@abb/abb-common-ux-react";
//QUERIES
import { getStructures } from "gql/storageApi/queries/structureQueries";
import { getAllAssets } from "gql/storageApi/queries/assetQueries";
//DEFINITIONS
import { QueryStructure } from "types/storageApi/QueryStructure";
import {
  Structure,
  StructureImportType,
  structurePropertyList,
} from "components/ImportFromCSV/Structure";
import { fullAssetPropertyList } from "components/ImportFromCSV/hooks/useQueryAssetsData";

//UTILS
import { mapAssetsToCSVData } from "utils/mappers";

//STYLES
import { usePanel } from "components/Panel/PanelContext";
import { Button, SlidePanel } from "@abb/common-ux";
import {
  NotifyError,
  useGlobalNotification,
} from "services/GlobalNotification/GlobalNotificationService";
import { ApolloContexts } from "services/ApolloService";

export interface ExportStructureToCSVProps {
  structureId?: string;
  importWithOutPanel: boolean;
}

interface StructureForm {
  type: string;
}
export const mapQueryStructuretoStructure = (
  queryStructure: QueryStructure
): Structure => {
  return {
    name: queryStructure.properties.name,
    type: queryStructure.properties.type,
    structureid: queryStructure.structureid,
    parentid: queryStructure.parentid,
  };
};

export const getStructureData = (
  dataStructure: QueryStructure[],
  structureId: string | undefined
): Structure[] => {
  let result: Structure[] = [];

  if (!structureId) {
    return dataStructure.reduce(
      (acc: Structure[], structure: QueryStructure) => {
        if (structure.parentid) {
          acc.push(mapQueryStructuretoStructure(structure));
        }
        return acc;
      },
      []
    );
  }

  const parent = dataStructure.find(
    (structure: QueryStructure) => structure.structureid === structureId
  );

  if (parent) {
    result = result.concat(mapQueryStructuretoStructure(parent));
  }

  const children = dataStructure.filter(
    (structure: QueryStructure) => structure.parentid === structureId
  );

  if (children.length) {
    result = result.concat(
      children.flatMap((child) =>
        getStructureData(dataStructure, child.structureid)
      )
    );
  }

  return result;
};

const ExportStructureToCSV = (props: ExportStructureToCSVProps) => {
  const { t } = useTranslation();
  const { isOpen, closePanel } = usePanel();
  const { downloadText } = useDownload();
  const { publishSuccess } = useGlobalNotification();
  const { context } = ApolloContexts.Hasura;

  const {
    setFieldValue,
    setFieldTouched,
    errors: formikErrors,
    touched,
    values,
  } = useFormik({
    initialValues: {
      type: StructureImportType.AssetsOnly,
      file: undefined,
    },
    validationSchema: yup.object().shape({
      type: yup.string(),
      file: yup.object(),
    }),
    onSubmit: (values: any) => {},
  });
  const { data: structureData, loading: structuresLoading } = useQuery(
    getStructures,
    {
      fetchPolicy: "network-only",
      context,
    }
  );

  const { data: assetsData, loading: assetsLoading } = useQuery(getAllAssets, {
    fetchPolicy: "network-only",
    context,
  });
  const handleExport = useCallback(() => {
    try {
      let fields: string[] = [];
      let data: any[] = [];

      switch (values.type) {
        case StructureImportType.All:
          fields = [...structurePropertyList, ...fullAssetPropertyList];
          break;
        case StructureImportType.AssetsOnly:
          fields = [...fullAssetPropertyList];
      }

      const structure = getStructureData(
        structureData["master_structure"],
        props.structureId
      );

      if (values.type === StructureImportType.All) {
        data = [...data, ...structure];
      }

      let assetCsvData = mapAssetsToCSVData(assetsData["master_asset"], true);
      assetCsvData = assetCsvData.filter((x) => x.isEditable !== false);

      if (props.structureId) {
        const structureIds = structure.map((s: Structure) => s.structureid);
        assetCsvData = assetCsvData.filter(
          (x) => x.structureid && structureIds.includes(x.structureid)
        );
      }
      data = [...data, ...assetCsvData];

      const csv = unparse({ data, fields });
      downloadText(csv, `CSV assets ${new Date().toISOString()}.csv`);
      publishSuccess(
        t("app:screen.structure.panel.exportStructureCSV.success")
      );
    } catch (e) {
      NotifyError(e as Error);
    }
    closePanel();
  }, [
    closePanel,
    values.type,
    structureData,
    props.structureId,
    assetsData,
    downloadText,
    publishSuccess,
    t,
  ]);

  const handleValidate = (value: keyof StructureForm): string => {
    return formikErrors[value] && touched[value]
      ? (formikErrors[value] as string)
      : "";
  };
  const loading = structuresLoading || assetsLoading;

  useKeyPress("Escape", () => {
    closePanel();
  });

  useKeyPress("Enter", () => {
    handleExport();
  });

  useEffect(() => {
    if (props.importWithOutPanel && !structuresLoading && !assetsLoading) {
      handleExport();
    }
  }, [
    assetsLoading,
    handleExport,
    props.importWithOutPanel,
    structuresLoading,
  ]);

  return props.importWithOutPanel ? (
    <></>
  ) : (
    <SlidePanel
      isOpen={isOpen}
      closePanel={closePanel}
      title={t("exportCSV")}
      bottomActions={
        <>
          <Button
            text={t(`app:screen.structure.panel.exportStructureCSV.cancel`)}
            onPress={closePanel}
            type="discreet-blue"
          />
          <Button
            disabled={loading}
            text={t(`app:screen.structure.panel.exportStructureCSV.download`)}
            onPress={handleExport}
            type="primary-blue"
          />
        </>
      }
    >
      <form data-cy="panel-form">
        <Dropdown
          label={t("app:screen.structure.panel.exportStructureCSV.selectType")}
          value={
            values.type
              ? [
                  {
                    value: values.type,
                    label: t(
                      `app:screen.structure.panel.importStructureCSV.${values.type}`
                    ),
                  },
                ]
              : []
          }
          onChange={(v) => {
            setFieldValue("type", v[0]?.value);
            setTimeout(() => setFieldTouched("type"), 500);
          }}
          showValidationBarWhenInvalid={true}
          showValidationIconWhenInvalid={true}
          validationState={{
            valid: handleValidate("type") === "",
            message: handleValidate("type"),
          }}
          searchable={true}
          sizeClass="large"
          required
        >
          <DropdownOption
            label={t("app:screen.structure.panel.importStructureCSV.assets")}
            value={StructureImportType.AssetsOnly}
          />
          <DropdownOption
            label={t("app:screen.structure.panel.importStructureCSV.all")}
            value={StructureImportType.All}
          />
        </Dropdown>
        <LoadingIndicator
          className={cx({ hidden: !loading })}
          determinate={false}
          color="blue"
          type="bar"
          text={t("app:screen.structure.panel.exportStructureCSV.loading", {
            type: structuresLoading
              ? assetsLoading
                ? t(
                    "app:screen.structure.panel.exportStructureCSV.assetsAndStructures"
                  )
                : t(
                    "app:screen.structure.panel.exportStructureCSV.structureNodes"
                  )
              : t("app:screen.structure.panel.exportStructureCSV.assets"),
          })}
        />
      </form>
    </SlidePanel>
  );
};

export default ExportStructureToCSV;
