import { Fragment } from "react";
import ContentLoader from "react-content-loader";
import skeletonDefaults from "../Skeleton";

const AppSkeleton = (props: any) => {
  const appConfig = {
    ...skeletonDefaults,
    rx: 4,
    ry: 4,
    width: 175,
    height: 90,
    gap: 8,
  };

  const nameConfig = {
    ...appConfig,
    y: 100,
    width: 140,
    height: 20,
  };

  const descriptionConfig = {
    ...appConfig,
    y: 130,
    width: 160,
    height: 20,
  };

  const app = (num: number) => {
    // Calc x position = app width * app number + left-margin
    let x = appConfig.width * num + 20 * num;
    return (
      <Fragment key={num}>
        <rect {...appConfig} x={x} />
        <rect {...nameConfig} x={x} />
        <rect {...descriptionConfig} x={x} />
      </Fragment>
    );
  };

  let apps = [];
  for (let i = 0; i < (props.items || 1); i++) {
    apps.push(app(i));
  }

  return (
    <ContentLoader
      height={200}
      backgroundColor={`#f5f5f5`}
      foregroundColor={`#ebebeb`}
    >
      {apps}
    </ContentLoader>
  );
};

export default AppSkeleton;
