import React from "react";
import ContentLoader from "react-content-loader";
import skeletonDefaults from "./Skeleton";
import { omit } from "lodash";
interface IListSkeleton {
  id: string;
  items?: number;
  height?: number;
  inverted?: boolean;
}

const ListSkeleton = ({
  id,
  items = 0,
  height = 0,
  inverted,
}: IListSkeleton) => {
  const listDefaults = {
    ...skeletonDefaults,
    rx: 4,
    ry: 4,
    width: 20,
    height: 20,
    heightTotal: 80,
    padding: 2,
    gap: 8,
  };

  const listItemConfig = {
    ...listDefaults,
    y: 30,
    width: 300,
    height: 20,
    heightTotal: 40,
  };

  const listItemsQuantity = Math.ceil(
    (height || listDefaults["heightTotal"]) / listDefaults["heightTotal"] / 1.2
  );

  const input = (num: number) => {
    const inputYPosition = listItemConfig["heightTotal"] * num + 20;
    return (
      <React.Fragment key={`${id}_item_${num}`}>
        <rect
          {...omit(listItemConfig, ["heightTotal"])}
          y={inputYPosition}
          width={"100%"}
        />
      </React.Fragment>
    );
  };

  let listItems = [];
  for (let i = 0; i < (items || listItemsQuantity); i++) {
    listItems.push(input(i));
  }

  return (
    <ContentLoader
      height={items * listDefaults["heightTotal"] || height}
      backgroundColor={inverted ? `#ebebeb` : `#f5f5f5`}
      foregroundColor={inverted ? `#f5f5f5` : `#ebebeb`}
    >
      {listItems}
    </ContentLoader>
  );
};

export default ListSkeleton;
