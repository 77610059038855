// UTILS
import capitalize from "utils/capitalize";
import { useModal } from "components/Modal/ModalContext";

// REACT
import { useCallback, useState } from "react";

// I18NEXT
import { useTranslation } from "react-i18next";

// FORMIK
import { useFormik } from "formik";
import yup from "libs/validation/yup";

// APOLLO
import { FetchResult, useMutation, useQuery } from "@apollo/client";
import {
  getDomains,
  getDomainsListId,
} from "gql/storageApi/queries/domainQueries";
import { insertDomain } from "gql/storageApi/mutations/assetDomainsMutations";
import { ApolloContexts, ApolloService } from "services/ApolloService";

// COMPONENTS
import { Dropdown, DropdownOption } from "@abb/abb-common-ux-react";
import { StructureTreeView } from "components/StructureTree/StructureTreeView";
import { StepProps } from "../DeviceWizard";
import { QueryResultRenderer } from "components/QueryResultRenderer/QueryResultRenderer";
import { AlertBox, AlertBoxType } from "components/AlertBox/AlertBox";
import { Input } from "libs/@abbrda/abb-common-ux-react/src/components/Input";

// STYLING
import styles from "../Wizard.module.scss";
import { useStructureTree } from "components/StructureTree/structureHooks";
import { LOCATIONS_ASSET_TREE_MAX_WIDTH } from "config/constants";
import { Modal } from "components/Modal/Modal";

type Domain = {
  id: string;
  value: {
    name: string;
  };
};

interface DomainOption {
  isNew: boolean;
  label: string;
  value: number;
}

const LocationDomainStep = ({ formik }: StepProps) => {
  const { t } = useTranslation();
  const { setModal, closeModal } = useModal();
  const { context } = ApolloContexts.Hasura;
  const domainsQueryResult = useQuery(getDomains, {
    fetchPolicy: "network-only",
    context,
  });

  const { tree } = useStructureTree();

  const handleOnDomainSelect = (domains: DomainOption[]) => {
    return formik.setFieldValue("tag", domains);
  };

  const [createNewDomain] = useMutation(insertDomain, {
    context,
  });
  const [isCreatingDomain, setIsCreatingDomain] = useState(false);

  const onSubmit = useCallback(
    (
      newDomainName: string,
      setFieldError: (field: string, value: string | undefined) => void
    ) => {
      if (!newDomainName) {
        return;
      }

      if (isCreatingDomain) {
        return;
      }

      setIsCreatingDomain(true);

      ApolloService.getClient()
        .query({
          query: getDomains,
          fetchPolicy: "network-only",
          context,
        })
        .then(({ data }: FetchResult) => {
          if (!data) {
            return Promise.resolve();
          }
          if (
            (data?.master_code_list_item as Domain[]).some(
              (item) => item.value.name === newDomainName
            )
          ) {
            setFieldError(
              "name",
              capitalize(
                t(
                  "app:screen.structure.wizard.location.tagDomain.errors.tagRepeated"
                )
              )
            );
            return Promise.resolve();
          }
          return ApolloService.getClient()
            .query({
              query: getDomainsListId,
              fetchPolicy: "network-only",
              context,
            })
            .then(({ data }: FetchResult) => {
              return createNewDomain({
                variables: {
                  value: { name: newDomainName },
                  codeListId: data?.master_code_list[0]?.id,
                },
              }).then(({ data }: FetchResult) => {
                if (!data) return Promise.resolve();
                const insertedDomain = data.insert_master_code_list_item_one;
                domainsQueryResult.refetch().then(() => {
                  formik.setFieldValue("tag", [
                    ...formik.values.tag.map((domain) => ({
                      ...domain,
                      isNew: true,
                    })),
                    {
                      value: insertedDomain.id,
                      label: insertedDomain.value.name,
                      isNew: true,
                    },
                  ]);
                });
                closeModal();
                return Promise.resolve();
              });
            });
        })
        .finally(() => setIsCreatingDomain(false));
    },
    [
      closeModal,
      context,
      createNewDomain,
      domainsQueryResult,
      formik,
      isCreatingDomain,
      t,
    ]
  );

  return (
    <div className={styles.largeStepContainer}>
      <div style={{ flex: 1 }}>
        <span className={styles.locationTreeLabel}>{t(`location`)}</span>
        <span className={styles.required}>*</span>
        {formik.errors.location && formik.touched.location && (
          <div style={{ marginTop: 4 }}>
            <AlertBox type={AlertBoxType.Danger}>
              {t(`locationIsARequiredField`)}
            </AlertBox>
          </div>
        )}
        <div className={styles.locationTreeContainer}>
          <StructureTreeView
            selectedId={formik.values.location.id}
            expandOnSelectedItem
            onlyView
            items={tree || []}
            onClickOnItem={(option) =>
              formik.setFieldValue("location", {
                id: option.id,
                label: option.name,
              })
            }
            maxWidth={LOCATIONS_ASSET_TREE_MAX_WIDTH}
          />
        </div>
      </div>
      <div style={{ flex: 1 }}>
        <QueryResultRenderer
          queryResults={[
            {
              queryResult: domainsQueryResult,
              dataKey: "master_code_list_item",
            },
          ]}
        >
          {([domains]: [Domain[]]) => {
            return (
              <>
                <Dropdown
                  searchable
                  multiselect={true}
                  onChange={handleOnDomainSelect}
                  sizeClass="large"
                  label={t("tags")}
                  placeholder={t("tagSelect")}
                  value={formik.values?.tag || []}
                >
                  {domains.map((domain: Domain) => (
                    <DropdownOption
                      key={domain?.id || "-"}
                      label={domain?.value.name || "-"}
                      value={domain?.id || "-"}
                    />
                  ))}
                </Dropdown>
                <button
                  className="mt-2 d-inline-block btn btn-link btn-sm"
                  onClick={() => {
                    setModal(<LocationDomainModal onSubmit={onSubmit} />);
                  }}
                >
                  {t(`createNewTag`)}
                </button>
              </>
            );
          }}
        </QueryResultRenderer>
      </div>
    </div>
  );
};

/**
 * Modal to create a new location domain.
 * This is detached from the main component to allow rerendering the input from the modal without
 * rerendering the whole modal again or calling multiple times to showModal.
 */
const LocationDomainModal = ({
  onSubmit,
}: {
  onSubmit: (
    name: string,
    setFieldError: (field: string, value: string | undefined) => void
  ) => void;
}) => {
  const { t } = useTranslation();
  const { closeModal } = useModal();

  const {
    errors,
    touched,
    values,
    setFieldTouched,
    setFieldValue,
    setFieldError,
  } = useFormik<{
    name: string;
  }>({
    initialValues: {
      name: "",
    },
    validationSchema: yup.object().shape({
      name: yup
        .string()
        .required(capitalize(t("mandatoryField")))
        .max(50, t("yup:errors.tooLong")),
    }),
    // Placeholder required value. The real onSubmit callback is passed from props
    onSubmit: () => undefined,
  });

  const handleValidate = (value: keyof { name: string }): string => {
    return errors[value] && touched[value] ? (errors[value] as string) : "";
  };

  return (
    <Modal
      isOpen
      title={t(`createTag`)}
      closeModal={closeModal}
      body={
        <Input
          showValidationBarWhenInvalid
          showValidationIconWhenInvalid
          validationResult={handleValidate("name")}
          label={capitalize(
            t("app:screen.structure.wizard.location.createTag.fields.name")
          )}
          value={values.name}
          onValueChange={(value) => {
            setFieldValue("name", value);
          }}
          onLostFocus={() => setFieldTouched("name")}
          dataType="text"
          required
        />
      }
      footer={{
        footer: {
          leftButton: {
            text: t(`cancel`),
            type: "discreet-blue",
            size: "small",
            onPress: closeModal,
          },
          rightButton: {
            text: t(`createAndSelect`),
            type: "primary-blue",
            size: "small",
            onPress: async () => {
              onSubmit(values.name, setFieldError);
            },
          },
        },
      }}
    />
  );
};

export default LocationDomainStep;
